import React, { Component } from 'react';
import { call_api } from '../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import Footer from '../components/Footer.js';
class CouponSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data_status: false,
      coupon_status: '',
      coupon_price_type: '',
      coupon_price_percent: '',
      min_order_price: '',
    };
  }
  update_password = () => {
    let coupon_status = this.state.coupon_status || '';
    let coupon_price_type = this.state.coupon_price_type || '';
    let coupon_price_percent = this.state.coupon_price_percent || '';
    let min_order_price = this.state.min_order_price || '';
    if (coupon_status == '') {
      notify.show("Select Coupon Status", 'error', 5000);
    }
    else if (coupon_price_type == '') {
      notify.show('Select Coupon Price type', 'error', 5000);
    }
    else if (coupon_price_percent == '') {
      notify.show('Enter Coupon Price', 'error', 5000);
    }
    else if (min_order_price == '') {
      notify.show('Enter Minimum Order Amount', 'error', 5000);
    }
    else {
      this.setState({ loading: true })
      var LoginToken = localStorage.getItem('LoginToken');
      let options = {
        'coupon_status': coupon_status, 'coupon_price_type': coupon_price_type, 'LoginToken': LoginToken,
        'coupon_price_percent': coupon_price_percent, 'min_order_price': min_order_price
      }
      call_api('a/update-coupon-setting', options)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 5000);
            }
            else {
              notify.show(resolve.message, 'error', 5000);
            }
            this.setState({ loading: false })
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  componentDidMount() {
    this.fetch_coupon_setting();
  }
  fetch_coupon_setting() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/get-coupon-setting', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({
              coupon_status: resolve.data['coupon_status'],
              min_order_price: resolve.data['min_order_price'],
              coupon_price_percent: resolve.data['coupon_price_percent'],
              coupon_price_type: resolve.data['coupon_price_type'],
              data_status: true
            })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ data_status: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  render() {
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Coupon Setting</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4 margin-auto">
                          {this.state.data_status == true ?
                            <div className="form-wrap">
                              <div className="form-group">
                                <label htmlFor="coupon_status" className=" control-label">Percent/Price</label>
                                <select name="coupon_status" value={this.state.coupon_status || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="coupon_status">
                                  <option value="">Select Status</option>
                                  <option value="1">Active</option>
                                  <option value="2">Inactive</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="coupon_price_type" className=" control-label">Percent/Price</label>
                                <select name="coupon_price_type" value={this.state.coupon_price_type || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="coupon_price_type">
                                  <option value="">Select Type</option>
                                  <option value="Price">Price</option>
                                  <option value="Percent">Percent</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="coupon_price_percent" className="control-label">Coupon Price/Percent</label>
                                <input type="text" name="coupon_price_percent" value={this.state.coupon_price_percent || '0'} onChange={(e) => this.setState({ [e.target.name]: e.target.value.replace(/\D/, '') })} className="form-control" id="coupon_price_percent" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="min_order_price" className="control-label">Min Order Price</label>
                                <input type="text" name="min_order_price" value={this.state.min_order_price || '0'} onChange={(e) => this.setState({ [e.target.name]: e.target.value.replace(/\D/, '') })} className="form-control" id="min_order_price" />
                              </div>
                              <div className="form-group text-center">
                                <button type="button" className="btn btn-primary btn-sm" disabled={this.state.loading} onClick={() => this.update_password()}> {this.state.loading && <i className="fa fa-spin fa-spinner"></i>}  Update</button>
                              </div>
                            </div>
                            :
                            <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
export default CouponSetting;
