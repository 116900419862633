import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import notfoundImg from "../../assets/img/no_image.jpg";
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from '../../components/General_data.js';
import FadeIn from "react-lazyload-fadein";
class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      displayLogistics: true,
      loading: false,
      logistics_id: 0,
      order_details: [],
      cart_details: [],
      vender_list: [],
    };
    if (props.match.isExact == true) {
      const order_ids = props.match.params.order_id;
      if (order_ids) {
        this.log_order_details(order_ids);
      }
      else {
        notify.show('invalid order details', 'error', 2000);
      }
    }
    else {
      notify.show('invalid order details', 'error', 2000);
    }
    //const order_ids = 1; 
  }
  asign_logistics = (orderId) => {
    let logistics_id = this.state.logistics_id;
    if (logistics_id == 0) {
      notify.show('Please select logisticd', 'error', 2000);
    }
    else {
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'logistics_id': logistics_id,
        'orderId': orderId
      }
      this.setState({ loading: true })
      call_api('a/order-logistics-asign', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.log_order_details(orderId);
              this.setState({ loading: false, displayLogistics: true })
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  fetch_logistics_list = (cityId) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'cityId': cityId
    }
    call_api('a/order-logistics-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ vender_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  log_order_details(order_ids) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'order_id': order_ids,
    }
    call_api('a/order-details', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ order_details: resolve.data, cart_details: resolve.cart, isLoading: false, data_status: true })
            if (resolve.data.logistics_id == 0) {
              this.setState({ displayLogistics: false })
            }
            this.fetch_logistics_list(resolve.data.city_id);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  render() {
    const { isLoading, data_status, order_details, cart_details } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Order Detail</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      {isLoading == true ?
                        'Please Wait'
                        :
                        <div className="row">
                          <div className="col-lg-6 col-xs-12 col-sm-6 col-md-6">
                            <h6>Order Details :-</h6>
                            <hr></hr>
                            <div className='row'>
                              <div className="col-lg-6 col-xs-12 col-sm-6 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Order No :</label>
                                  <span>{order_details.invoiceNumber}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-6 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Order Date :</label>
                                  <span>{order_details.order_date}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Order Status :</label>
                                  <span className={"label " + fetch_status_color(order_details.order_status)}>{fetch_status_string(order_details.order_status)}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Payment Mode :</label>
                                  <span>{fetch_payment_mode_string(order_details.p_mode)}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Order Amount :</label>
                                  <span>Rs.{order_details.order_amount}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Shipping Amount :</label>
                                  <span>Rs.{order_details.shipping_amount}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Coupon Code Applied :</label>
                                  <span>Rs.{order_details.couponAmount}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Total Amount :</label>
                                  <span>Rs.{order_details.final_amount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xs-12 col-sm-6 col-md-6">
                            <h6>User Details :-</h6>
                            <hr></hr>
                            <div className='row'>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">User Name :</label>
                                  <span>{order_details.muser_name}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">User Contact :</label>
                                  <span>{order_details.mobilenumber}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">User Address :</label>
                                  <span>{order_details.muser_address}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Order Area :</label>
                                  <span>{order_details.area_name}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">City & State :</label>
                                  <span>{order_details.city_name}-{order_details.state_name}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Order Pincode :</label>
                                  <span>{order_details.o_pincode_id}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                  <label className="control-label">Address Type :</label>
                                  <span>{((order_details.addr_type == 1) ? 'Home' : (order_details.addr_type == 2) ? 'Office' : 'Other')}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6 mt-20">
                            <h6>Delivery Details :-</h6>
                            <hr></hr>
                            {this.state.displayLogistics == false ?
                              <>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label htmlFor="logistics_id" className="control-label">Logistic Not Asign</label>
                                          <select type="text" name="logistics_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.logistics_id} className="form-control" >
                                            <option value="0">Select Logistics</option>
                                            {
                                              this.state.vender_list.map((list, index) => {
                                                return (
                                                  <option key={"l_" + index} value={list.id}>{list.full_name}({list.contact_details})</option>
                                                )
                                              })
                                            }
                                          </select>
                                          {this.state.logistics_id != 0 ?
                                            <>
                                              <br />
                                              <button className="btn btn-sm btn-danger" disabled={this.state.loading} onClick={() => this.asign_logistics(order_details.order_id)}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Asign Logistics</button>
                                            </>
                                            : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">Logistic Name :</label>
                                      <span>{order_details.full_name}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">Logistic Contact :</label>
                                      <span>{order_details.contact_details}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">Delivery Date :</label>
                                      <span>{order_details.delivery_date}</span>
                                  </div>
                                </div>
                                {order_details.order_status == 1 ?
                                  <p className="ml-30 txt-info cursor" onClick={() => this.setState({ displayLogistics: false })}>Change Logistics</p>
                                  : ''}
                              </>
                            }
                          </div> */}
                          <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                            <br></br>
                            <br></br>
                            <h6>Item Details :-</h6>
                            <hr></hr>
                            <div className="table-responsive">
                              <table className="table mb-0 text-center">
                                <thead>
                                  <tr>
                                    <th>Sr No</th>
                                    <th>Image</th>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    {/* <th>Unit</th> */}
                                    <th>MRP</th>
                                    <th>Sale Price</th>
                                    <th>Discount</th>
                                    <th>Total Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    data_status == true ?
                                      cart_details.map((list, index) => {
                                        return (
                                          <tr key={"row" + index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              <FadeIn height={50} duration={50} easing={'ease-out'}>
                                                {onload => (
                                                  <img src={apiUrl + list.p_img} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} onLoad={onload} style={{ cursor: 'pointer', minHeight: "100px", width: "75px" }} />
                                                )}
                                              </FadeIn>
                                            </td>
                                            <td>{list.product_name}</td>
                                            <td>{list.p_qty}</td>
                                            {/* <td>{list.p_unit_qty} {list.p_unit}</td> */}
                                            <td>{list.actual_price}</td>
                                            <td>{list.p_price}</td>
                                            <td>{list.p_discount}</td>
                                            <td>{list.total_price}</td>
                                          </tr>
                                        )
                                      })
                                      : ''}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(OrderDetails);
