import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import OrderDetailsMdl from './../order/OrderDetailsMdl.js';
import OrderTrackMdl from './../order/OrderTrackMdl.js';
import Moment from 'react-moment';
import { fetch_status_string, fetch_status_btn_color, fetch_payment_mode_string } from '../../components/General_data.js';
class User_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      addressModel: false,
      detail_model_status: false,
      loyaltyModel: false,
      track_model_status: false,
      order_id_selected: '',
      orderModel: false,
      order_hash: false,
      actionType: 0,
      headerTitle: '',
      UserId: 0,
      user_list: [],
      orderList: [],
      loyaltyList: [],
      addressList: [],
    };
  }
  componentDidMount() {

    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/user-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ user_list: resolve.data, isLoading: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  getOrderAddress = (userId) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'userId': userId,
      'owner_flag': localStorage.getItem('owner_flag')
    }
    this.setState({ addressModel: true, addressList: [] });
    call_api('a/user-address-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ addressList: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  getOrderList = (userId) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'userId': userId
    }
    this.setState({ orderModel: true, order_hash: false, orderList: [] });
    call_api('a/user-order-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ orderList: resolve.data, order_hash: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  loyalty_point_report = (userId) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'userId': userId
    }
    this.setState({ loyaltyModel: true, loyaltyList: [] });
    call_api('a/user-loyalty-point-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ loyaltyList: resolve.data })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  editData = (data) => {
    notify.show("Page under construction", 'error', 20000);
  }
  orderDetail = (order_id) => {
    this.setState({
      detail_model_status: true, order_id_selected: order_id
    })
  }
  handleCallback = () => {
    this.setState({ detail_model_status: false, track_model_status: false, order_id_selected: '' })
  }
  track_order = (order_id) => {
    this.setState({
      track_model_status: true, order_id_selected: order_id
    })
  }
  render() {
    const { error, data_status, user_list } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 9999, timeout: 1000, top: '100px' }} />
          <OrderDetailsMdl model_status={this.state.detail_model_status} order_id={this.state.order_id_selected} parentCallback={this.handleCallback} />
          <OrderTrackMdl model_status={this.state.track_model_status} order_id={this.state.order_id_selected} parentCallback={this.handleCallback} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">User List</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Created</th>
                              <th>Name</th>
                              <th>User Id</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>State</th>
                              <th>City</th>
                              {/* <th>Pincode</th> */}
                              <th>Pass</th>
                              <th>Loyalty Point</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                user_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.created_on}</td>
                                      <td>{list.muser_name}</td>
                                      <td>{list.m_u_token}</td>
                                      <td>{list.muser_email || '--'}</td>
                                      <td>{list.muser_mobno}</td>
                                      <td>{list.state_name}</td>
                                      <td>{list.city_name || '--'}</td>
                                      {/* <td>{list.pind_code}</td> */}
                                      <td>{list.muser_password}</td>
                                      <td>{list.loyalty_point}</td>
                                      <td>{list.active_status == 0 ? <span className="label label-success">Active</span> : <span className="label label-danger">Inactive</span>}</td>
                                      <td>
                                        <button className="btn btn-sm btn-warning mr-2" onClick={() => this.editData(list)}><i className="fa fa-pencil-square-o"></i></button>
                                        <button className="btn btn-sm btn-primary mr-2" onClick={() => this.getOrderAddress(list.muser_id)}><i className="fa fa-map-marker"></i></button>
                                        <button title="Loyalty Point" className="btn btn-sm btn-success mr-2" onClick={() => this.loyalty_point_report(list.muser_id)}><i className="fa fa-gift"></i></button>
                                        <button className="btn btn-sm btn-info" onClick={() => this.getOrderList(list.muser_id)}><i className="fa fa-shopping-basket"></i></button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="12" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" fade={false} isOpen={this.state.addressModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ addressModel: false })}>X</button>}>
            <CardText className="modal-title">
              Delivery Address
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="table-responsive">
                  <table className="table mb-0 text-center">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Pincode</th>
                        <th>Area</th>
                        <th>Address</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.addressList.map((list, index) => {
                        return (
                          <tr key={"price" + index}>
                            <td>{list.id}</td>
                            <td>{list.fir_name} {list.lastname}</td>
                            <td>{list.mobilenumber}</td>
                            <td>{list.state_name}</td>
                            <td>{list.city_name}</td>
                            <td>{list.pinno}</td>
                            <td>{list.userarea}</td>
                            <td>{list.address_line1}</td>
                            <td>{list.addr_type == 1 ? 'Home' : list.addr_type == 2 ? 'Office' : 'Other'}</td>
                            <td>{list.addr_status == 1 ? <span className="label label-success">Active</span> : <span className="label label-danger">Inactive</span>}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div></div>
          </ModalFooter>
        </Modal>
        <Modal size="lg" fade={false} isOpen={this.state.orderModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ orderModel: false })}>X</button>}>
            <CardText className="modal-title">
              Order List
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="table-responsive">
                  <table className="table mb-0 text-center">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>P Mode</th>
                        <th>Status</th>
                        <th>Order Amount</th>
                        <th>Shipping Charge</th>
                        <th>Total</th>
                        <th>Plateform</th>
                        <th>Delivered</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.order_hash == true ?
                        this.state.orderList.map(list => (
                          <tr key={"order_" + list.order_invoice + "_" + list.order_id}>
                            <td>#{list.invoiceNumber}</td>
                            <td><Moment format="DD MMM YYYY">
                              {list.order_date}
                            </Moment></td>
                            <td>{fetch_payment_mode_string(list.p_mode)}</td>
                            <td><button className={"btn btn-xs " + fetch_status_btn_color(list.order_status)}>{fetch_status_string(list.order_status)}</button></td>
                            <td>{list.total_payable_amount}</td>
                            <td>{list.shipping_amount}</td>
                            <td>{list.final_amount}</td>
                            <td>{list.orderPlateform}</td>
                            <td>
                              {(list.delivery_date != '' && list.delivery_date != null) ? <Moment format="DD MMM YYYY">
                                {list.delivery_date}
                              </Moment> : '--'}</td>
                            <td>
                              {/* <Link target="_blank" to={"/order-details/" + list.order_id} data-original-title="View Detail" className="btn btn-info btn-sm"><i className="fa fa-eye" /></Link> */}
                              <button onClick={() => this.orderDetail(list.order_id)} className="btn btn-xs btn-dark mr-2">View</button>
                              <button onClick={() => this.track_order(list.order_id)} className="btn btn-xs btn-primary mr-2">Track</button>
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan="7" align={"center"}>Order not found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </Modal>
        <Modal size="lg" fade={false} isOpen={this.state.loyaltyModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ loyaltyModel: false })}>X</button>}>
            <CardText className="modal-title">
              Loyalty Point Report
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="table-responsive">
                  <table className="table mb-0 text-center">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Point</th>
                        <th>Percent</th>
                        <th>Description</th>
                        <th>View Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loyaltyList.length > 0 ?
                        this.state.loyaltyList.map(list => (
                          <tr key={"loyalty_" + list.muser_id + "_" + list.referrence_id}>
                            <td><Moment format="DD MMM YYYY">
                              {list.created_at}
                            </Moment></td>
                            <td>{list.credit_debit}</td>
                            <td>{list.loyalty_point}</td>
                            <td>{list.loyalty_percent}</td>
                            <td>{list.description}</td>
                            <td><button onClick={() => this.orderDetail(list.order_id)} className="btn btn-xs btn-dark mr-2">View</button></td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan="6" align={"center"}>Record not found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default User_list;
