import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from '../../components/General_data.js';
class OrderTrackMdl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      model_status: false,
      loading: false,
      order_details: [],
      tracking_list: [],
      shipment: [],
    };
  }
  log_order_details(order_ids) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'order_id': order_ids,
    }
    call_api('a/order-details', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({
              order_details: resolve.data, isLoading: false,
              tracking_list: resolve.tracking_list || [],
              shipment: resolve.shipment || [],
              data_status: true, model_status: true
            })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  componentWillReceiveProps(nextProps) {
    let order_ids = nextProps.order_id;
    let model_status = nextProps.model_status;
    if (order_ids && model_status == true) {
      this.log_order_details(order_ids);
    }
    else {
      this.setState({ model_status: false, data_status: false })
    }
  }
  componentDidMount() {
  }

  render() {
    const { isLoading, data_status, order_details, tracking_list } = this.state;
    return (
      <>
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
        <Modal size="md" fade={false} isOpen={this.state.model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.props.parentCallback()}>X</button>}>
            <CardText className="modal-title">
              Order Tracking {order_details.invoiceNumber || ''}
            </CardText>
          </ModalHeader>
          <ModalBody className="px-2 py-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    {isLoading == true ?
                      'Please Wait'
                      :
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                          <div className="accordion accordion-bordered" id="accordion-2" role="tablist">
                            <div className="row">
                              <div className="col-lg-4 col-xs-12 col-sm-6 col-md-4">
                                <div className="card">
                                  <div className="card-header" role="tab" id="heading-1">
                                    <h6>
                                      <a data-bs-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                        Order Details :-
                                      </a>
                                    </h6>
                                  </div>
                                  <div id="collapse-1" className="collapse" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion-2">
                                    <div className='card-body'>
                                      <div className='row'>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Order No :</label>
                                            <span>{order_details.invoiceNumber}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Order Date :</label>
                                            <span>{order_details.order_date}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Order Status :</label>
                                            <span className={"label " + fetch_status_color(order_details.order_status)}>{fetch_status_string(order_details.order_status)}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Payment Mode :</label>
                                            <span>{fetch_payment_mode_string(order_details.p_mode)}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Order Amount :</label>
                                            <span>Rs.{order_details.order_amount}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Shipping Amount :</label>
                                            <span>Rs.{order_details.shipping_amount}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Delivery Date :</label>
                                            <span>{order_details.delivery_date || '--'}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Total Amount :</label>
                                            <span>Rs.{order_details.final_amount}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xs-12 col-sm-6 col-md-4">
                                <div className="card">
                                  <div className="card-header" role="tab" id="heading-2">
                                    <h6>
                                      <a data-bs-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                        User Details :-
                                      </a>
                                    </h6>
                                  </div>
                                  <div id="collapse-2" className="collapse" role="tabpanel" aria-labelledby="heading-2" data-bs-parent="#accordion-2">
                                    <div className='card-body'>
                                      <div className='row'>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Name :</label>
                                            <span>{order_details.muser_name}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Contact :</label>
                                            <span>{order_details.mobilenumber}</span>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                          <div>
                                            <label className="control-label">Address :</label>
                                            <span>{order_details.muser_address}</span>
                                            <span>{order_details.area_name}</span>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                          <div>
                                            <label className="control-label">City & State :</label>
                                            <span>{order_details.city_name}-{order_details.state_name}</span>
                                            <span>{order_details.o_pincode_id}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Address Type :</label>
                                            <span>{((order_details.addr_type == 1) ? 'Home' : (order_details.addr_type == 2) ? 'Office' : 'Other')}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xs-12 col-sm-6 col-md-4">
                                <div className="card">
                                  <div className="card-header" role="tab" id="heading-3">
                                    <h6>
                                      <a data-bs-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                        Courier Details :-
                                      </a>
                                    </h6>
                                  </div>
                                  <div id="collapse-3" className="collapse" role="tabpanel" aria-labelledby="heading-3" data-bs-parent="#accordion-2">
                                    <div className='card-body'>
                                      <div className='row'>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Courier Id :</label>
                                            <span>{this.state.shipment.courier_company_id || '---'}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Courier Name :</label>
                                            <span>{this.state.shipment.courier_name || '---'}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">AWB Code :</label>
                                            <span>{this.state.shipment.awb_code || '---'}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Pickup Schedule :</label>
                                            <span>{this.state.shipment.pickup_scheduled_date || '---'}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Shipment Id :</label>
                                            <span>{this.state.shipment.shipment_id || '---'}</span>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div>
                                            <label className="control-label">Shipment Order Id :</label>
                                            <span>{this.state.shipment.sub_order_id || '---'}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 mt-2">
                          <div className="table-responsive">
                            <table className="table mb-0 text-center">
                              <thead>
                                <tr>
                                  <th>Sr No</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  data_status == true ?
                                    tracking_list.map((list, index) => {
                                      return (
                                        <tr key={"row" + index}>
                                          <td>{index + 1}</td>
                                          <td>{list.created_at}</td>
                                          <td>{list.order_status_text}</td>
                                          <td>
                                            <h6 style={{ fontSize: '0.75rem' }}>{list.description}</h6>
                                            <p>{list.location}</p>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    : <tr>
                                      <td colSpan={8}></td>
                                    </tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default OrderTrackMdl;
