import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import FadeIn from "react-lazyload-fadein";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import notfoundImg from "../../assets/img/no_image.jpg";
class Product_brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      upload_image: '',
      imageSelect: false,
      loading: false,
      data_status: false,
      modalShow: false,
      loading_text: "Please wait loading",
      brand_list: [],
    };
  }
  componentDidMount() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/brand-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ brand_list: resolve.data, data_status: true })
          }
          else {
            this.setState({ loading_text: resolve.message });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  update_product_brand() {
    var file = this.state.upload_image;
    let brand_name = this.state.brand_name;
    let id = this.state.id;
    if (brand_name == '') {
      notify.show("Enter Brand Name", 'error', 2000);
    }
    else {
      this.setState({ loading: true })
      let optopns = { 'image': file, 'id': this.state.id, 'brand_name': this.state.brand_name, 'LoginToken': localStorage.getItem('LoginToken') };
      call_api('a/brand-update', optopns)
        .then(
          (resolve) => {
            if (resolve['status'] == true) {
              this.setState({ brand_list: resolve['data'], editModel: false, upload_image: '', loading: false, data_status: true })
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  save_new_brand() {
    let brand_name = this.state.brand_name;
    var file = this.refs.file.files[0];
    var reader = new FileReader();
    if (brand_name == '') {
      notify.show("Enter Brand Name", 'error', 2000);
    }
    else {
      if (file != null) {
        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
          var url = reader.readAsDataURL(file);
          this.setState({ loading: true })
          reader.onloadend = function (e) {
            let optopns = { 'image': reader.result, 'brand_name': this.state.brand_name, 'LoginToken': localStorage.getItem('LoginToken') };
            call_api('a/add-prod-brand', optopns)
              .then(
                (resolve) => {
                  if (resolve.status == true) {
                    this.setState({ brand_list: resolve.data, editModel: false, loading: false, data_status: true })
                  }
                  else {
                    notify.show(resolve.message, 'error', 2000);
                    this.setState({ loading: false });
                  }
                },
                (reject) => {
                  console.log(reject);
                }
              )
          }.bind(this);
        }
        else {
          notify.show("Select Valid Image", 'error', 2000);
        }
      }
      else {
        notify.show("Select Valid Image", 'error', 2000);
      }
    }
  }
  editData = (list) => {
    this.setState({
      pg_id1: 1, brand_name: list.brand_name, upload_image: '', imageLink: apiUrl + list.brand_logo, editModel: true, modelHeader: 'Edit Item Brand',
      id: list.id
    })
  }
  add_new_prod_brand() {
    this.setState({
      pg_id1: 0, brand_name: '', upload_image: '', editModel: true, imageLink: '', modelHeader: 'Add New Item Brands'
    })
  }
  changeStatus(id, brand_status) {
    let optopns = { 'brand_status': brand_status, 'id': id };
    call_api('a/brand-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ brand_list: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  changePriveImage(event) {
    let image = event.target.files[0];
    let mythis = this;
    if (image != null) {
      if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg" || image.type == "image/webp") {
        var reader = new FileReader();
        var url = reader.readAsDataURL(image);
        reader.onloadend = function (e) {
          mythis.setState({
            upload_image: reader.result
          })
        }
        this.setState({
          imageLink: URL.createObjectURL(image), displayUbtn: true
        })
      }
      else {
        this.setState({
          upload_image: ''
        })
      }
    }
    else {
      this.setState({
        upload_image: ''
      })
    }
  }
  render() {
    const { data_status, brand_list } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Item Brand</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <button type="button" onClick={() => this.add_new_prod_brand()} className="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                    <i className="mdi mdi-plus-circle"></i> Add New Brand </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Brand Name</th>
                              <th>Brand Logo</th>
                              <th>Date </th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                brand_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.brand_name}</td>
                                      <td>
                                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img src={apiUrl + list.brand_logo} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} onLoad={onload} style={{ height: '100px', width: '100px' }} />
                                          )}
                                        </FadeIn>
                                      </td>
                                      <td>{list.create_at}</td>
                                      <td>{list.brand_status == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.changeStatus(list.id, 0)}>Active</button> : <button className="btn btn-danger btn-xs" onClick={(e) => this.changeStatus(list.id, 1)}>Inactive</button>}</td>
                                      <td><button className="btn btn-sm btn-success" onClick={() => this.editData(list, 'edit')}>Edit</button></td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="6" align="center">{this.state.loading_text}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader>
            <button type="button" className="close" onClick={() => this.setState({ editModel: false })} aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="form-wrap form-horizontal">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="brand_name" className="col-md-4 control-label"><span className="text-danger">*</span> Brand Name</label>
                  <div className="col-md-8">
                    <input type="text" ref="brand_name" name="brand_name" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.brand_name || ''} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="brand_img" className="col-md-4 control-label"><span className="text-danger">*</span> Brand Image</label>
                  <div className="col-md-8">
                    <input type="file" ref="file" id="Inputfile" accept="image/*" name="brand_img" onChange={(event) => this.changePriveImage(event)} className="form-control" />
                  </div>
                  <br />
                  <div className="text-center mt-5">
                    <img src={this.state.imageLink} width="200px" />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.pg_id1 != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.update_product_brand()} className="btn btn-sm btn-primary"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_new_brand()} className="btn btn-sm btn-primary"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default Product_brand;
