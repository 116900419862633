import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
class WebsiteProductDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      modalShow: false,
      web_prod_list: [],
      categorylist: [],
      brand_list: [],
    };
  }
  componentDidMount() {
    this.fetch_prod_display();
    this.fetch_category();
    this.fetch_brand();
  }
  fetch_prod_display() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/web-prod-setting', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ web_prod_list: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_category() {

    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_brand() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/brand-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ brand_list: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  update_web_prod() {
    let section_header = this.state.section_header;
    let section_type = this.state.section_type;
    let section_id = this.state.section_id;
    let section_ordering = this.state.section_ordering;
    let website_location = this.state.website_location;
    let id = this.state.id;

    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'section_header': section_header,
      'section_type': section_type,
      'section_id': section_id,
      'section_ordering': section_ordering,
      'website_location': website_location,
      'id': id
    }
    if (section_header == '') {
      notify.show("Enter title", 'error', 2000);
    }
    else if (section_type == '0') {
      notify.show("Select group type", 'error', 2000);
    }
    else if (section_id == '0') {
      notify.show("Select group", 'error', 2000);
    }
    else if (section_ordering == '0') {
      notify.show("Enter sequence", 'error', 2000);
    }
    else {
      this.setState({ loading: true })
      call_api('a/web-prod-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ web_prod_list: resolve.data, editModel: false, loading: false, data_status: true })
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  save_web_prod() {
    let section_header = this.state.section_header;
    let section_type = this.state.section_type;
    let section_id = this.state.section_id;
    let section_ordering = this.state.section_ordering;
    let website_location = this.state.website_location;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'section_header': section_header,
      'section_type': section_type,
      'section_id': section_id,
      'section_ordering': section_ordering,
      'website_location': website_location,

    }
    if (section_header == '') {
      notify.show("Enter title", 'error', 2000);
    }
    else if (section_type == '0') {
      notify.show("Select group type", 'error', 2000);
    }
    else if (section_id == '0') {
      notify.show("Select group", 'error', 2000);
    }
    else if (section_ordering == '0') {
      notify.show("Enter sequence", 'error', 2000);
    }
    else {
      this.setState({ loading: true })
      call_api('a/add-web-prod', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ web_prod_list: resolve.data, editModel: false, loading: false, data_status: true })
              notify.show(resolve.message, 'success', 2000);
              window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  editData = (list) => {
    this.setState({
      pg_id1: 1, id: list.id, section_ordering: list.section_ordering, section_header: list.section_header, section_id: list.section_id,
      section_type: list.section_type, editModel: true, modelHeader: 'Edit Item Display Website', id: list.id, website_location: list.website_location
    })
  }
  add_new_prod_brand() {
    this.setState({
      pg_id1: 0, editModel: true, section_header: '', section_id: 0, section_type: 0, section_ordering: '',
      modelHeader: 'Add Item Display Website', website_location: ''
    })
  }


  changeStatus(id, section_status) {
    let optopns = { 'section_status': section_status, 'id': id };
    call_api('a/web-prod-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ web_prod_list: resolve.data, modalShow: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  render() {
    const { data_status, web_prod_list, categorylist, brand_list } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Website Item Section</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <Button type="button" onClick={() => this.add_new_prod_brand()} className="btn btn-sm btn-info">Add New Section</Button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Sequence</th>
                              <th>Title</th>
                              <th>Group Name </th>
                              <th>Group Type </th>
                              <th>Date </th>
                              <th>Status</th>
                              <th>Useable</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                web_prod_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.section_ordering}</td>
                                      <td>{list.section_header}</td>
                                      <td>{list.section_type == 3 ? list.brand_name : list.group_name}</td>
                                      <td>{list.section_type == 1 ? "Main category" : list.section_type == 2 ? "Sub category" : "Brand"}</td>
                                      <td>{list.section_created}</td>
                                      <td>{list.section_status == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.changeStatus(list.id, 0)}>Active</button> : <button className="btn btn-danger btn-sm" onClick={(e) => this.changeStatus(list.id, 1)}>Inactive</button>}</td>
                                      <td>{list.website_location}</td>
                                      <td><button className="btn btn-sm btn-success" onClick={() => this.editData(list, 'edit')}>Edit</button></td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="9" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="section_header" className="col-md-4 control-label"><span className="text-danger">*</span> Header Name</label>
                  <div className="col-md-8">
                    <input type="text" name="section_header" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.section_header || ''} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="section_type" className="col-md-4 control-label"><span className="text-danger">*</span> Group Type</label>
                  <div className="col-md-8">
                    <select type="text" name="section_type" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.section_type} className="form-control" >
                      <option value="0">Select Type</option>
                      <option value="1">Main Category</option>
                      <option value="2">Sub category</option>
                      <option value="3">Brand</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="website_location" className="col-md-4 control-label"><span className="text-danger">*</span> Usable</label>
                  <div className="col-md-8">
                    <select type="text" name="website_location" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.website_location} className="form-control" >
                      <option value="HOME">Home</option>
                      <option value="FOOTER">Footer</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="section_id" className="col-md-4 control-label"><span className="text-danger">*</span> Group Name</label>
                  <div className="col-md-8">
                    <select type="text" name="section_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.section_id} className="form-control" >
                      {this.state.section_type != '3' ?
                        <>
                          <option value="0">Select Group</option>
                          {
                            categorylist.map((list, index) => {
                              return (
                                (list.main_group_flag == this.state.section_type) ?
                                  <option key={"m_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                                  : ''
                              )
                            })
                          }
                        </>
                        :
                        <>
                          <option value="0">Select Brand</option>
                          {
                            brand_list.map((list, index) => {
                              return (
                                <option key={"m_" + index} value={list.id}>{list.brand_name}</option>
                              )
                            })
                          }
                        </>
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="section_ordering" className="col-md-4 control-label"><span className="text-danger">*</span> Sequence Number</label>
                  <div className="col-md-8">
                    <input type="number" name="section_ordering" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.section_ordering || '0'} className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.pg_id1 != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.update_web_prod()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_web_prod()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default WebsiteProductDisplay;
