import React, { Component } from 'react';
import LocationModel from '../components/master/LocationModel.js';
import { call_api } from '../connect/Connect.js';
import { Button } from 'reactstrap';
import { c_s_c_status_jquery } from "../components/jquery/location_jquery.js";
import Notifications, { notify } from 'react-notify-toast';
class CityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      model_status: false,
      city_list: [],
      modelData: '',
      country_state_city: [],
      city_id: ''
    };
    this.onChange = this.onChange.bind(this);
  }


  componentDidMount() {
    c_s_c_status_jquery(call_api);
    this.fetch_city_list();
    this.fetch_location();
  }

  onChange(event) {
    this.setState({
      model_status: false
    });
  }
  handleCallback = (type, status) => {
    this.setState({ model_status: status });
    if (type == 'city' && status == false) {
      this.fetch_city_list();
    }
  }
  fetch_city_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/admin-city', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ city_list: resolve.data, tem_city_list: resolve.data, isLoading: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_location = () => {
    call_api('m/fetch_country_state_city_option', {})
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({ country_state_city: resolve['data'], pincode: resolve['pincode'], model_status: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  filter_list_data = (type, id) => {
    let list = this.state.tem_city_list;
    if (type == 'country_id') {
      var filter_data = list.filter(item => item.countryId === id);
      this.setState({ state_id: 0 });
    }
    else {
      var filter_data = list.filter(item => item.stateCode === id);
    }
    this.setState({
      city_list: filter_data
    })
  }

  editData = (list, modelType) => {
    this.setState({ modelData: list, model_status: true, modelType: modelType })
  }
  render() {
    const { isLoading, data_status, city_list, modelData, model_status } = this.state;
    return (
      <div>
        <LocationModel parentCallback={this.handleCallback} model_status={model_status} modelType={this.state.modelType} modelData={modelData} />
        <div className="page-wrapper" style={{ minHeight: "359px" }}>
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
          <div className="container-fluid">
            <div className="row heading-bg">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 className="txt-dark">City List</h5>
              </div>
              <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol className="breadcrumb">
                  <li><a href="/">Dashboard</a></li>
                  <li className="active"><span>City</span></li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      {/* <button className="btn btn-sm btn-success" onClick={() => this.editData('', 'cityAdd')}>Add New City</button> <br></br>
                        <p className="text-muted">Search</p> */}
                      {/* <div className="row" style={{ marginTop: "10px" }}>
                          <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="col-md-4 control-label"><span className="text-danger"></span>Country</label>
                              <div className="col-md-8">
                                <select name="country_id" value={this.state.country_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('country_id', e.target.value);}} className="form-control" id="country_id">
                                  <option key={"c00"} value="">Select Country</option>
                                  {this.state.country_state_city.map((country, index) => {
                                    return (
                                      (country.l_master_type == 1) ?
                                        <option key={'c' + index} value={country.id}>{country.name}</option>
                                        : ''
                                    )
                                  }
                                  )
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="col-md-4 control-label"><span className="text-danger"></span>State</label>
                              <div className="col-md-8">
                                <select ref="state_id" name="state_id" value={this.state.state_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('state_id', e.target.value) }} className="form-control" id="state_id">
                                  <option key={"s00"}>Select State</option>
                                  {this.state.country_state_city.map((state, index) => {
                                    return (
                                      (state.l_master_type == 2 && this.state.country_id == state.l3) ?
                                        <option key={'c' + index} value={state.l1}>{state.name}</option>
                                        : ''
                                    )
                                  }
                                  )
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      <hr></hr>
                      <div className="table-wrap mt-40">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Country Name</th>
                                <th>State Name</th>
                                <th>City Name</th>
                                <th>City Code</th>
                                <th>Status</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data_status == true ?
                                  city_list.map((list, index) => {
                                    return (
                                      <tr key={"row" + index}>
                                        <td>{index + 1}</td>
                                        <td>{list.country_name}</td>
                                        <td>{list.state_name}</td>
                                        <td>{list.city_name}</td>
                                        <td>{list.city_code}</td>
                                        <td>{list.l_status == 1 ? <span data-id={list.id} data-status="2" className="label label-success cursor c_s_c_locationStatus">Active</span> : <span data-id={list.id} data-status="1" className="label label-danger cursor c_s_c_locationStatus">Inactive</span>}</td>
                                        <td><button className="btn btn-xs btn-success" onClick={() => this.editData(list, 'city')}>Edit</button></td>
                                      </tr>
                                    )
                                  })
                                  : <tr><td colSpan="7" align="center">Please Wait</td></tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CityList;
