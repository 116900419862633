import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Input, CardText } from 'reactstrap';
import { call_api } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
class LocationModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            countrymodel: false,
            areaModel: false,
            state_model: false,
            state_name: 22,
            state: [],
            city: [],
            area_name: '',
            city_code: '',
            city_name: '',
            regexp: /^[0-9\b]+$/,
            country: [],
            country_state_city: [],
            pincode_list: [],
            model_status: false,
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }
    componentDidMount() {
        this.fetch_location();
    }
    onChangeValue(event) {
        this.setState({
            l_status: event.target.value,
            model_status: false
        });
    }
    onTrigger = (type) => {
        this.props.parentCallback(type, false);

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.modelType == 'country') {
            let data = nextProps.modelData;
            this.setState({
                countrymodel: nextProps.model_status,
                l_status: data.l_status,
                country_code: data.country_code,
                id: data.id,
                country_name: data.country_name
            });
        }
        else if (nextProps.modelType == 'state') {
            let data = nextProps.modelData;
            this.setState({
                state_model: nextProps.model_status,
                l_status: data.l_status,
                state_code: data.state_code,
                state_name: data.state_name,
                id: data.id,
                country_name: data.country_name
            });
        }
        else if (nextProps.modelType == 'pincode') {
            let data = nextProps.modelData;
            this.setState({
                pincodeModel: nextProps.model_status,
                pincode: data.pincode,
                id: data.p_id,
                l_status: data.p_status
            });
        }
        else if (nextProps.modelType == 'area') {
            let data = nextProps.modelData;
            this.setState({
                areaModel: nextProps.model_status,
                area_name: data.area_name,
                id: data.a_id,
                l_status: data.a_status
            });
        }
        else if (nextProps.modelType == 'city') {
            let data = nextProps.modelData;
            this.setState({
                cityModel: nextProps.model_status,
                city_name: data.city_name,
                city_code: data.city_code,
                id: data.id,
                l_status: data.l_status
            });
        }
        else if (nextProps.modelType == 'cityAdd') {
            this.setState({
                cityaddModel: nextProps.model_status,
                city_name: '',
                city_code: '',
                country_name: 1,
                stateCode: '',
                state_name: '',
                id: '0',
                l_status: 1
            });
        }
        else if (nextProps.modelType == 'addArea') {
            this.setState({
                areaAddModel
                    : nextProps.model_status,
                country_name: 1,
                state_name: 22,
                city_name: 0,
                p_id: '0',
                area_name: '',
                l_status: 1
            });
        }
        else if (nextProps.modelType == 'addpincode') {
            this.setState({
                pincodeAddModel
                    : nextProps.model_status,
                country_name: '1',
                state_name: '22',
                city_name: '0',
                cityId: '0',
                id: '0',
                pincode: '',
                l_status: '1'
            });
        }

    }
    save_add_city_data() {
        let options = {
            l_status: this.state.l_status,
            city_name: this.state.city_name,
            stateCode: this.state.state_name,
            city_code: this.state.city_code
        };
        if (this.state.city_name == '') {
            notify.show('Enter city name', 'error', 2000);
        }
        if (this.state.city_code == '') {
            notify.show('Enter city code', 'error', 2000);
        }
        else {
            this.setState({ loading: true });
            call_api('a/admin-city-save', options)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            this.setState({ loading: false, cityaddModel: false })
                            notify.show(resolve.message, 'success', 2000);
                            this.onTrigger('city')
                        }
                        else {
                            notify.show(resolve.message, 'error', 2000);
                            this.setState({ loading: false })
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }
    save_add_pincode_data() {
        let options = {
            l_status: this.state.l_status,
            cityId: this.state.city_name,
            pincode: this.state.pincode
        };
        if (this.state.pincode == '') {
            notify.show('Enter Pincode', 'error', 2000);
        }
        if (String(this.state.pincode).length != 6) {
            notify.show('Enter 6 digit pincode', 'error', 2000);
        }
        else {
            this.setState({ loading: true });
            call_api('a/admin-pincode-save', options)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            this.setState({ loading: false, pincodeAddModel: false })
                            notify.show(resolve.message, 'success', 2000);
                            this.onTrigger('pincode')
                        }
                        else {
                            notify.show(resolve.message, 'error', 2000);
                            this.setState({ loading: false })
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }
    save_add_area_data() {
        let options = {
            l_status: this.state.l_status,
            p_id: this.state.pincode,
            area_name: this.state.area_name
        };
        if (this.state.area_name == '') {
            notify.show('Enter Service Area Name', 'error', 2000);
        }
        else {
            this.setState({ loading: true });
            call_api('a/admin-area-save', options)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            this.setState({ loading: false, areaAddModel: false })
                            notify.show(resolve.message, 'success', 2000);
                            this.onTrigger('area')
                        }
                        else {
                            notify.show(resolve.message, 'error', 2000);
                            this.setState({ loading: false })
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }
    save_update_city_data() {
        let options = {
            l_status: this.state.l_status,
            city_name: this.state.city_name,
            id: this.state.id,
            city_code: this.state.city_code
        };
        this.setState({ loading: true });
        call_api('a/admin-city-update', options)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        this.setState({ loading: false, cityModel: false })
                        notify.show(resolve.message, 'success', 2000);
                        this.onTrigger('city')
                    }
                    else {
                        notify.show(resolve.message, 'error', 2000);
                        this.setState({ loading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    save_update_country_data() {
        let options = {
            l_status: this.state.l_status,
            country_code: this.state.country_code,
            id: this.state.id,
            country_name: this.state.country_name
        };
        this.setState({ loading: true });
        call_api('a/admin-country-edit', options)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        this.setState({ loading: false, countrymodel: false })
                        notify.show(resolve.message, 'success', 2000);
                        this.onTrigger('country')
                    }
                    else {
                        notify.show(resolve.message, 'error', 2000);
                        this.setState({ loading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    save_update_state_data() {
        let options = {
            l_status: this.state.l_status,
            state_code: this.state.state_code,
            id: this.state.id,
            state_name: this.state.state_name
        };
        this.setState({ loading: true });
        call_api('a/admin-state-edit', options)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        this.setState({ loading: false, state_model: false })
                        notify.show(resolve.message, 'success', 2000);
                        this.onTrigger('state')
                    }
                    else {
                        notify.show(resolve.message, 'error', 2000);
                        this.setState({ loading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    save_update_area_data() {
        let options = {
            l_status: this.state.l_status,
            area_name: this.state.area_name,
            id: this.state.id
        };
        this.setState({ loading: true });
        call_api('a/admin-area-update', options)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        this.setState({ loading: false, areaModel: false });
                        notify.show(resolve.message, 'success', 2000);
                        this.onTrigger('area')
                    }
                    else {
                        notify.show(resolve.message, 'error', 2000);
                        this.setState({ loading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    save_update_pincode_data() {
        let options = {
            l_status: this.state.l_status,
            pincode: this.state.pincode,
            id: this.state.id
        };
        this.setState({ loading: true });
        call_api('a/admin-pincode-update', options)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        this.setState({ loading: false, pincodeModel: false });
                        notify.show(resolve.message, 'success', 2000);
                        this.onTrigger('pincode')
                    }
                    else {
                        notify.show(resolve.message, 'error', 2000);
                        this.setState({ loading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    fetch_location = () => {
        call_api('m/fetch_country_state_city_option', {})
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({ country_state_city: resolve['data'], pincode: resolve['pincode'], pincode_list: resolve['pincode'] });
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    render() {
        const { loading, countrymodel, } = this.state;
        return (
            <div>
                <Notifications options={{ animationDuration: 200, timeout: 3000, top: '50px' }} />
                {countrymodel == true ?
                    <Modal size="md" fade={false} isOpen={countrymodel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('country') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Edit Country
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="country_name" className="col-md-4 control-label"><span className="text-danger">*</span>Country Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="country_name" value={this.state.country_name} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="country_name" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="country_code" className="col-md-4 control-label"><span className="text-danger">*</span>Country Code</label>
                                        <div className="col-md-8">
                                            <input type="text" name="country_code" value={this.state.country_code} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="country_code" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_update_country_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Submit</Button>
                        </ModalFooter>
                    </Modal>
                    : ''}
                {this.state.state_model == true ?
                    <Modal size="md" fade={false} isOpen={this.state.state_model} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={(e) => { this.onTrigger('state') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Edit State
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="country_name" className="col-md-4 control-label"><span className="text-danger">*</span>Country Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="country_name" value={this.state.country_name || ''} readOnly className="form-control" id="country_name" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="state_name" className="col-md-4 control-label"><span className="text-danger">*</span>State Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="state_name" value={this.state.state_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="state_name" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="state_code" className="col-md-4 control-label"><span className="text-danger">*</span>State Code</label>
                                        <div className="col-md-8">
                                            <input type="text" name="state_code" value={this.state.state_code || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="state_code" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_update_state_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Submit</Button>
                        </ModalFooter>
                    </Modal>
                    : ''}
                {this.state.cityModel == true ?
                    <Modal size="md" fade={false} isOpen={this.state.cityModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('city') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Edit City
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="city_name" className="col-md-4 control-label"><span className="text-danger">*</span>City Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="city_name" value={this.state.city_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_name" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="city_code" className="col-md-4 control-label"><span className="text-danger">*</span>City Code</label>
                                        <div className="col-md-8">
                                            <input type="text" name="city_code" value={this.state.city_code || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_code" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_update_city_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Submit</Button>
                        </ModalFooter>
                    </Modal> : ''}
                {this.state.pincodeModel == true ?
                    <Modal size="md" fade={false} isOpen={this.state.pincodeModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('pincode') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Edit Pincode
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="pincode" className="col-md-4 control-label"><span className="text-danger">*</span>Pincode</label>
                                        <div className="col-md-8">
                                            <input type="text" name="pincode" maxLength="6" value={this.state.pincode || ''} onChange={(e) => (e.target.value === '' || this.state.regexp.test(e.target.value)) ? this.setState({ [e.target.name]: e.target.value }) : ''} className="form-control" id="pincode" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_update_pincode_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</Button>
                        </ModalFooter>
                    </Modal> : ''}
                {this.state.areaModel == true ?
                    <Modal size="md" fade={false} isOpen={this.state.areaModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('area') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Edit Area Name
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="area_name" className="col-md-4 control-label"><span className="text-danger">*</span>Area Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="area_name" value={this.state.area_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="area_name" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_update_area_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Submit</Button>
                        </ModalFooter>
                    </Modal> : ''}
                {this.state.cityaddModel == true ?
                    <Modal size="md" fade={false} isOpen={this.state.cityaddModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('city') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Add City
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    {/* <div className="form-group">
                                    <label htmlFor="country_name" className="col-md-4 control-label"><span className="text-danger">*</span>Country</label>
                                    <div className="col-md-8">
                                        <select name="country_name" value={this.state.country_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="country_name">
                                            <option key={"c00"} value="">Select Country</option>
                                            {this.state.country_state_city.map((country, index) => {
                                                return (
                                                    (country.l_master_type == 1) ?
                                                        <option key={'c' + index} value={country.id}>{country.name}</option>
                                                        : ''
                                                )
                                            }
                                            )
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                    {/* <div className="form-group">
                                    <label htmlFor="state_name" className="col-md-4 control-label"><span className="text-danger">*</span>State</label>
                                    <div className="col-md-8">
                                        <select ref="state_name" name="state_name" value={this.state.state_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="state_name">
                                            <option key={"s00"}>Select State</option>
                                            {this.state.country_state_city.map((state, index) => {
                                                return (
                                                    (state.l_master_type == 2 && this.state.country_name == state.l3) ?
                                                        <option key={'c' + index} value={state.l1}>{state.name}</option>
                                                        : ''
                                                )
                                            }
                                            )
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                    <div className="form-group">
                                        <label htmlFor="city_name" className="col-md-4 control-label"><span className="text-danger">*</span>City Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="city_name" value={this.state.city_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_name" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="city_code" className="col-md-4 control-label"><span className="text-danger">*</span>City Code</label>
                                        <div className="col-md-8">
                                            <input type="text" name="city_code" value={this.state.city_code || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_code" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_add_city_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</Button>
                        </ModalFooter>
                    </Modal> : ''}
                {this.state.areaAddModel == true ?
                    <Modal size="md" fade={false} isOpen={this.state.areaAddModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('area') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Add Area
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    {/* <div className="form-group">
                                    <label htmlFor="country_name" className="col-md-4 control-label"><span className="text-danger">*</span>Country</label>
                                    <div className="col-md-8">
                                        <select name="country_name" value={this.state.country_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="country_name">
                                            <option key={"c00"} value="">Select Country</option>
                                            {this.state.country_state_city.map((country, index) => {
                                                return (
                                                    (country.l_master_type == 1) ?
                                                        <option key={'c' + index} value={country.id}>{country.name}</option>
                                                        : ''
                                                )
                                            }
                                            )
                                            }
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div> */}
                                    {/* <div className="form-group">
                                    <label htmlFor="state_name" className="col-md-4 control-label"><span className="text-danger">*</span>State</label>
                                    <div className="col-md-8">
                                        <select ref="state_name" name="state_name" value={this.state.state_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="state_name">
                                            <option key={"s00"}>Select State</option>
                                            {this.state.country_state_city.map((state, index) => {
                                                return (
                                                    (state.l_master_type == 2 && this.state.country_name == state.l3) ?
                                                        <option key={'c' + index} value={state.l1}>{state.name}</option>
                                                        : ''
                                                )
                                            }
                                            )
                                            }
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div> */}
                                    <div className="form-group">
                                        <label htmlFor="city_name" className="col-md-4 control-label"><span className="text-danger">*</span>City</label>
                                        <div className="col-md-8">
                                            <select ref="city_name" name="city_name" value={this.state.city_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_name">
                                                <option key={"c00"}>Select City</option>
                                                {this.state.country_state_city.map((city, index) => {
                                                    return (
                                                        (city.l_master_type == 3 && this.state.state_name == city.l3) ?
                                                            <option key={'c' + index} value={city.id}>{city.name}</option>
                                                            : ''
                                                    )
                                                }
                                                )
                                                }
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pincode" className="col-md-4 control-label"><span className="text-danger">*</span>Pincode</label>
                                        <div className="col-md-8">
                                            <select ref="pincode" name="pincode" value={this.state.pincode || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="pincode">
                                                <option key={"p00"}>Select Pincode</option>
                                                {this.state.pincode_list.map((pins, index) => {
                                                    return (
                                                        (this.state.city_name == pins.city_id) ?
                                                            <option key={'p' + index} value={pins.p_id}>{pins.pincode}</option>
                                                            : ''
                                                    )
                                                }
                                                )
                                                }
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="area_name" className="col-md-4 control-label"><span className="text-danger">*</span>Area Name</label>
                                        <div className="col-md-8">
                                            <input type="text" name="area_name" value={this.state.area_name} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="area_name" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_add_area_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Submit</Button>
                        </ModalFooter>
                    </Modal> : ''}
                {this.state.pincodeAddModel == true ?
                    <Modal size="md" fade={false} isOpen={this.state.pincodeAddModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                        <ModalHeader>
                            <button type="button" className="close" onClick={() => { this.onTrigger('pincode') }} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <CardText className="modal-title">
                                Add Pincode
                        </CardText>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-wrap form-horizontal">
                                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                    {/* <div className="form-group">
                                    <label htmlFor="country_name" className="col-md-4 control-label"><span className="text-danger">*</span>Country</label>
                                    <div className="col-md-8">
                                        <select name="country_name" value={this.state.country_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="country_name">
                                            <option key={"c00"} value="">Select Country</option>
                                            {this.state.country_state_city.map((country, index) => {
                                                return (
                                                    (country.l_master_type == 1) ?
                                                        <option key={'c' + index} value={country.id}>{country.name}</option>
                                                        : ''
                                                )
                                            }
                                            )
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                    {/* <div className="form-group">
                                    <label htmlFor="state_name" className="col-md-4 control-label"><span className="text-danger">*</span>State</label>
                                    <div className="col-md-8">
                                        <select ref="state_name" name="state_name" value={this.state.state_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="state_name">
                                            <option key={"s00"}>Select State</option>
                                            {this.state.country_state_city.map((state, index) => {
                                                return (
                                                    (state.l_master_type == 2 && this.state.country_name == state.l3) ?
                                                        <option key={'c' + index} value={state.l1}>{state.name}</option>
                                                        : ''
                                                )
                                            }
                                            )
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                    <div className="form-group">
                                        <label htmlFor="city_name" className="col-md-4 control-label"><span className="text-danger">*</span>City</label>
                                        <div className="col-md-8">
                                            {/* <input type="text" name="city_name" value={this.state.city_name} readOnly className="form-control" id="city_name" /> */}
                                            <select ref="city_name" name="city_name" value={this.state.city_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_name">
                                                <option key={"c00"}>Select City</option>
                                                {this.state.country_state_city.map((city, index) => {
                                                    return (
                                                        (city.l_master_type == 3 && this.state.state_name == city.l3) ?
                                                            <option key={'c' + index} value={city.id}>{city.name}</option>
                                                            : ''
                                                    )
                                                }
                                                )
                                                }
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pincode" className="col-md-4 control-label"><span className="text-danger">*</span>Pincode</label>
                                        <div className="col-md-8">
                                            <input type="text" name="pincode" maxLength="6" value={this.state.pincode || ''} onChange={(e) => (e.target.value === '' || this.state.regexp.test(e.target.value)) ? this.setState({ [e.target.name]: e.target.value }) : ''} className="form-control" id="pincode" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-4 control-label"><span className="text-danger">*</span>Status</label>
                                        <div className="col-md-8">
                                            <div className="radio radio-info">
                                                <input type="radio" id="Active" checked={this.state.l_status == 1 ? true : false} onChange={this.onChangeValue} value="1" name="status" />
                                                <label htmlFor="Active">
                                                    Active
											</label>
                                            </div>
                                            <div className="radio radio-info">
                                                <input type="radio" id="Inactive" checked={this.state.l_status == 2 ? true : false} onChange={this.onChangeValue} value="2" name="status" />
                                                <label htmlFor="Inactive">
                                                    Inactive
											</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" disabled={loading} onClick={() => this.save_add_pincode_data()} className="btn btn-primary btn-sm"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</Button>
                        </ModalFooter>
                    </Modal> : ''}
            </div>
        );
    }
}

export default LocationModel;
