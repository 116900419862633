import React, { Component } from 'react';
import parse from 'html-react-parser';
class RatingShow extends Component {
  constructor(props) {
    super(props);
    if (props) {
      if (props.total_count || props.data) {
        this.display_rating_section(props.data, props.total_count);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    //this.display_rating_section(nextProps.data, nextProps.total_count);
  }
  round = (num) => {
    return num.toFixed();
  }
  IsJsonString = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return false;
    }
  }
  display_rating_section = (list, total_count) => {
    let fiveStarRating = 0;
    let fourStarRating = 0;
    let threeStarRating = 0;
    let twoStarRating = 0;
    let oneStarRating = 0;
    var container = '<div class="row">';
    if (list != 'null' && total_count > 0) {
      list = this.IsJsonString('[' + list + ']');
      list.forEach(item => {
        if (item['number'] == 1) {
          oneStarRating = oneStarRating + 1;
        } if (item['number'] == 2) {
          twoStarRating = twoStarRating + 1;
        } if (item['number'] == 3) {
          threeStarRating = threeStarRating + 1;
        } if (item['number'] == 4) {
          fourStarRating = fourStarRating + 1;
        } if (item['number'] == 5) {
          fiveStarRating = fiveStarRating + 1;
        }
      })
    }
    let fiveStarRatingPercent = this.round((fiveStarRating / 5) * 100);
    fiveStarRatingPercent = (fiveStarRatingPercent) ? fiveStarRatingPercent + '%' : '0%';

    let fourStarRatingPercent = this.round((fourStarRating / 5) * 100);
    fourStarRatingPercent = (fourStarRatingPercent) ? fourStarRatingPercent + '%' : '0%';

    let threeStarRatingPercent = this.round((threeStarRating / 5) * 100);
    threeStarRatingPercent = (threeStarRatingPercent) ? threeStarRatingPercent + '%' : '0%';

    let twoStarRatingPercent = this.round((twoStarRating / 5) * 100);
    twoStarRatingPercent = (twoStarRatingPercent) ? twoStarRatingPercent + '%' : '0%';

    let oneStarRatingPercent = this.round((oneStarRating / 5) * 100);
    oneStarRatingPercent = (oneStarRatingPercent) ? oneStarRatingPercent + '%' : '0%';
    var n = 0;
    for (n = 5; n >= 1; n--) {
      if (n == 1) {
        var RatingPercent = oneStarRatingPercent;
        var RatingCount = oneStarRating;
        var progressClass = 'progress-bar-danger';
      } else if (n == 2) {
        var RatingPercent = twoStarRatingPercent;
        var RatingCount = twoStarRating;
        var progressClass = 'progress-bar-warning';
      } else if (n == 3) {
        var RatingPercent = threeStarRatingPercent;
        var RatingCount = threeStarRating;
        var progressClass = 'progress-bar-info';
      } else if (n == 4) {
        var RatingPercent = fourStarRatingPercent;
        var RatingCount = fourStarRating;
        var progressClass = 'progress-bar-primary';
      } else if (n == 5) {
        var RatingPercent = fiveStarRatingPercent;
        var RatingCount = fiveStarRating;
        var progressClass = 'progress-bar-success';
      }
      container = container + '<div class="col-lg-8"><div class="float-right">'
        + '<div class="float-left" style="width:35px; line-height:1;">'
        + '<div style="height:9px; margin:5px 0;">' + n + ' <span class="fa fa-star"></span></div>'
        + '</div>'
        + '<div class="float-left" style="width:140px;">'
        + '<div class="progress" style="height:9px; margin:8px 0;">'
        + '<div class="progress-bar ' + progressClass + '" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5" style="width: ' + RatingPercent + '">'
        + '<span class="sr-only">' + RatingPercent + '</span>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '<div class="float-right" style="margin-left:10px;">' + RatingCount + '</div>'
        + '</div></div>';
    }
    container = container + "</div>";
    this.state = {
      container: container 
    }
  }
  render() {
    return (
      parse(this.state.container||'<p>Loading</p>')
    );
  }
}
export default RatingShow;