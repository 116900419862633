import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { fetch_status_string, fetch_payment_mode_string } from '../../components/General_data.js';
class CouponCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: false,
      applicable_user: 0,
      coupon_type: "",
      applicableId: 0,
      data_status: false,
      modalShow: false,
      uses_list_has: false,
      list_loading_text: "Loading Please Wait",
      order_loading_text: "Loading Please Wait",
      coupon_list: [],
      productlist: [],
      categorylist: [],
      coupon_uses_list: [],
    };
  }
  componentDidMount() {
    this.fetch_coupin_list();
  }
  fetch_coupin_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/coupon-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ coupon_list: resolve.data, data_status: true })
          }
          else {
            this.setState({ list_loading_text: resolve.message })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  update_coupon_code() {
    let coupon_name = this.state.coupon_name || '';
    let coupon_code = this.state.coupon_code || '';
    let coupon_price = this.state.coupon_price || 0;
    let coupon_date_from = this.state.coupon_date_from || '';
    let coupon_type = this.state.coupon_type || '';
    let coupon_date_to = this.state.coupon_date_to || '';
    let applicable_ptaform = this.state.applicable_ptaform || 'ALL';
    let coupon_uses = this.state.coupon_uses || 1;
    let coupon_id = this.state.coupon_id;
    let applicable_user = this.state.applicable_user || 0;
    let min_order_price = this.state.min_order_price || 0;
    let applicableId = this.state.applicableId || 0;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'coupon_name': coupon_name,
      'coupon_code': coupon_code,
      'coupon_price': coupon_price,
      'coupon_date_from': coupon_date_from,
      'coupon_type': coupon_type,
      'coupon_date_to': coupon_date_to,
      'coupon_uses': coupon_uses,
      'applicable_user': applicable_user,
      'applicableId': applicableId,
      'min_order_price': min_order_price,
      'coupon_id': coupon_id,
      'applicable_ptaform': applicable_ptaform,
    }
    if (coupon_name == '') {
      notify.show("Coupon Name Can't be empty", 'warning', 2000);
    }
    else if ((coupon_code == '')) {
      notify.show("Coupon code Can't be empty", 'warning', 2000);
    }
    else if (coupon_price == '') {
      notify.show("Coupon price Can't be empty", 'warning', 2000);
    }
    else if (coupon_date_from == '') {
      notify.show("Coupon date from Can't be empty", 'warning', 2000);
    }
    else if (coupon_date_to == '') {
      notify.show("Coupon date to Can't be empty", 'warning', 2000);
    }
    else if (coupon_type == '') {
      notify.show("Select Coupon Price Type", 'warning', 2000);
    }
    /* else if (applicable_user == '0') {
      notify.show("Applicable User Can't be empty", 'warning', 2000);
    } */
    else if ((applicable_user == '3' || applicable_user == '4') && applicableId == 0) {
      notify.show("Applicable Item Can't be empty", 'warning', 2000);
    }
    else if (min_order_price == '0' || min_order_price == '0') {
      notify.show("Minimum Order Amount Greater Then 0", 'warning', 2000);
    }
    else if (coupon_uses == '') {
      notify.show("Coupon uses Can't be empty", 'warning', 2000);
    }
    else {
      this.setState({ loading: true })
      call_api('a/coupon-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ editModel: false, loading: false })
              this.fetch_coupin_list();
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  save_new_coupon_code() {
    let coupon_name = this.state.coupon_name || '';
    let coupon_code = this.state.coupon_code || '';
    let coupon_type = this.state.coupon_type || '';
    let coupon_price = this.state.coupon_price || 0;
    let coupon_date_from = this.state.coupon_date_from || '';
    let coupon_date_to = this.state.coupon_date_to || '';
    let applicable_ptaform = this.state.applicable_ptaform || 'ALL';
    let coupon_uses = this.state.coupon_uses || 1;
    let applicable_user = this.state.applicable_user || 0;
    let min_order_price = this.state.min_order_price || 0;
    let applicableId = this.state.applicableId || 0;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'coupon_name': coupon_name,
      'coupon_code': coupon_code,
      'coupon_price': coupon_price,
      'coupon_type': coupon_type,
      'coupon_date_from': coupon_date_from,
      'coupon_date_to': coupon_date_to,
      'coupon_uses': coupon_uses,
      'applicable_user': applicable_user,
      'applicableId': applicableId,
      'min_order_price': min_order_price,
      'applicable_ptaform': applicable_ptaform,
    }
    if (coupon_name == '') {
      notify.show("Coupon Name Can't be empty", 'warning', 2000);
    }
    else if ((coupon_code == '')) {
      notify.show("Coupon code Can't be empty", 'warning', 2000);
    }
    else if (coupon_price == '') {
      notify.show("Coupon price Can't be empty", 'warning', 2000);
    }
    else if (coupon_date_from == '') {
      notify.show("Coupon date from Can't be empty", 'warning', 2000);
    }
    else if (coupon_date_to == '') {
      notify.show("Coupon date to Can't be empty", 'warning', 2000);
    }
    else if (coupon_type == '') {
      notify.show("Select Coupon Price Type", 'warning', 2000);
    }
    else if (applicable_user == '0') {
      notify.show("Applicable User Can't be empty", 'warning', 2000);
    }
    else if ((applicable_user == '3' || applicable_user == '4') && applicableId == 0) {
      notify.show("Applicable Item Can't be empty", 'warning', 2000);
    }
    else if (min_order_price == '0' || min_order_price == '0') {
      notify.show("Minimum Order Amount Greater Then 0", 'warning', 2000);
    }
    else if (coupon_uses == '') {
      notify.show("Coupon uses Can't be empty", 'warning', 2000);
    }
    else {
      this.setState({ loading: true })
      call_api('a/add-coupon', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ editModel: false, loading: false })
              notify.show(resolve.message, 'success', 2000);
              this.fetch_coupin_list();
              window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  editData = (list) => {
    this.setState({
      coupon_name: list.coupon_name, editModel: true, min_order_price: list.min_order_amount, coupon_type: list.coupon_type,
      modelHeader: 'Edit Coupon Code', applicable_user: list.applicable_user, applicableId: list.applicableId, coupon_uses: list.coupon_max_usage, coupon_id: list.coupon_id,
      coupon_code: list.coupon_code, applicable_ptaform: list.applicable_ptaform, coupon_price: list.coupon_price, coupon_date_from: list.coupon_date_to, coupon_date_to: list.coupon_exp_date
    })
    if (list.applicable_user == 3) {
      this.fetch_category();
    }
    else if (list.applicable_user == 4) {
      this.fetch_product_list();
    }
  }
  add_new_coupon_code() {
    this.setState({
      coupon_name: '', min_order_price: '', applicable_ptaform: 'ALL', applicable_user: 0, applicableId: 0, coupon_code: '', coupon_price: '',
      coupon_id: 0, editModel: true, modelHeader: 'New Coupon Code', coupon_date_from: '', coupon_date_to: '', coupon_uses: '0', coupon_type: ''
    })
  }


  changeStatus(coupon_id, coupon_status) {
    let optopns = { 'coupon_status': coupon_status, 'coupon_id': coupon_id, 'LoginToken': localStorage.getItem('LoginToken'), };
    call_api('a/coupon-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ modalShow: false })
            this.fetch_coupin_list();
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  send_notification(coupon_id,) {
    let optopns = { 'coupon_id': coupon_id, 'LoginToken': localStorage.getItem('LoginToken'), };
    this.setState({ loading: true })
    call_api('a/send-coupon-notification', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
          this.setState({ loading: false })
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_category() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  delete_coupon(couponId) {
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        confirmBtnBsStyle="danger"
        onCancel={() => { this.setState({ alert: null }); }}
        onConfirm={() => { this.delete_coupon_confirm(couponId) }} >
        You will not be able to recover this imaginary file!
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });
  }
  delete_coupon_confirm(couponId) {
    this.setState({ alert: null })
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'couponId': couponId
    }
    call_api('a/coupon-code-delete', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            const items = this.state.coupon_list.filter(item => item.coupon_id !== couponId);
            this.setState({ coupon_list: items })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  uses_list(couponId) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'couponId': couponId
    }
    call_api('a/coupon-code-uses', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ coupon_uses_list: resolve.data, coupon_uses_mdl: true, uses_list_has: true })
            //notify.show(resolve.message, 'success', 2000);
          }
          else {
            this.setState({ coupon_uses_list: [], coupon_uses_mdl: true, order_loading_text: resolve.message, uses_list_has: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_product_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'search': '',
      'limit': 100,
      'start': 0,
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ productlist: resolve.data || [] })
          }
          else {
            //notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  applicable_change = (name, value) => {
    this.setState({ [name]: value })
    if (value == 4) {
      this.fetch_product_list();
      this.setState({ applicable_text: 'Select Product' })
    }
    else if (value == 3) {
      this.fetch_category();
      this.setState({ applicable_text: 'Select Category' })
    }
  }
  render() {
    const { data_status, coupon_list, coupon_uses_list, uses_list_has } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: 99999 }} />
          {this.state.alert}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Coupon Code</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <Button type="button" onClick={() => this.add_new_coupon_code()} className="btn btn-sm btn-info">New Coupon Code</Button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Created</th>
                              <th>Name</th>
                              <th>Coupon</th>
                              <th>Amount/Percent</th>
                              <th>Platform</th>
                              <th>Applicable From</th>
                              <th>Expired</th>
                              <th>Applicable On</th>
                              <th>Min Order</th>
                              <th>Max Uses</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                coupon_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td><Moment format="YYYY/MM/DD">{list.created_at}</Moment></td>
                                      <td>{list.coupon_name}</td>
                                      <td>
                                        {list.coupon_code}
                                        {(list.user_id && list.muser_mobno) ?
                                          <>
                                            <br />
                                            <p>{list.muser_name}-{list.muser_mobno}</p>
                                          </>
                                          :
                                          <></>
                                        }
                                      </td>
                                      <td>{list.coupon_price}{(list.coupon_type == 'Price' ? '₹' : '%')}</td>
                                      <td>{list.applicable_ptaform || '---'}</td>
                                      <td>{list.coupon_date_to}</td>
                                      <td>{list.coupon_exp_date}</td>
                                      <td>
                                        {list.applicable_user == 1 ?
                                          'New User' : list.applicable_user == 2 ?
                                            'Order Amount' : list.applicable_user == 3 ?
                                              'Category' : list.applicable_user == 4 ?
                                                'Product' :
                                                'All User'
                                        }
                                      </td>
                                      <td>{list.min_order_amount}</td>
                                      <td>{list.coupon_max_usage}</td>
                                      <td>
                                        {list.coupon_status == 1 ?
                                          <button className="btn btn-success btn-sm" onClick={(e) => this.changeStatus(list.coupon_id, 0)}>Active</button>
                                          : list.coupon_status == 2 ?
                                            <button className="btn btn-info btn-sm">Used By User</button>
                                            : list.coupon_status == 3 ?
                                              <button className="btn btn-warning btn-sm">Expired</button>
                                              :
                                              <button className="btn btn-danger btn-sm" onClick={(e) => this.changeStatus(list.coupon_id, 1)}>Inactive</button>
                                        }
                                      </td>
                                      <td>
                                        {(list.coupon_status == 1) ?
                                          <>
                                            <button className="btn btn-sm btn-success mr-2" onClick={() => this.editData(list, 'edit')}><i className="fa fa-pencil"></i></button>
                                          </>
                                          :
                                          <></>}
                                        {(list.total_uses == 0) ?
                                          <>
                                            <button className="btn btn-sm mr-2 btn-danger" onClick={() => this.delete_coupon(list.coupon_id)}><i className="fa fa-trash-o"></i></button>
                                          </>
                                          :
                                          <></>}
                                        <button className="btn btn-sm mr-2 btn-info" onClick={() => this.uses_list(list.coupon_id)}><i className="fa fa-eye"></i></button>
                                        {/*  <button className="btn btn-sm btn-warning" disabled={this.state.loading} onClick={() => this.send_notification(list.coupon_id)}><i className="fa fa-bell"></i></button> */}
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="11" align="center">{this.state.list_loading_text}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_name" className="control-label">Coupon Name</label>
                  <input type="text" ref="coupon_name" name="coupon_name" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.coupon_name || ''} className="form-control" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_code" className="control-label">Coupon Code</label>
                  <input type="text" ref="coupon_code" name="coupon_code" value={this.state.coupon_code || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="coupon_code" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_price" className="control-label">Coupon Price/Percent</label>
                  <input type="text" ref="coupon_price" name="coupon_price" value={this.state.coupon_price || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value.replace(/\D/, '') })} className="form-control" id="coupon_price" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_date_from" className="control-label">Date From</label>
                  <input type="date" ref="coupon_date_from" name="coupon_date_from" value={this.state.coupon_date_from || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="coupon_date_from" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_date_to" className="control-label">Date To</label>
                  <input type="date" ref="coupon_date_to" name="coupon_date_to" value={this.state.coupon_date_to || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="coupon_date_to" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="applicable_ptaform" className=" control-label">Applicable Platform</label>
                  <select name="applicable_ptaform" ref="applicable_ptaform" value={this.state.applicable_ptaform || 'ALL'} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="applicable_ptaform">
                    <option value="ALL">All</option>
                    <option value="WEB">WEB</option>
                    <option value="APP">APP</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_type" className=" control-label">Percent/Price</label>
                  <select name="coupon_type" value={this.state.coupon_type || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="coupon_type">
                    <option value="">Select Type</option>
                    <option value="Price">Price</option>
                    <option value="Percent">Percent</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="applicable_user" className="control-label">Applicable User</label>
                  <select name="applicable_user" ref="applicable_user" value={this.state.applicable_user || '0'} onChange={(e) => this.applicable_change(e.target.name, e.target.value)} className="form-control" id="applicable_user">
                    <option value="0">All User</option>
                    <option value="1">New User</option>
                    <option value="2">Order Amount</option>
                    <option value="3">Category</option>
                    <option value="4">Product</option>
                  </select>
                </div>
              </div>
              {this.state.applicable_user == '3' || this.state.applicable_user == '4' ?
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="applicableId" className="control-label">{this.state.applicable_text}</label>
                    <select type="text" ref="applicableId" name="applicableId" id="applicableId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.applicableId} className="form-control" >
                      {this.state.applicable_user == '3' ?
                        <>
                          <option value="0">Select Category</option>
                          {
                            this.state.categorylist.map((list, index) => {
                              return (
                                (list.main_group_flag == 1) ?
                                  <option key={"catList_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                                  : ''
                              )
                            })
                          }
                        </>
                        : this.state.applicable_user == '4' ?
                          <>
                            <option value="0">Select Product</option>
                            {
                              this.state.productlist.map((list, index) => {
                                return (
                                  <option key={"schp" + index} value={list.product_id}>{list.prod_name}</option>
                                )
                              })
                            }
                          </>
                          :
                          ''
                      }
                    </select>
                  </div>
                </div>
                : ''}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="min_order_price" className="control-label">Min Order Price</label>
                  <input type="text" ref="min_order_price" name="min_order_price" value={this.state.min_order_price || '0'} onChange={(e) => this.setState({ [e.target.name]: e.target.value.replace(/\D/, '') })} className="form-control" id="min_order_price" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="coupon_uses" className="control-label">Max Uses (Per users)</label>
                  <input type="number" ref="coupon_uses" min={1} name="coupon_uses" value={this.state.coupon_uses || '1'} onChange={(e) => this.setState({ [e.target.name]: e.target.value.replace(/\D/, '') })} className="form-control" id="coupon_uses" />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.coupon_id != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.update_coupon_code()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_new_coupon_code()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
        <Modal size="lg" fade={false} isOpen={this.state.coupon_uses_mdl} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ coupon_uses_mdl: false })}>X</button>}>
            <CardText className="modal-title">
              Coupon Uses Report
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive">
                  <table className="table mb-0 text-center">
                    <thead>
                      <tr>
                        <th>SrNo</th>
                        <th>Order No.</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Total</th>
                        <th>Coupon Amount</th>
                        <th><span title="Payment Mode">PM</span></th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        uses_list_has == true ?
                          coupon_uses_list.map((list, index) => {
                            return (
                              <tr key={"row_order" + index}>
                                <td>{index + 1}</td>
                                <td>{list.invoiceNumber}</td>
                                <td>{list.muser_name}</td>
                                <td>{list.mobilenumber}</td>
                                <td>{list.final_amount}</td>
                                <td>{list.couponAmount}</td>
                                <td>{fetch_payment_mode_string(list.p_mode)}</td>
                                <td>{list.order_date}</td>
                                <td>
                                  <button className="btn btn-sm btn-success">
                                    {fetch_status_string(list.order_status)}
                                  </button>
                                </td>
                                <td>
                                  <Link target="_blank" rel="noopener noreferrer" to={"/order-details/" + list.order_id} className="btn btn-sm btn-info mr-5">View</Link>
                                </td>
                              </tr>
                            )
                          })
                          : <tr><td colSpan="10" align="center">{this.state.order_loading_text}</td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <>
            </>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default withRouter(CouponCode);
