import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import notfoundImg from "../../assets/img/no_image.jpg";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import { fetch_status_string, fetch_status_color, fetch_payment_mode_string } from '../../components/General_data.js';
import LazyLoad from "react-lazyload";
class OrderDetailsMdl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      status_model_status: false,
      model_status: false,
      displayLogistics: true,
      loading: false,
      logistics_id: 0,
      new_order_status: 0,
      item_picked_barcode: "",
      order_update_header_text: "",
      remarks: "",
      order_details: [],
      cart_details: [],
      vender_list: [],
      shipment: [],
    };
  }

  asign_logistics = (orderId) => {
    let logistics_id = this.state.logistics_id;
    if (logistics_id == 0) {
      notify.show('Please select logisticd', 'error', 2000);
    }
    else {
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'logistics_id': logistics_id,
        'orderId': orderId
      }
      this.setState({ loading: true })
      call_api('a/order-logistics-asign', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.log_order_details(orderId);
              this.setState({ loading: false, displayLogistics: true })
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  fetch_logistics_list = (cityId) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'cityId': cityId
    }
    call_api('a/order-logistics-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ vender_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  update_pickup_scheduled_date = () => {
    const order_Detail = this.state.shipment;
    if (order_Detail.new_pickup_scheduled == '') {
      notify.show('Select New Pickup Scheduled  Date', 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      let options = {
        new_pickup_scheduled: order_Detail.new_pickup_scheduled || order_Detail.pickup_scheduled_date,
        order_id: order_Detail.order_id,
        'LoginToken': localStorage.getItem('LoginToken'),
      }
      call_api('a/update-shipment-pickup-date', options)
        .then(
          (resolve) => {
            this.setState({ loading: false });
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  update_order_length_weight = () => {
    const order_Detail = this.state.order_details;
    if (order_Detail.weight == '' || order_Detail.weight == '0') {
      notify.show('Enter item weight', 'error', 2000);
    }
    else if (order_Detail.item_length == '' || order_Detail.item_length == '0') {
      notify.show('Enter item length', 'error', 2000);
    }
    else if (order_Detail.height == '' || order_Detail.height == '0') {
      notify.show('Enter item height', 'error', 2000);
    }
    else if (order_Detail.breadth == '' || order_Detail.breadth == '0') {
      notify.show('Enter item breadth', 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      let options = {
        item_length: order_Detail.item_length,
        weight: order_Detail.weight,
        height: order_Detail.height,
        breadth: order_Detail.breadth,
        order_id: order_Detail.order_id,
        'LoginToken': localStorage.getItem('LoginToken'),
      }
      call_api('a/update-orders-detail', options)
        .then(
          (resolve) => {
            this.setState({ loading: false });
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  log_order_details(order_ids) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'order_id': order_ids,
    }
    call_api('a/order-details', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({
              order_details: resolve.data, cart_details: resolve.cart, isLoading: false,
              shipment: resolve.shipment || [],
              data_status: true, model_status: true, item_picked_barcode: ''
            })
            if (resolve.data.logistics_id == 0) {
              this.setState({ displayLogistics: false })
            }
            //this.fetch_logistics_list(resolve.data.city_id);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  download_invoice_manifests(download_type) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'order_id': this.state.order_details.order_id,
      'download_type': download_type,
    }
    this.setState({ loading: true })
    call_api('a/download-order-invoice-manifests', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            if (download_type == 'label')
              window.open(resolve.label_url, '_blank');
            else if (download_type == 'invoice')
              window.open(resolve.invoice_url, '_blank');
            else if (download_type == 'manifests')
              window.open(resolve.manifest_url, '_blank');
            else if (download_type == 'invoice_label')
              window.open(resolve.invoice_label_url, '_blank');
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
          this.setState({ loading: false })
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  componentWillReceiveProps(nextProps) {
    let order_ids = nextProps.order_id;
    let model_status = nextProps.model_status;
    if (order_ids && model_status == true) {
      this.setState({ status_model_status: false });
      this.log_order_details(order_ids);
    }
    else {
      this.setState({ model_status: false, data_status: false, status_model_status: false })
    }
  }
  componentDidMount() {
  }
  check_picked_item_barcode(e) {
    let item_picked_barcode = this.state.item_picked_barcode;
    let order_id = this.state.order_details['order_id'];
    if (item_picked_barcode) {
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'order_id': order_id,
        'item_barcode': item_picked_barcode,
      }
      call_api('a/check-picked-item-barcode', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ cart_details: resolve.cart_data });
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  update_order_status() {
    let new_order_status = this.state.new_order_status || "";
    var newList = [];
    if (this.state.order_details.order_id)
      newList.push(this.state.order_details.order_id);

    let options = {
      'LoginToken': localStorage.getItem('LoginToken'),
      new_order_status: new_order_status,
      remarks: this.state.remarks,
      order_ids: newList
    };
    if (new_order_status == "") {
      notify.show('select order status', 'error', 2000);
    }
    else if (newList.length === 0) {
      notify.show('select order', 'error', 2000);
    }
    else if (new_order_status === 'cancelled_order' && this.state.remarks == '') {
      notify.show('select order', 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      call_api('a/update-orders-status', options)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ loading: false, status_model_status: false });
              this.log_order_details(this.state.order_details.order_id);
              notify.show(resolve.message, 'success', 2000);
              if (new_order_status == 'rtd_order' && resolve['label_url']) {
                window.open(resolve.label_url, '_blank');
              }
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
            notify.show("Server not responding", 'error', 2000);
          }
        )
    }
  }
  update_input_value = (key, value) => {
    const detail = { ...this.state.order_details, [key]: value };
    this.setState({ order_details: detail })
  }
  update_courier_value = (key, value) => {
    const detail = { ...this.state.shipment, [key]: value };
    this.setState({ shipment: detail })
  }
  confirm_order_update = (order_status) => {
    if (order_status == 'cancelled_order') {
      var order_update_header_text = "Confirm to cancel new order";
    }
    else if (order_status == 'confirm_order') {
      var order_update_header_text = "Confirm to confirmation of new order";
    }
    else if (order_status == 'rtd_order') {
      var order_update_header_text = "Generate Labels";
    }
    else if (order_status == 'pending_dispatch_Order') {
      var order_update_header_text = "Ready For Dispatch";
    }
    this.setState({
      new_order_status: order_status, status_model_status: true, order_update_header_text: order_update_header_text
    })
  }
  render() {
    const { isLoading, data_status, order_details, cart_details } = this.state;
    return (
      <>
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
        <Modal size="lg" fade={false} isOpen={this.state.model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.props.parentCallback()}>X</button>}>
            <CardText className="modal-title">
              Order Detail
            </CardText>
          </ModalHeader>
          <ModalBody className="px-2 py-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    {isLoading == true ?
                      'Please Wait'
                      :
                      <>
                        <div className="accordion accordion-bordered" id="accordion-2" role="tablist">
                          <div className="row">
                            <div className="col-lg-4 col-xs-12 col-sm-6 col-md-4">
                              <div className="card">
                                <div className="card-header" role="tab" id="heading-1">
                                  <h6>
                                    <a data-bs-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                      Order Details :-
                                    </a>
                                  </h6>
                                </div>
                                <div id="collapse-1" className="collapse" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion-2">
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className="col-lg-6 col-xs-12 col-sm-6 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Order No :</label>
                                          <span>{order_details.invoiceNumber}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-6 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Order Date :</label>
                                          <span>{order_details.order_date}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Order Status :</label>
                                          <span className={"label " + fetch_status_color(order_details.order_status)}>{fetch_status_string(order_details.order_status)}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Payment Mode :</label>
                                          <span>{fetch_payment_mode_string(order_details.p_mode)}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Order Amount :</label>
                                          <span>Rs.{order_details.order_amount}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Shipping Amount :</label>
                                          <span>Rs.{order_details.shipping_amount}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Loyalty Point Use :</label>
                                          <span>Rs.{order_details.loyalty_discount}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Coupon Applied Amount :</label>
                                          <span>Rs.{order_details.couponAmount}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Delivery Date :</label>
                                          <span>{order_details.delivery_date || '--'}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Total Amount :</label>
                                          <span>Rs.{order_details.final_amount}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-xs-12 col-sm-6 col-md-4">
                              <div className="card">
                                <div className="card-header" role="tab" id="heading-2">
                                  <h6>
                                    <a data-bs-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                      User Details :-
                                    </a>
                                  </h6>
                                </div>
                                <div id="collapse-2" className="collapse" role="tabpanel" aria-labelledby="heading-2" data-bs-parent="#accordion-2">
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Name :</label>
                                          <span>{order_details.muser_name}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Contact :</label>
                                          <span>{order_details.mobilenumber}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                        <div className="form-group">
                                          <label className="control-label">Address :</label>
                                          <span>{order_details.muser_address}</span>
                                          <span>{order_details.area_name}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                        <div className="form-group">
                                          <label className="control-label">City & State :</label>
                                          <span>{order_details.city_name}-{order_details.state_name}</span>
                                          <span>{order_details.o_pincode_id}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Address Type :</label>
                                          <span>{((order_details.addr_type == 1) ? 'Home' : (order_details.addr_type == 2) ? 'Office' : 'Other')}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-xs-12 col-sm-6 col-md-4">
                              <div className="card">
                                <div className="card-header" role="tab" id="heading-3">
                                  <h6>
                                    <a data-bs-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                      Courier Details :-
                                    </a>
                                  </h6>
                                </div>
                                <div id="collapse-3" className="collapse" role="tabpanel" aria-labelledby="heading-3" data-bs-parent="#accordion-2">
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Courier Id :</label>
                                          <span>{this.state.shipment.courier_company_id || '---'}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Courier Name :</label>
                                          <span>{this.state.shipment.courier_name || '---'}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">AWB Code :</label>
                                          <span>{this.state.shipment.awb_code || '---'}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Pickup Schedule :</label>
                                          <span>{this.state.shipment.pickup_scheduled_date || '---'}</span>
                                        </div>
                                      </div>
                                      {this.state.shipment.new_pickup_scheduled ?
                                        <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                          <div className="form-group">
                                            <label className="control-label">New Pickup Schedule :</label>
                                            <span>{this.state.shipment.new_pickup_scheduled || '---'}</span>
                                          </div>
                                        </div> : <></>}
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Shipment Id :</label>
                                          <span>{this.state.shipment.shipment_id || '---'}</span>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">Shipment Order Id :</label>
                                          <span>{this.state.shipment.sub_order_id || '---'}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6 mt-20">
                            <h6>Delivery Details :-</h6>
                            <hr></hr>
                            {this.state.displayLogistics == false ?
                              <>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label htmlFor="logistics_id" className="control-label">Logistic Not Asign</label>
                                          <select type="text" name="logistics_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.logistics_id} className="form-control" >
                                            <option value="0">Select Logistics</option>
                                            {
                                              this.state.vender_list.map((list, index) => {
                                                return (
                                                  <option key={"l_" + index} value={list.id}>{list.full_name}({list.contact_details})</option>
                                                )
                                              })
                                            }
                                          </select>
                                          {this.state.logistics_id != 0 ?
                                            <>
                                              <br />
                                              <button className="btn btn-sm btn-danger" disabled={this.state.loading} onClick={() => this.asign_logistics(order_details.order_id)}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Asign Logistics</button>
                                            </>
                                            : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">Logistic Name :</label>
                                      <span>{order_details.full_name}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">Logistic Contact :</label>
                                      <span>{order_details.contact_details}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 col-sm-12 col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">Delivery Date :</label>
                                      <span>{order_details.delivery_date}</span>
                                  </div>
                                </div>
                                {order_details.order_status == 1 ?
                                  <p className="ml-30 txt-info cursor" onClick={() => this.setState({ displayLogistics: false })}>Change Logistics</p>
                                  : ''}
                              </>
                            }
                          </div> */}
                        <div className="row">
                          <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                            <hr />
                            <div className='row'>
                              <div className='margin-auto col-sm-2'>
                                <div className="form-group">
                                  <label htmlFor="item_picked_barcode" className="control-label-sm"><span className="text-danger">*</span>Enter Or Scan Barcode</label>
                                  <input type="text" value={this.state.item_picked_barcode || ''} onChange={(e) => this.setState({ item_picked_barcode: e.target.value })} onKeyUp={(e) => this.check_picked_item_barcode(e)} className="form-control" id="item_picked_barcode" />
                                </div>
                              </div>
                              <div className='text-center m-auto col-sm-5'>
                                {
                                  (order_details.order_status == 1) ?
                                    <>
                                      <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.loading}>Cancel New Order</button>
                                      <button className="btn btn-success btn-sm" onClick={() => this.confirm_order_update("confirm_order")} disabled={this.state.loading}>Confirm New Order</button>
                                    </>
                                    : (order_details.order_status == 2) ?
                                      <>
                                        <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.loading}>Cancel Confirmed Order</button>
                                        <button className="btn btn-success btn-sm" onClick={() => this.confirm_order_update("rtd_order")} disabled={this.state.loading}>Generate Labels</button>
                                      </>
                                      :
                                      (order_details.order_status == 3) ?
                                        <>
                                          <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.loading}>Cancel Order</button>
                                          <button className="btn btn-success btn-sm mr-3" onClick={() => this.confirm_order_update("rtd_order")} disabled={this.state.loading}>Regenerate Labels</button>
                                          <button className="btn btn-info btn-sm" onClick={() => this.confirm_order_update("pending_dispatch_Order")} disabled={this.state.loading}>Ready For Dispatch</button>
                                        </>
                                        :
                                        (order_details.order_status == 4) ?
                                          <>
                                            <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.loading}>Cancel Order</button>
                                            <button className="btn btn-info btn-sm" onClick={() => this.confirm_order_update("pending_dispatch_Order")} disabled={this.state.loading}>Again Request Pickup</button>
                                          </>
                                          :
                                          (order_details.order_status == 5) ?
                                            <>
                                              <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.loading}>Cancel Order</button>
                                            </>
                                            :
                                            <>
                                            </>
                                }
                              </div>
                              <div className='text-center m-auto col-sm-5'>
                                {(this.state.shipment != null && this.state.shipment && this.state.shipment.awb_code) ?
                                  <>
                                    {(order_details.order_status == 3 || order_details.order_status == 4 || order_details.order_status == 5) ?
                                      <>
                                        <button className="btn btn-dark btn-sm mr-3 mt-2" onClick={() => this.download_invoice_manifests("label")} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Download Label</button>
                                        <button className="btn btn-success btn-sm mr-3 mt-2" onClick={() => this.download_invoice_manifests("manifests")} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Download Manifests</button>
                                      </>
                                      :
                                      <></>
                                    }
                                  </>
                                  :
                                  <> </>
                                }
                                {(this.state.shipment != null && this.state.shipment) ?
                                  <>
                                    <button className="btn btn-info btn-sm mt-2 mr-2" onClick={() => this.download_invoice_manifests("invoice")} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Download Invoice</button>
                                    <button className="btn btn-primary btn-sm mt-2" onClick={() => this.download_invoice_manifests("invoice_label")} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Download Invoice+Label</button>
                                  </>
                                  :
                                  <></>
                                }
                              </div>
                            </div>
                            <hr />
                            <div className=''><h6>Item Details :-</h6></div>
                            <div className="table-responsive">
                              <table className="table mb-0 text-center">
                                <thead>
                                  <tr>
                                    <th>Sr No</th>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    {/* <th>Unit</th> */}
                                    <th>MRP</th>
                                    <th>Sale Price</th>
                                    <th>Discount</th>
                                    <th>Total Amount</th>
                                    <th>Item Picked</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    data_status == true ?
                                      cart_details.map((list, index) => {
                                        return (
                                          <tr key={"row" + index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              <div className="text-left" style={{ display: "flex" }}>
                                                <LazyLoad height={50} once>
                                                  <img alt={list.product_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={apiUrl + list.p_img} />
                                                </LazyLoad>
                                                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                                  <p className="text-info">{list.product_name.slice(0, 50)}</p>
                                                  <p>SKU Id : {list.sku || '---'}</p>
                                                  <p>HSN Code : {list.hsn || '---'}</p>
                                                  <p>BarCode : {list.p_barcode || '---'}</p>
                                                  <p>Size : {list.product_size || '---'}</p>
                                                </div>
                                              </div>
                                            </td>
                                            <td>{list.p_qty}</td>
                                            {/* <td>{list.p_unit_qty} {list.p_unit}</td> */}
                                            <td>{list.actual_price}</td>
                                            <td>{list.p_price}</td>
                                            <td>{list.p_discount}</td>
                                            <td>{list.total_price}</td>
                                            <td>{list.item_picked}</td>
                                          </tr>
                                        )
                                      })
                                      : <tr>
                                        <td colSpan={8}></td>
                                      </tr>}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.state.status_model_status == true ?
          <Modal size={this.state.new_order_status == 'rtd_order' ? "lg" : "md"} fade={false} isOpen={this.state.status_model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
            <ModalHeader close={<button className="close" onClick={() => this.setState({ status_model_status: false })}>X</button>}>
              <CardText className="modal-title">
                {this.state.order_update_header_text}
              </CardText>
            </ModalHeader>
            <ModalBody className="px-2 py-2">
              {(this.state.new_order_status == 'cancelled_order') ?
                <div className="row">
                  <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="control-label">Remark</label>
                      <textarea name="remarks" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" value={this.state.remarks}> </textarea>
                    </div>
                  </div>
                </div>
                : (this.state.new_order_status == 'rtd_order' || this.state.new_order_status == 'pending_dispatch_Order') ?
                  <>
                    <div className="card chat-app-wrapper">
                      <div className="row mx-0">
                        <div className="col-sm-12 my-2">
                          <div className='pd-2 row'>
                            {this.state.new_order_status == 'rtd_order' ?
                              <>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_weight" className="control-label-sm"><span className="text-danger">*</span>Item Weight(In KG)</label>
                                    <input type="text" value={order_details.weight || 0} onChange={(e) => this.update_input_value('weight', e.target.value)} className="form-control" id="item_weight" />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_length" className="control-label-sm"><span className="text-danger">*</span>Item Length(In CMS)</label>
                                    <input type="text" value={order_details.item_length || 0} onChange={(e) => this.update_input_value('item_length', e.target.value)} className="form-control" id="item_length" />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_breadth" className="control-label-sm"><span className="text-danger">*</span>Item Breadth(In CMS)</label>
                                    <input type="text" value={order_details.breadth || 0} onChange={(e) => this.update_input_value('breadth', e.target.value)} className="form-control" id="item_breadth" />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_height" className="control-label-sm"><span className="text-danger">*</span>Item Height(In CMS)</label>
                                    <input type="text" value={order_details.height || 0} onChange={(e) => this.update_input_value('height', e.target.value)} className="form-control" id="item_height" />
                                  </div>
                                </div>
                                <div className="col-sm-2 mt-auto">
                                  <div className="form-group">
                                    <button className="btn btn-sm btn-info" disabled={this.state.loading} onClick={() => this.update_order_length_weight()} >{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</button>
                                  </div>
                                </div>
                              </>
                              : (this.state.new_order_status == 'pending_dispatch_Order') ?
                                <>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="new_pickup_scheduled" className="control-label-sm"><span className="text-danger">*</span>Scheduled Pickup Date </label>
                                      <input type="datetime-local" min={this.state.shipment.pickup_scheduled_date} value={this.state.shipment.new_pickup_scheduled || this.state.shipment.pickup_scheduled_date || ''} onChange={(e) => this.update_courier_value('new_pickup_scheduled', e.target.value)} className="form-control" id="new_pickup_scheduled" />
                                    </div>
                                  </div>
                                  <div className="col-sm-2 mt-auto">
                                    <div className="form-group">
                                      <button className="btn btn-sm btn-info" disabled={this.state.loading} onClick={() => this.update_pickup_scheduled_date()} >{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</button>
                                    </div>
                                  </div>
                                </>
                                : <></>}
                          </div>
                          <label className="control-label">Item In This Order</label>
                          <div className="table-responsive">
                            <table className="table mb-0 table-bordered text-center table-striped">
                              <thead>
                                <tr>
                                  <th width="50%">Item Info</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  cart_details.length > 0 ?
                                    cart_details.map((list, index) => {
                                      return (
                                        <tr key={"row1" + index}>
                                          <td>
                                            <div className="text-left" style={{ display: "flex" }}>
                                              <LazyLoad height={50} once>
                                                <img alt={list.product_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={apiUrl + list.p_img} />
                                              </LazyLoad>
                                              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                                <p className="text-info">{list.product_name.slice(0, 50)}</p>
                                                <p>SKU Id : {list.sku || '---'}</p>
                                                <p>HSN Code : {list.hsn || '---'}</p>
                                                <p>BarCode : {list.p_barcode || '---'}</p>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    : <tr><td colSpan="1" align="center">Item not found</td></tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : <></>}
            </ModalBody>
            <ModalFooter>
              <Button type="button" disabled={this.state.loading} onClick={() => this.update_order_status()} className="btn btn-danger btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</Button>
            </ModalFooter>
          </Modal> : ''}
      </>
    );
  }
}
export default OrderDetailsMdl;
