import React from 'react';
import {Redirect} from 'react-router-dom';
function Login_check(props)
{
    const Cmp = props.cmp;
    var LoginToken  =  localStorage.getItem('LoginToken');
    var reg_Id =  localStorage.getItem('reg_Id');
    var owner_flag =  localStorage.getItem('owner_flag');
    return <>{reg_Id&&LoginToken&&(owner_flag==1) ? <Cmp /> : <Redirect to="service-login"></Redirect>}</>
}
export default Login_check;