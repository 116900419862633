import { apiUrl } from './Base_page.js';
export { call_api, apiUrl }
function call_api(url, options) {
    return new Promise((resolve, reject) => {
        fetch(apiUrl + url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2' },
            body: JSON.stringify(options)
        })
            .then(result => {
                result.json().then((res) => {
                    resolve(res);
                })
                    .catch((error) => {
                        reject(error);
                    })
            });
    });
}