import $ from 'jquery';
export function c_s_c_status_jquery(call_api) {
    $(document).ready(function () {
        $(document).on('click', '.c_s_c_locationStatus', function () {
            var this_element = $(this);
            var location_id = this_element.attr('data-id');
            var status = this_element.attr('data-status');
            let optopns = {
                'LoginToken': localStorage.getItem('LoginToken'),
                'location_id': location_id,
                'status': status
            }
            call_api('a/c-s-c-location-status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            if (status == 1) {
                                $(this_element).attr('data-status', 2);
                                $(this_element).html('Active');
                                $(this_element).removeClass("label-danger");
                                $(this_element).addClass("label-success");
                            }
                            else {
                                $(this_element).attr('data-status', 1);
                                $(this_element).html('Inactive');
                                $(this_element).removeClass("label-success");
                                $(this_element).addClass("label-danger");
                            }
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        });
    });
}