import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import FadeIn from "react-lazyload-fadein";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import notfoundImg from "../../assets/img/no_image.jpg";
import Notifications, { notify } from 'react-notify-toast';
class ProductCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      modalShow: false,
      categorylist: [],
    };
  }
  componentDidMount() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  save_category_data() {
    let category_type = this.state.category_type;
    let mainCatId = this.state.mainCatId || 0;
    let catrgory_Name = this.state.catrgory_Name;
    let subCatId = this.state.subCatId || 0;
    let pg_id1 = this.state.pg_id1;
    let dispalyOrder = this.state.dispalyOrder;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'category_type': category_type,
      'mainCatId': mainCatId,
      'catrgory_Name': catrgory_Name,
      'subCatId': subCatId,
      'dispalyOrder': dispalyOrder,
      'pg_id1': pg_id1,
    }
    if (catrgory_Name == '') {
      notify.show("Enter Category Name", 'warning', 2000);
    }
    else if ((category_type == '2' || category_type == '3') && (mainCatId == 0 || mainCatId == '')) {
      notify.show("Select Main Category", 'warning', 2000);
    }
    /* else if (category_type == '3' && (subCatId == 0 || subCatId == '')) {
      alert("Select Sub Category");
    } */
    else {
      this.setState({ loading: true })
      call_api('a/product-category-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ categorylist: resolve.data, editModel: false, loading: false, data_status: true })
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  save_new_category_data() {
    let category_type = this.state.category_type;
    let mainCatId = this.state.mainCatId || 0;
    let catrgory_Name = this.state.catrgory_Name;
    let subCatId = this.state.subCatId || 0;
    let pg_id1 = this.state.pg_id1;
    let dispalyOrder = this.state.dispalyOrder;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'category_type': category_type,
      'mainCatId': mainCatId,
      'catrgory_Name': catrgory_Name,
      'subCatId': subCatId,
      'dispalyOrder': dispalyOrder,
      'pg_id1': pg_id1,
    }
    if (catrgory_Name == '') {
      notify.show("Enter Category Name", 'warning', 2000);
    }
    else if ((category_type == '2' || category_type == '3') && (mainCatId == 0 || mainCatId == '')) {
      notify.show("Select Main Category", 'warning', 2000);
    }
    /*else if (category_type == '3' && (subCatId == 0 || subCatId == '')) {
      alert("Select Sub Category");
    } */
    else {
      this.setState({ loading: true })
      call_api('a/product-category-save', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ categorylist: resolve.data, editModel: false, loading: false, data_status: true })
              notify.show(resolve.message, 'success', 2000);
              window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  editData = (list) => {
    this.setState({
      category_type: list.main_group_flag, mainCatId: list.pg_parent_id, catrgory_Name: list.pg_name1, dispalyOrder: list.dispalyOrder,
      subCatId: list.pg_parent_id2, pg_id1: list.pg_id1, editModel: true, modelHeader: 'Edit Category'
    })

  }
  add_category_mdl_display() {
    this.setState({
      category_type: 1, mainCatId: 0, catrgory_Name: '', dispalyOrder: 0,
      subCatId: 0, pg_id1: 0, editModel: true, modelHeader: 'Add New Category'
    })
  }
  viewImage = (imageLink, categoryId) => {
    this.setState({ imageLink: imageLink, modalShow: true, displayUbtn: false, categoryId: categoryId })
  }
  changePriveImage(event) {
    let image = event.target.files[0];
    if (image != null) {
      if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg" || image.type == "image/webp") {
        this.setState({
          imageLink: URL.createObjectURL(image), displayUbtn: true
        })
      }
      else {
        this.setState({
          imageLink: '', displayUbtn: true
        })
      }
    }
    else {
      this.setState({
        imageLink: '', displayUbtn: true
      })
    }
  }
  save_upload_image() {
    this.setState({ loading: true })
    var file = this.refs.file.files[0];
    var reader = new FileReader();
    if (file != null) {
      if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          let optopns = { 'image': reader.result, 'categoryId': this.state.categoryId };
          call_api('a/product-category-image-upload', optopns)
            .then(
              (resolve) => {
                if (resolve.status == true) {
                  this.setState({ categorylist: resolve.data, loading: false, modalShow: false, data_status: true })
                }
                else {
                  notify.show(resolve.message, 'error', 2000);
                  this.setState({ loading: false })
                }
              },
              (reject) => {
                console.log(reject);
              }
            )
        }.bind(this);
      }
      else {
        this.setState({ loading: false })
      }
    }
    else {
      this.setState({ loading: false })
    }
  }
  changeStatus(categoryId, status) {
    let optopns = { 'status': status, 'categoryId': categoryId };
    call_api('a/product-category-change-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data, modalShow: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  deleteproduct_category = (pg_id1) => {
    this.setState({ loading: true })
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        onCancel={() => { this.setState({ alert: null, loading: false }); }}
        onConfirm={() => { this.conformDeleteproduct_category(pg_id1) }} >
        You will not be able to recover this imaginary file
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });
  }
  conformDeleteproduct_category = (pg_id1) => {
    this.setState({ alert: null });
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'pg_parent_id': pg_id1
    }
    call_api('a/product-category-delete', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            let categorylist = this.state.categorylist || [];
            this.setState({ loading: false });
            var filter_data = categorylist.filter(item => item.pg_id1 !== pg_id1);
            this.setState({ categorylist: filter_data });
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  render() {
    const { data_status, categorylist } = this.state;
    return (
      <>
        {this.state.alert}
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Item Category</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <Button type="button" onClick={() => this.add_category_mdl_display()} className="btn btn-info btn-sm">Add Category</Button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Parent</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Sequence</th>
                              <th>Image</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                categorylist.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.main_group_flag == 2 ? (list.pg_name2) : ((list.main_group_flag == 3) ? (list.pg_name3 + '->' + list.pg_name2) : '---')}</td>
                                      <td>{list.pg_name1}</td>
                                      <td>{list.main_group_flag == 1 ? 'Main' : list.main_group_flag == 2 ? "Sub" : "Child"}</td>
                                      <td>{list.dispalyOrder}</td>
                                      <td>
                                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img alt={list.pg_name1} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" src={apiUrl + list.image_link} onClick={() => this.viewImage(apiUrl + list.image_link, list.pg_id1)} onLoad={onload} style={{ height: 80, cursor: 'pointer' }} />
                                          )}
                                        </FadeIn>
                                      </td>
                                      <td>{list.active_state == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.changeStatus(list.pg_id1, 2)}>Active</button> : <button className="btn btn-danger btn-sm" onClick={(e) => this.changeStatus(list.pg_id1, 1)}>Inactive</button>}</td>
                                      <td>
                                        <button className="btn btn-sm btn-info mr-5" onClick={() => this.editData(list, 'edit')}>Edit</button>
                                        <button className="btn btn-danger btn-sm" onClick={() => this.deleteproduct_category(list.pg_id1)} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  <i className="fa fa-trash-o"></i></button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="8" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="category_type" className="control-label"><span className="text-danger">*</span>Category Type</label>
                  <select name="category_type" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.category_type} className="form-control">
                    <option value="0">Select Category Type</option>
                    <option value="1">Main Category</option>
                    <option value="2">Sub Category</option>
                    <option value="3">Child Category</option>
                  </select>
                </div>
              </div>
              {this.state.category_type != 1 ?
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="mainCatId" className="control-label"><span className="text-danger">*</span>Main Category</label>
                    <select name="mainCatId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.mainCatId} className="form-control">
                      <option value="0" key={"m11"}>Select Main Category</option>
                      {categorylist.map((list, index) => {
                        if (list.main_group_flag == 1 && list.pg_id1 != this.state.pg_id1) {
                          return (
                            <option key={"m_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                          )
                        }
                      })
                      }
                    </select>
                  </div>
                </div> : ''}
              {this.state.category_type == 3 ?
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="subCatId" className="control-label"><span className="text-danger">*</span>Sub Category</label>
                    <select name="subCatId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.subCatId} className="form-control">
                      <option value="0" key={"s11"}>Select Sub Category</option>
                      {categorylist.map((list, index) => {
                        if (list.main_group_flag == 2 && list.pg_parent_id == this.state.mainCatId) {
                          return (
                            <option key={"s_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                          )
                        }
                      })
                      }
                    </select>
                  </div>
                </div> : ''}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="catrgory_Name" className="control-label"><span className="text-danger">*</span>Category Name</label>
                  <input type="text" name="catrgory_Name" value={this.state.catrgory_Name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="catrgory_Name" />
                  <span className="text-danger"></span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="dispalyOrder" className="control-label">Sequence</label>
                  <input type="number" min={0} name="dispalyOrder" value={this.state.dispalyOrder || '0'} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="dispalyOrder" />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.pg_id1 != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.save_category_data()} className="btn btn-success"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_new_category_data()} className="btn btn-success"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
        <Modal size="md" fade={false} isOpen={this.state.modalShow} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ modalShow: false })}>X</button>}>
            <CardText className="modal-title">
              Category Image
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="form-wrap form-horizontal">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="text-center">
                  <img src={this.state.imageLink} width="50%" />
                  <br />
                  <br />
                  <center>
                    <input type="file" ref="file" accept="images/*" name="cat_image" onChange={(event) => this.changePriveImage(event)} />
                  </center>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.displayUbtn == true ? <Button type="button" disabled={this.state.loading} onClick={() => this.save_upload_image()} className="btn btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Upload</Button> : ''}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default ProductCategory;
