import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import FadeIn from "react-lazyload-fadein";
import notfoundImg from "../../assets/img/no_image.jpg";
class WebsitePriceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      imageSelect: false,
      modalShow: false,
      section_id: 0,
      categoryId: 0,
      web_prod_list: [],
      categorylist: [],
    };
  }
  componentDidMount() {
    this.fetch_prodduct_price_display();
    this.fetch_category();
  }
  fetch_prodduct_price_display() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/web-product-price-setting', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ web_prod_list: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  update_web_prod() {
    let section_id = this.state.section_id;
    let section_header = this.state.section_header || '';
    let price_from = this.state.price_from || 0;
    let price_to = this.state.price_to || 0;
    let categoryId = this.state.categoryId || 0;
    let section_ordering = this.state.section_ordering || 0;

    if (section_header == '') {
      alert("Header Name Can't be empty");
    }
    else if (price_from == '0' || price_from == '') {
      alert("Min Price Can't be empty");
    }
    else if (price_to == '0' || price_to == '') {
      alert("Max Price Can't be empty");
    }
    else if (section_ordering == '0' || section_ordering == '') {
      alert("order number Can't be empty");
    }
    else {
      var file = this.refs.header_image.files[0];
      this.setState({ loading: true })
      var imageurl = '';
      var self = this;
      if (file != null && this.state.imageSelect == true) {
        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            imageurl = reader.result;
          }
        }
      }

      setTimeout(function () {
        let optopns = {
          'LoginToken': localStorage.getItem('LoginToken'),
          'section_header': section_header,
          'price_from': price_from,
          'price_to': price_to,
          'section_id': section_id,
          'categoryId': categoryId,
          'section_ordering': section_ordering,
          'imageurl': imageurl,
        }
        call_api('a/web-prod-price-section-update', optopns)
          .then(
            (resolve) => {
              if (resolve.status == true) {
                self.setState({ web_prod_list: resolve.data, editModel: false, loading: false, data_status: true })
                notify.show(resolve.message, 'success', 2000);
              }
              else {
                notify.show(resolve.message, 'error', 2000);
                self.setState({ loading: false })
              }
            },
            (reject) => {
              console.log(reject);
            }
          )
      }, 1000);
    }
  }
  fetch_category() {

    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  changePriveImage(event) {
    let image = event.target.files[0];
    if (image != null) {
      if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg") {
        this.setState({ temp_image: URL.createObjectURL(image), imageSelect: true });
      }
      else {
        this.setState({ imageSelect: false, temp_image: '' });
      }
    }
    else {
      this.setState({ imageSelect: false, temp_image: '' });
    }
  }
  save_web_prod() {
    let section_header = this.state.section_header || '';
    let price_from = this.state.price_from || 0;
    let price_to = this.state.price_to || 0;
    let categoryId = this.state.categoryId || 0;
    let section_ordering = this.state.section_ordering || 0;

    if (section_header == '') {
      alert("Header Name Can't be empty");
    }
    else if (price_from == '0' || price_from == '') {
      alert("Min Price Can't be empty");
    }
    else if (price_to == '0' || price_to == '') {
      alert("Max Price Can't be empty");
    }
    else if (section_ordering == '0' || section_ordering == '') {
      alert("order number Can't be empty");
    }
    else {
      var file = this.refs.header_image.files[0];
      this.setState({ loading: true })
      var self = this;
      if (file != null && this.state.imageSelect == true) {
        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            let imageurl = reader.result;
            let optopns = {
              'LoginToken': localStorage.getItem('LoginToken'),
              'section_header': section_header,
              'price_from': price_from,
              'price_to': price_to,
              'categoryId': categoryId,
              'section_ordering': section_ordering,
              'imageurl': imageurl,
            }
            call_api('a/add-web-prod-price', optopns)
              .then(
                (resolve) => {
                  if (resolve.status == true) {
                    self.setState({ web_prod_list: resolve.data, editModel: false, loading: false, data_status: true })
                    notify.show(resolve.message, 'success', 2000);
                  }
                  else {
                    notify.show(resolve.message, 'error', 2000);
                    self.setState({ loading: false })
                  }
                },
                (reject) => {
                  console.log(reject);
                }
              )
          }
        }
        else {
          this.setState({ loading: false })
        }
      }
      else {
        this.setState({ loading: false })
      }
    }
  }
  editData = (list) => {
    this.setState({
      section_id: list.section_id, categoryId: list.categoryId, section_ordering: list.section_ordering, section_header: list.section_header, price_from: list.price_from, price_to: list.price_to,
      editModel: true, imageSelect: false, modelHeader: 'Edit Item Price Section', temp_image: apiUrl + list.headerImage
    })
  }
  add_new_prod_brand() {
    this.setState({
      editModel: true, section_header: '', categoryId: 0, price_from: 0, price_to: 0, section_id: 0,
      section_ordering: '', modelHeader: 'Add Item Price Section', temp_image: '', imageSelect: false
    })
  }


  changeStatus(id, section_status) {
    let optopns = {
      'section_status': section_status, 'id': id,
      'LoginToken': localStorage.getItem('LoginToken'),
    };
    call_api('a/web-product-price-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ web_prod_list: resolve.data, modalShow: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  render() {
    const { data_status, web_prod_list, categorylist, brand_list } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Website Item Price</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <Button type="button" onClick={() => this.add_new_prod_brand()} className="btn btn-sm btn-info">Add Price Section</Button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Number Sequence</th>
                              <th>Header Name</th>
                              <th>Hrader Image </th>
                              <th>Min Price</th>
                              <th>Max Price</th>
                              <th>Date </th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                web_prod_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.section_ordering}</td>
                                      <td>{list.section_header}</td>
                                      <td>
                                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img alt={"Slider"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" width="200px" src={apiUrl + list.headerImage} onLoad={onload} style={{ cursor: 'pointer' }} />
                                          )}
                                        </FadeIn>
                                      </td>
                                      <td>{list.price_from}</td>
                                      <td>{list.price_to}</td>
                                      <td>{list.section_created}</td>
                                      <td>{list.section_status == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.changeStatus(list.section_id, 0)}>Active</button> : <button className="btn btn-danger btn-sm" onClick={(e) => this.changeStatus(list.section_id, 1)}>Inactive</button>}</td>
                                      <td><button className="btn btn-sm btn-info" onClick={() => this.editData(list, 'edit')}>Edit</button></td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="8" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="section_header" className="col-md-4 control-label"><span className="text-danger">*</span> Header Name</label>
                  <div className="col-md-8">
                    <input type="text" name="section_header" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.section_header || ''} className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="section_ordering" className="col-md-4 control-label"><span className="text-danger">*</span> Order Sequence</label>
                  <div className="col-md-8">
                    <input type="number" min={0} name="section_ordering" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.section_ordering || ''} className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="price_from" className="col-md-4 control-label"><span className="text-danger">*</span> From Price</label>
                  <div className="col-md-8">
                    <input type="number" min={0} name="price_from" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.price_from || ''} className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="price_to" className="col-md-4 control-label"><span className="text-danger">*</span> From To</label>
                  <div className="col-md-8">
                    <input type="number" min={0} name="price_to" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.price_to || ''} className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="categoryId" className="col-md-4 control-label"><span className="text-danger">*</span> Item category</label>
                  <div className="col-md-8">
                    <select type="text" name="categoryId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.categoryId} className="form-control" >
                      <option value="0">Select Category</option>
                      {
                        this.state.categorylist.map((list, index) => {
                          return (
                            (list.main_group_flag == 1) ?
                              <option key={"m_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                              : ''
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="header_image" className="col-md-4 control-label"><span className="text-danger">*</span>Header Image</label>
                  <div className="col-md-8">
                    <input type="file" accept="images/*" ref="header_image" name="header_image" onChange={(event) => this.changePriveImage(event)} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mt-5 mb-5 text-center">
                      <FadeIn height={50} duration={50} easing={'ease-out'}>
                        {onload => (
                          <img alt={"Header Image"} title="Header Image" height="180px" width="100%" src={this.state.temp_image || ''} onLoad={onload} style={{ cursor: 'pointer' }} />
                        )}
                      </FadeIn>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.section_id != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.update_web_prod()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_web_prod()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default WebsitePriceSection;
