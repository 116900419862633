import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Input, CardText } from 'reactstrap';
import { call_api, apiUrl } from '../../connect/Connect.js';
import axios from 'axios';
import { Logistics_pincode_area_jquery } from "../../components/jquery/Web_jquery.js";
import '../../assets/dropify.min.css'
import Notifications, { notify } from 'react-notify-toast';
class VendorModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            modalShow: false,
            displayPage: false,
            modelType: 0,
            modelId: 0,
            data: '',
            kyc: '',
            file: '',
            city_id: '',
            User_name: '',
            gst_in_no: '',
            it_pan_no: '',
            full_address: '',
            contact_details: '',
            email_identity: '',
            full_name: '',
            comp_pass: '',
            state_id: '22',
            country_id: '1',
            pin_code: '',
            headerTitle: '',
            country_state_city: [],
            menu: [],
            pincode: [],
            area_list: [],
            pincode_list: []
        };
        this.alloteMenu = this.alloteMenu.bind(this);
    }
    onTrigger = (status) => {
        this.setState({ modalShow: false });
        this.props.parentCallback(status);
    }
    alloteMenu = (e, menuId) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        let optopns = { 'modelId': this.state.modelId, 'menuid': menuId, 'type': isChecked };
        call_api('a/admin-menu-allot', optopns)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        notify.show(resolve.message, 'success', 3000);
                        this.setState({ menu: resolve.menu });
                    }
                    else {
                        notify.show(resolve.message, 'error', 3000);
                    }
                },
                (reject) => {
                    console.log(reject);
                })
    }
    fetch_location = () => {
        call_api('m/fetch_country_state_city_option', {})
            .then(
                (resolve) => {
                    if (resolve['status'] == 1) {
                        this.setState({ country_state_city: resolve['data'], pincode: resolve['pincode'] });
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            modelId: nextProps.modelId, modalShow: nextProps.displayPopup, modelType: nextProps.modelType,
            headerTitle: nextProps.headerTitle, it_pan_no: '', User_name: '', full_address: '', comp_pass: '',
            pin_code: '', contact_details: '', gst_in_no: '', email_identity: '', full_name: ''
        });
        if (nextProps.modelType == 5) {
            this.fetch_location();
            this.setState({ masterId: 0, displayPage: true, loading: false, data_status: true });
        }
        else if (nextProps.modelType == 10) {
            this.get_area_allot(nextProps.modelId);
            this.setState({ masterId: 0, logID: nextProps.modelId, displayPage: true, loading: false, data_status: true });
        }
        else {
            this.fetch_detail(nextProps.modelId, nextProps.modelType);
            this.fetch_location();
        }
    }
    fetch_detail = (modelId, modelType) => {
        let optopns = {
            'modelId': modelId,
            'modelType': modelType
        }
        call_api('a/admin-detail', optopns)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        if (modelType == 2) {
                            this.setState({ menu: resolve.menu });
                        }
                        else if (modelType == 1) {
                            let data = resolve.data;
                            this.setState({
                                User_name: data.User_name,
                                masterId: modelId,
                                city_id: data.city_id,
                                state_id: data.state_id,
                                pin_code: data.pin_code,
                                country_id: data.country_id,
                                comp_pass: data.comp_pass,
                                contact_details: data.contact_details,
                                email_identity: data.email_identity,
                                full_address: data.full_address,
                                full_name: data.full_name,
                                gst_in_no: data.gst_in_no,
                                it_pan_no: data.it_pan_no,
                            });
                        }
                        else if (modelType == 3) {
                            this.setState({ kyc: resolve.kyc });
                        }
                        this.setState({ displayPage: true, loading: false, data_status: true });
                    }
                    else {
                        this.setState({ loading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    save_update_data() {
        let data = this.state;
        if (data.User_name == '') {
            this.refs.User_name.focus();
        }
        else if (data.country_id == '') {
            this.refs.country_id.focus();
        }
        else if (data.city_id == '') {
            this.refs.city_id.focus();
        }
        else if (data.state_id == '') {
            this.refs.state_id.focus();
        }
        else if (data.pin_code == '') {
            this.refs.pin_code.focus();
        }
        else if (data.comp_pass == '') {
            this.refs.comp_pass.focus();
        }
        else if (data.contact_details == '') {
            this.refs.contact_details.focus();
        }
        else if (data.email_identity == '') {
            this.refs.email_identity.focus();
        }
        else if (data.full_address == '') {
            this.refs.full_address.focus();
        }
        else if (data.full_name == '') {
            this.refs.full_name.focus();
        }
        /* else if(data.gst_in_no=='')
        {
            this.refs.gst_in_no.focus();
        }
        else if(data.it_pan_no=='')
        {
            this.refs.it_pan_no.focus();
        } */
        else {
            let options = {
                'LoginToken': localStorage.getItem('LoginToken'),
                'User_name': data.User_name,
                'masterId': data.masterId,
                'city_id': data.city_id,
                'state_id': data.state_id,
                'pin_code': data.pin_code,
                'country_id': data.country_id,
                'comp_pass': data.comp_pass,
                'contact_details': data.contact_details,
                'email_identity': data.email_identity,
                'full_address': data.full_address,
                'full_name': data.full_name,
                'gst_in_no': data.gst_in_no,
                'it_pan_no': data.it_pan_no,
            }
            this.setState({ loading: true });
            call_api('a/master-save-update', options)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            notify.show(resolve.message, 'success', 2000);
                            this.setState({ modalShow: false, loading: false });
                            this.onTrigger(true);
                        }
                        else {
                            notify.show(resolve.message, 'error', 2000);
                            this.setState({ isLoading: false });
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
    }

    changeDocument = (e, type) => {
        let image = e.target.files[0];
        if (image != null) {
            if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg") {
                var reader = new FileReader();
                var self = this;
                reader.onload = function (upload) {
                    self.setState({
                        file: upload.target.result
                    });
                };
                reader.readAsDataURL(image);

                setTimeout(function () {
                    const data = {
                        modelId: self.state.modelId,
                        modelImage: self.state.file,
                        type: type
                    }
                    axios({
                        url: apiUrl + 'a/admin-kycUpload',
                        method: 'POST',
                        data: data,
                        headers: {
                            'Content-Type': 'application/json', 'Authorization': 'bearer hg4ug4ug4g4uh2gu42g4u2'
                        }
                    }).then((response) => {
                        if (response.data.status == true) {
                            notify.show(response.data.message, 'success', 3000);
                            self.setState({
                                kyc: response.data.kyc
                            });
                        }
                        else {
                            notify.show(response.data.message, 'error', 3000);
                        }
                    }).catch((error) => {
                        //setResponse("error");
                    })
                }, 2000);

            }
            else {
                notify.show('upload valid file', 'error', 3000);
            }
        }
    }

    get_area_allot = (logID) => {
        let optopns = {
            'LoginToken': localStorage.getItem('LoginToken'),
            'logID': logID
        }
        call_api('a/fetch-area-pincode', optopns)
            .then(
                (resolve) => {
                    if (resolve.status == true) {
                        Logistics_pincode_area_jquery(resolve.pincode, resolve.area, call_api)
                    }
                    else {
                        notify.show(resolve.message, 'error', 2000);
                        this.setState({ isLoading: false })
                    }
                },
                (reject) => {
                    console.log(reject);
                }
            )
    }
    render() {
        const { loading, modalShow, displayPage, data, kyc, modelType, headerTitle, menu } = this.state;
        return (
            <div>
                <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
                <Modal size="lg" fade={false} isOpen={modalShow} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false} id="getLogPinArea">
                    <ModalHeader>
                        <button type="button" className="close" onClick={() => { this.onTrigger(false); }} aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <CardText className="modal-title">
                            {headerTitle}
                        </CardText>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-wrap form-horizontal">
                            <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                {displayPage == true && (modelType == '1' || modelType == '5') ?
                                    <div>
                                        <h4 className="mb-10 text-center">Personal Information</h4>
                                        <hr />
                                        <div className="form-group">
                                            <label htmlFor="User_name" className="col-md-4 control-label">User Name</label>
                                            <div className="col-md-6">
                                                <input ref="User_name" type="text" name="User_name" value={this.state.User_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="User_name" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="full_name" className="col-md-4 control-label">Name :</label>
                                            <div className="col-md-6">
                                                <input ref="full_name" type="text" name="full_name" value={this.state.full_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="full_name" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email_identity" className="col-md-4 control-label">Email : </label>
                                            <div className="col-md-6">
                                                <input ref="email_identity" type="text" name="email_identity" value={this.state.email_identity || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="email_identity" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="contact_details" className="col-md-4 control-label">Contact : </label>
                                            <div className="col-md-6">
                                                <input ref="contact_details" type="text" name="contact_details" value={this.state.contact_details || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="contact_details" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="comp_pass" className="col-md-4 control-label">Password : </label>
                                            <div className="col-md-6">
                                                <input ref="comp_pass" type="text" name="comp_pass" value={this.state.comp_pass || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="comp_pass" />
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ display: 'none' }}>
                                            <label ref="country_id" htmlFor="country_id" className="col-md-4 control-label">Country : </label>
                                            <div className="col-md-6">
                                                <select name="country_id" value={this.state.country_id || '1'} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="country_id">
                                                    <option key={"c00"} value="">Select Country</option>
                                                    {this.state.country_state_city.map((country, index) => {
                                                        return (
                                                            (country.l_master_type == 1) ?
                                                                <option key={'c' + index} value={country.id}>{country.name}</option>
                                                                : ''
                                                        )
                                                    }
                                                    )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group" id="state_hide" style={{ display: 'none' }}>
                                            <label htmlFor="state_id" className="col-md-4 control-label">State : </label>
                                            <div className="col-md-6">
                                                <select ref="state_id" name="state_id" value={this.state.state_id || '22'} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="state_id">
                                                    <option key={"s00"}>Select State</option>
                                                    {this.state.country_state_city.map((state, index) => {
                                                        return (
                                                            (state.l_master_type == 2 && this.state.country_id == state.l3) ?
                                                                <option key={'c' + index} value={state.l1}>{state.name}</option>
                                                                : ''
                                                        )
                                                    }
                                                    )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="city_id" className="col-md-4 control-label">City : </label>
                                            <div className="col-md-6">
                                                <select ref="city_id" name="city_id" value={this.state.city_id || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="city_id">
                                                    <option key={"c00"}>Select City</option>
                                                    {this.state.country_state_city.map((city, index) => {
                                                        return (
                                                            (city.l_master_type == 3 && this.state.state_id == city.l3) ?
                                                                <option key={'c' + index} value={city.id}>{city.name}</option>
                                                                : ''
                                                        )
                                                    }
                                                    )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="pin_code" className="col-md-4 control-label">Pincode : </label>
                                            <div className="col-md-6">
                                                <select ref="pin_code" name="pin_code" value={this.state.pin_code || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="pin_code">
                                                    <option key={"p00"}>Select Pincode</option>
                                                    {this.state.pincode.map((list, index) => {
                                                        return (
                                                            (list.city_id == this.state.city_id) ?
                                                                <option key={"p" + list.p_id} value={list.p_id}>{list.pincode}</option>
                                                                : ''
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="full_address" className="col-md-4 control-label">Full Address : </label>
                                            <div className="col-md-6">
                                                <input type="text" ref="full_address" name="full_address" value={this.state.full_address || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="full_address" />
                                            </div>
                                        </div>
                                        <h4 className="mb-10 mt-10 text-center">Professional Information</h4>
                                        <hr />
                                        <div className="form-group">
                                            <label htmlFor="it_pan_no" className="col-md-4 control-label">Pan No. : </label>
                                            <div className="col-md-6">
                                                <input type="text" ref="it_pan_no" name="it_pan_no" value={this.state.it_pan_no || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="it_pan_no" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="gst_in_no" className="col-md-4 control-label">GST No. : </label>
                                            <div className="col-md-6">
                                                <input type="text" ref="gst_in_no" name="gst_in_no" value={this.state.gst_in_no || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="gst_in_no" />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    displayPage == true && modelType == '2' ?
                                        <Table responsive={true}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Page Name</th>
                                                    <th>Created</th>
                                                    <th>Alloted</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {menu.map((mlist, index) => {
                                                    return (
                                                        <tr key={"menu" + mlist.id}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>{mlist.page_name}</td>
                                                            <td>{mlist.created}</td>
                                                            <td><Input type={"checkbox"} checked={mlist.asign_menu > 0 ? true : false} onChange={(evt) => this.alloteMenu(evt, mlist.id)} /></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        :
                                        displayPage == true && modelType == '3' ?
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="dropify-wrapper has-preview">
                                                            <div className="dropify-loader" style={{ display: 'none' }}></div>
                                                            <input type="file" accept="image/png,image/jpeg,image/jpg" name="adhar" onChange={(e) => this.changeDocument(e, 'adhar_card')} id="input-file-now-custom-1" className="dropify" />
                                                            <button type="button" className="dropify-clear">Remove</button>
                                                            <div className="dropify-preview" style={{ display: 'block' }}>
                                                                <span className="dropify-render">
                                                                    {kyc && kyc.adhar_card ?
                                                                        <img src={apiUrl + kyc.adhar_card} />
                                                                        :
                                                                        <img src="" />
                                                                    }
                                                                </span>
                                                                <div className="dropify-infos">
                                                                    <div className="dropify-infos-inner">
                                                                        <p className="dropify-filename">
                                                                            <span className="file-icon"></span>
                                                                            <span className="dropify-filename-inner">Adhar Card</span></p>
                                                                        <p className="dropify-infos-message">Drag and drop or click to replace</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="dropify-wrapper has-preview">
                                                            <div className="dropify-loader" style={{ display: 'none' }}></div>
                                                            <input type="file" accept="image/png,image/jpeg,image/jpg" name="pan" onChange={(e) => this.changeDocument(e, 'pan_card')} id="input-file-now-custom-2" className="dropify" />
                                                            <button type="button" className="dropify-clear">Remove</button>
                                                            <div className="dropify-preview" style={{ display: 'block' }}>
                                                                <span className="dropify-render">
                                                                    {kyc && kyc.pan_card ?
                                                                        <img src={apiUrl + kyc.pan_card} />
                                                                        :
                                                                        <img src="" />
                                                                    }
                                                                </span>
                                                                <div className="dropify-infos">
                                                                    <div className="dropify-infos-inner">
                                                                        <p className="dropify-filename">
                                                                            <span className="file-icon"></span>
                                                                            <span className="dropify-filename-inner">Pan Card</span></p>
                                                                        <p className="dropify-infos-message">Drag and drop or click to replace</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            displayPage == true && modelType == '10' ?
                                                <div>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="pincode_id" className="col-md-3 col-lg-3 control-label">Select Pincode</label>
                                                                <div className="col-md-9 col-lg-9">
                                                                    <div className="row">
                                                                        <div id="pincodeSection"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-lg-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="discountType" className="col-md-3 col-lg-3 control-label">Area Selection</label>
                                                                <div className="col-md-9 col-lg-9">
                                                                    <div className="row">
                                                                        <div id="areaSection"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12" id="getMessage" style={{ color: 'red', textAlign: 'center', fontSize: 'medium' }}>
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12">
                                                            <div className="form-group">
                                                                <button className="btn btn-sm btn-success mr-5" value={this.state.logID} id="save_area_allocation">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {displayPage == true && (modelType == '1' || modelType == '5') ?
                            <Button type="button" disabled={loading} onClick={() => this.save_update_data()} className="btn btn-sm btn-primary"> {loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</Button>
                            : ''}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default VendorModel;