import React from 'react';
import {Redirect} from 'react-router-dom';

function Logout()
{
    window.localStorage.removeItem("reg_Id");
    window.localStorage.removeItem("LoginToken");
    window.localStorage.removeItem("full_name");
    window.localStorage.removeItem("owner_Type");
    window.localStorage.removeItem("owner_flag");
    return <div>{<Redirect to={{pathname: '/service-login',state: { page_reload: true }}}></Redirect>}</div>
}
export default Logout;