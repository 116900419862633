import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import FadeIn from "react-lazyload-fadein";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import notfoundImg from "../../assets/img/no_image.jpg";
class Top_viewed_product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      viewed_model: false,
      vendor_id: 0,
      searchproduct_id: 0,
      modalHeader: "",
      responseText: "Loading Please Wait",
      viewed_product_list: [],
      viewed_product_detail: [],
      temp_view_product_list: [],
      temp_product_list: [],
      product_list: [],
      venderlist: [],
    };
  }
  componentDidMount() {
    this.fetch_viewed_product_list();
    this.fetch_product_list();
    this.fetch_vender_list();
  }

  fetch_viewed_product_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
    }
    call_api('a/top-viewed-product', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ viewed_product_list: resolve.data, temp_view_product_list: resolve.data, data_status: true })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_product_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'search': '',
      'limit': 50,
      'start': 0,
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ product_list: resolve.data, temp_product_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_vender_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': 3
    }
    call_api('a/fetch-vender-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ venderlist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  viewDetail = (list) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      clickId: list.clickId,
    }
    this.setState({ viewed_model: true, modalHeader: list.full_name + " - " + list.p_name, viewed_product_detail: [] })
    call_api('a/fetch-product-global-record', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ viewed_product_detail: resolve.data, viewed_product_detail_status: true })
          }
          else {
            this.setState({ viewed_product_detail_status: false, responseText: resolve.message })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  searchRecord = () => {
    var temp_view_product_list = this.state.temp_view_product_list;
    const vendor_id = this.state.vendor_id;
    const searchproduct_id = this.state.searchproduct_id;
    var temp_product_list = this.state.temp_product_list;
    if (vendor_id != '0' && searchproduct_id != '0') {
      temp_view_product_list = temp_view_product_list.filter((item) => item.venderId == vendor_id && item.clickId == searchproduct_id);
    }
    else if (searchproduct_id != '0') {
      temp_view_product_list = temp_view_product_list.filter((item) => item.clickId == searchproduct_id);
    } else if (vendor_id != '0') {
      temp_view_product_list = temp_view_product_list.filter((item) => item.venderId == vendor_id);
    }
    if (vendor_id != '0') {
      temp_product_list = temp_product_list.filter((item) => item.venderId == vendor_id)
    }
    this.setState({ viewed_product_list: temp_view_product_list, product_list: temp_product_list })
  }
  render() {
    const { data_status, viewed_product_detail, product_list, viewed_product_detail_status, responseText, viewed_product_list, modalHeader, viewed_model, vendor_id, searchproduct_id, venderlist } = this.state;
    return (
      <div>
        {this.state.alert}
        <div className="page-wrapper" style={{ minHeight: "359px" }}>
          <div className="container-fluid">
            <div className="row heading-bg">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 className="txt-dark">Top Viewed Product</h5>
              </div>
              <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol className="breadcrumb">
                  <li><a href="/">Dashboard</a></li>
                  <li className="active"><span>Top Viewed Product</span></li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                          <div className="form-group">
                            <label className="control-label">Seller</label>
                            <select onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); }} name="vendor_id" value={vendor_id} className="form-control" >
                              <option value="0">Select Seller</option>
                              {
                                venderlist.map((vendor, vindex) => {
                                  return (
                                    <option key={"voption" + vindex} value={vendor.id}>{vendor.full_name} - {vendor.city_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="control-label">Product</label>
                            <select className="form-control select2" ref="searchproduct_id" name="searchproduct_id" value={searchproduct_id || "0"} id="searchproduct_id" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); }}>
                              <option value="0">Select Product</option>
                              {product_list.map((list, index) => {
                                return (
                                  <option key={"sch" + index} value={list.product_id}>{list.prod_name.slice(0, 40)}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <button className="btn btn-xs btn-info" onClick={() => this.searchRecord()}>Search</button>
                          </div>
                        </div>
                      </div>
                      <div className="table-wrap mt-20">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th width="40%">Product</th>
                                <th>Seller</th>
                                <th>Image</th>
                                <th>Status</th>
                                <th>Total View</th>
                                <th>Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data_status == true ?
                                  viewed_product_list.map((list, index) => {
                                    return (
                                      <tr key={"row" + index}>
                                        <td>{index + 1}</td>
                                        <td>{list.p_name}</td>
                                        <td>{list.full_name}</td>
                                        <td>
                                          <FadeIn height={50} duration={50} easing={'ease-out'}>
                                            {onload => (
                                              <img alt={"Image"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" height="100px" width="100px" src={apiUrl + list.img_paths} onLoad={onload} style={{ cursor: 'pointer' }} />
                                            )}
                                          </FadeIn>
                                        </td>
                                        <td>{list.active_status == 1 ? <button className="btn btn-success btn-xs">Active</button> : <button className="btn btn-danger btn-xs">Inactive</button>}</td>
                                        <td>{list.total_view}</td>
                                        <td><button className="btn btn-xs btn-info" onClick={() => this.viewDetail(list)}>Detail</button></td>
                                      </tr>
                                    )
                                  })
                                  : <tr><td colSpan="7" align="center">Loading Please Wait</td></tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal size="lg" fade={false} isOpen={viewed_model} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                  <ModalHeader>
                    <button type="button" className="close" onClick={() => this.setState({ viewed_model: false })} aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                    <CardText className="modal-title">
                      {modalHeader}
                    </CardText>
                  </ModalHeader>
                  <ModalBody>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className="table-wrap mt-5">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>Sr</th>
                                  <th>Date</th>
                                  <th>Update</th>
                                  <th>Platform</th>
                                  <th>User Token</th>
                                  <th>User Name</th>
                                  <th>User Mobile</th>
                                  <th>Total View</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(viewed_product_detail_status == true) ?
                                  viewed_product_detail.map((list, index) => {
                                    return (
                                      <tr key={"price" + index}>
                                        <td>{index + 1}</td>
                                        <td>{list.dateTime}</td>
                                        <td>{list.last_update || '---'}</td>
                                        <td>{list.platform}</td>
                                        <td>{list.tokenId}</td>
                                        <td>{list.muser_name || '---'}</td>
                                        <td>{list.muser_mobno || '---'}</td>
                                        <td>{list.clickTotal}</td>
                                      </tr>
                                    )
                                  })
                                  :
                                  <tr><td colSpan="8" align="center">{responseText}</td></tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <></>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Top_viewed_product;
