import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import notfoundImg from "../../assets/img/no_image.jpg";
import Notifications, { notify } from 'react-notify-toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { product_jquery } from "../../components/jquery/Web_jquery.js";
import FadeIn from "react-lazyload-fadein";
class Product_price_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      product_id: 0,
      p_name: "",
      product_code: "",
      search_product: "",
      data_status: false,
      modalShow: false,
      action_status: false,
      price_edit: false,
      priceListlist: [],
      unit_list: [],
      tempary_data: [],
      productlist: [],
    };
  }
  componentDidMount() {
    this.fetch_product_list();
    this.fetch_price_list();
    this.fetch_unit_setting();
    product_jquery(apiUrl, call_api);
  }
  fetch_unit_setting() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/setting-unit', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ unit_list: resolve.data })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_product_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'search': '',
      'limit': 50,
      'start': 0,
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ productlist: resolve.data, data_status: true })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  searchproductprice = (e) => {
    let tempary_price = this.state.tempary_price;
    this.setState({ [e.target.name]: e.target.value })
    let searchId = e.target.value;
    if (searchId != 0 && e.target.name == 'searchproduct_id') {
      let search_list = tempary_price.filter(item => item.product_id === searchId);
      this.setState({ priceListlist: search_list, search_product: '' })
    }
    else if (searchId != '' && e.target.name == 'search_product') {
      let search_list = tempary_price.filter(item =>
        (item.p_name.toLowerCase()).includes((searchId.toLowerCase())) ||
        (item.product_code.toLowerCase()).includes((searchId.toLowerCase())) ||
        (item.p_barcode.toLowerCase()).includes((searchId.toLowerCase())) ||
        (item.hsn_code.toLowerCase()).includes((searchId.toLowerCase()))
      );
      this.setState({ priceListlist: search_list, searchproduct_id: '' })
    }
    else {
      this.setState({ priceListlist: tempary_price })
    }

  }
  fetch_price_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': 3
    }
    call_api('a/product-price-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            let searchproduct_id = this.state.searchproduct_id || '';
            this.setState({ tempary_price: resolve.data })
            if (searchproduct_id == '') {
              this.setState({ priceListlist: resolve.data })
            }
            else {
              var filter_data = resolve.data.filter(item => item.product_id == searchproduct_id);
              this.setState({ priceListlist: filter_data })
            }
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  newPriceAdd = () => {
    this.setState({
      priceModel: true, unitId: '', product_id: 0, unit_stock: '', product_size: '', price_edit: false,
      unitmrp: '', unitprice: '', unitdiscount: '', discountType: 'flat', p_barcode: '', shipping_charge: 0,
      item_weight: '', item_length: '', item_breadth: '', item_height: '', shipping_charge_type: 1,
      priceId: 0, max_order_qty: 0, headerTitle: 'Add New Item Price'
    });
  }
  newPriceEdit = (price) => {
    this.setState({
      priceModel: true, unitId: price.unitId, unit_stock: price.unit_stock, product_size: price.product_size, price_edit: true,
      unitmrp: price.unit_mrp, unitprice: price.unit_price, unitdiscount: price.unit_discount, p_barcode: price.p_barcode,
      p_name: price.p_name, product_code: price.product_code, shipping_charge_type: price.shipping_charge_type,
      item_weight: price.item_weight, item_length: price.item_length, item_breadth: price.item_breadth, item_height: price.item_height,
      discountType: price.disc_type, priceId: price.product_price_id, max_order_qty: price.max_order_qty, shipping_charge: price.shipping_charge,
      product_id: price.product_id, headerTitle: 'Update Item Price'
    });
  }
  save_price = () => {
    let unitId = this.state.unitId || '';
    let unit_stock = this.state.unit_stock || '';
    let unitmrp = this.state.unitmrp || '';
    let unitprice = this.state.unitprice || '';
    let unitdiscount = this.state.unitdiscount || '';
    let discountType = this.state.discountType || '';
    let product_size = this.state.product_size || '';
    let p_barcode = this.state.p_barcode || '';
    let priceId = this.state.priceId || '0';
    let product_id = this.state.product_id || '0';
    let max_order_qty = this.state.max_order_qty || '0';
    let item_weight = this.state.item_weight;
    let item_length = this.state.item_length;
    let item_height = this.state.item_height;
    let item_breadth = this.state.item_breadth;
    let shipping_charge_type = this.state.shipping_charge_type;
    let shipping_charge = this.state.shipping_charge;
    if (product_id == '' || product_id == 0) {
      this.refs.product_id.focus();
      notify.show("Select Item", 'error', 2000);
    }
    else if (unitId == '') {
      this.refs.unitId.focus();
      notify.show("Select Item Unit", 'error', 2000);
    }
    else if (unit_stock == '') {
      this.refs.unit_stock.focus();
      notify.show("Enter Item Stock", 'error', 2000);
    }
    else if (product_size == '') {
      this.refs.product_size.focus();
      notify.show("Enter Item Size", 'error', 2000);
    }
    else if (unitmrp == '') {
      this.refs.unitmrp.focus();
      notify.show("Enter Item Price", 'error', 2000);
    }
    else if (unitprice == '') {
      this.refs.unitprice.focus();
      notify.show("Enter Sale Price", 'error', 2000);
    }
    else if (unitdiscount == '') {
      this.refs.unitdiscount.focus();
      notify.show("Enter Discount", 'error', 2000);
    }
    else if (discountType == '' || discountType == 0) {
      this.refs.discountType.focus();
      notify.show("Select Discount Type", 'error', 2000);
    }
    else if (p_barcode == '') {
      this.refs.p_barcode.focus();
      notify.show("Enter Barcode", 'error', 2000);
    }
    else if (max_order_qty == '' || max_order_qty == 0) {
      this.refs.max_order_qty.focus();
      notify.show("Enter Max Order Quantity", 'error', 2000);
    }
    else if (item_weight == '') {
      notify.show("Enter Item Weight", 'error', 2000);
    }
    else if (item_length == '') {
      notify.show("Enter Item Length", 'error', 2000);
    }
    else if (item_height == '') {
      notify.show("Enter Item Height", 'error', 2000);
    }
    else if (item_breadth == '') {
      notify.show("Enter Item Breadth", 'error', 2000);
    }
    else {
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'unitId': unitId,
        'unit_stock': unit_stock,
        'unitmrp': unitmrp,
        'unitprice': unitprice,
        'unitdiscount': unitdiscount,
        'discountType': discountType,
        'product_size': product_size,
        'max_order_qty': max_order_qty,
        'shipping_charge_type': shipping_charge_type,
        'shipping_charge': shipping_charge,
        'p_barcode': p_barcode,
        'priceId': priceId,
        'product_id': product_id,
        'item_height': item_height,
        'item_breadth': item_breadth,
        'item_length': item_length,
        'item_weight': item_weight,
      }
      this.setState({ loading: true })
      call_api('a/price-save-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ loading: false, priceModel: false });
              notify.show(resolve.message, 'success', 2000);
              this.fetch_price_list();
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  changeProductPrice = (e, priceId, key) => {
    let value = e.target.value;
    if (value >= 0) {
      if (e._reactName == 'onChange') {
        const items = this.state.priceListlist.filter(item => item.product_price_id == priceId ?
          item[key] = value : item[key] = item[key]);
        this.setState({ priceListlist: items, action_status: true });
      }
      else if (e._reactName == 'onBlur' && this.state.action_status == true) {
        let getAlert1 = () => (
          <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
            closeOnClickOutside={false} confirmBtnText="Update confirm"
            btnSize="sm"
            onCancel={() => { this.setState({ alert: null }); }}
            onConfirm={() => { this.final_update_price(key, priceId, value) }} >
            Confirm to update value
          </SweetAlert>
        );
        this.setState({ alert: getAlert1() });
      }
    }
  }
  final_update_price = (key, priceId, value) => {
    this.setState({ loading: true, alert: null })
    let optopns = { 'LoginToken': localStorage.getItem('LoginToken'), 'key': key, 'priceId': priceId, 'value': value };
    call_api('a/price-change-valueBykey', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            this.setState({ action_status: false, loading: false });
            this.fetch_price_list();
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  deleteproduct_price = (product_price_id) => {
    this.setState({ loading: true })
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        onCancel={() => { this.setState({ alert: null, loading: false }); }}
        onConfirm={() => { this.conformDeleteproduct_price(product_price_id) }} >
        You will not be able to recover this imaginary file
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });
  }
  conformDeleteproduct_price = (product_price_id) => {
    this.setState({ alert: null });
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'product_price_id': product_price_id
    }
    call_api('a/product-price-delete', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            let tempary_price = this.state.tempary_price || [];
            let priceListlist = this.state.priceListlist || [];
            this.setState({ loading: false });
            var filter_data = tempary_price.filter(item => item.product_price_id !== product_price_id);
            var filter_data2 = priceListlist.filter(item => item.product_price_id !== product_price_id);
            this.setState({ priceListlist: filter_data2, tempary_price: filter_data });
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  product_export = () => {
    let optopns = { 'LoginToken': localStorage.getItem('LoginToken') };
    this.setState({ loading: true });
    call_api('a/product-price-export', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            if (resolve.excell_url) {
              window.open(resolve.excell_url, '_blank');
            }
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
          this.setState({ loading: false });
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false });
        }
      )
  }
  render() {
    const { data_status, productlist } = this.state;
    return (
      <>
        {this.state.alert}
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Item Price</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <button type="button" disabled={this.state.loading} onClick={() => this.product_export()} className="btn btn-sm btn-info mr-2">{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Export</button>
                  <button className="btn btn-success btn-sm" onClick={() => this.newPriceAdd()}>New Price</button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <select className="form-control select2" ref="searchproduct_id" name="searchproduct_id" value={this.state.searchproduct_id || "0"} id="searchproduct_id" onChange={(e) => this.searchproductprice(e)}>
                              <option value="0">Select Item</option>
                              {productlist.map((list, index) => {
                                return (
                                  <option key={"sch" + index} value={list.product_id}>{list.prod_name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 col-sm-3 col-md-3">
                          <div className="form-group">
                            <input type="text" name="search_product" value={this.state.search_product || ''} onChange={(e) => this.searchproductprice(e)} className="form-control" placeholder="Item Name/HSN/Style Code" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table mb-0 text-center">
                            <thead>
                              <tr>
                                {/* <th>Price Id</th> */}
                                <th>SR</th>
                                <th width="20%">Item</th>
                                {/* <th>Unit</th> */}
                                {/* <th>Size</th> */}
                                <th>Stock Qty</th>
                                <th>Item Price</th>
                                <th>Discount(%)</th>
                                {/* <th>Type</th> */}
                                <th>Sale Price</th>
                                <th>Shipping Charge</th>
                                <th>Sequence</th>
                                <th>Weight</th>
                                <th>Length</th>
                                <th>Height</th>
                                <th>Breadth</th>
                                <th>Status</th>
                                {/* <th>Verify</th> */}
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data_status == true ?
                                  this.state.priceListlist.map((list, index) => {
                                    return (
                                      <tr key={"price" + index}>
                                        {/* <td>{list.product_price_id}</td> */}
                                        <td>{index + 1}</td>
                                        <td>
                                          <div className="text-left" style={{ display: "flex" }}>
                                            <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                              {onload => (
                                                <img alt={list.p_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={list.img_paths} onLoad={onload} />
                                              )}
                                            </FadeIn>
                                            <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                              <p className="text-info">{list.p_name.slice(0, 30)}</p>
                                              <p>HSN Code : {list.hsn_code || '---'}</p>
                                              <p>SKU Id : {list.product_code || '---'}</p>
                                              <p>Barcode : {list.p_barcode || '---'}</p>
                                              <p>Size : {list.product_size || '---'}</p>
                                            </div>
                                          </div>
                                        </td>
                                        {/* <td>{list.unit}</td> */}
                                        {/* <td><input type="text" disabled={this.state.loading} onChange={(e) => this.changeProductPrice(e, list.product_price_id, 'product_size')} onBlur={(e) => this.changeProductPrice(e, list.product_price_id, 'product_size')} className="form-control" value={list.product_size} /></td> */}
                                        <td><input type="number" disabled={this.state.loading} onChange={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_stock')} onBlur={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_stock')} className="form-control" value={list.unit_stock} /></td>
                                        <td><input type="number" disabled={this.state.loading} onChange={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_mrp')} onBlur={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_mrp')} className="form-control hide-appearance" value={list.unit_mrp} /></td>
                                        <td><input type="number" disabled={this.state.loading} onChange={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_discount')} onBlur={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_discount')} className="form-control" value={list.unit_discount} /></td>
                                        {/* <td>{list.disc_type}</td> */}
                                        <td><input type="number" disabled={true} onChange={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_price')} onBlur={(e) => this.changeProductPrice(e, list.product_price_id, 'unit_price')} className="form-control hide-appearance" value={list.unit_price} /></td>
                                        <td>
                                          {list.shipping_charge_type == 1 ? 'Auto' : list.shipping_charge}
                                        </td>
                                        <td><input type="number" disabled={this.state.loading} onChange={(e) => this.changeProductPrice(e, list.product_price_id, 'sequence')} onBlur={(e) => this.changeProductPrice(e, list.product_price_id, 'sequence')} className="form-control" value={list.sequence} /></td>
                                        <td>{list.item_weight}KG</td>
                                        <td>{list.item_length}CM</td>
                                        <td>{list.item_height}CM</td>
                                        <td>{list.item_breadth}CM</td>
                                        <td>{list.active_status == 1 ? <button className="btn btn-success btn-sm productPriceStatus" data-product-id={list.product_id} data-price-status="2" data-price-label="active_status" data-price-id={list.product_price_id}>Active</button> : <button className="btn btn-danger btn-sm productPriceStatus" data-product-id={list.product_id} data-price-status="1" data-price-label="active_status" data-price-id={list.product_price_id}>Inactive</button>}</td>
                                        {/* <td>{list.verified == 1 ? <button className="btn btn-success btn-sm productPriceStatus" data-product-id={list.product_id} data-price-status="2" data-price-label="verified" data-price-id={list.product_price_id}>Yes</button> : <button className="btn btn-danger btn-sm productPriceStatus" data-product-id={list.product_id} data-price-status="1" data-price-label="verified" data-price-id={list.product_price_id}>No</button>}</td> */}
                                        <td>
                                          <button className="btn btn-info btn-sm mr-2" onClick={(e) => this.newPriceEdit(list)}><i className="fa fa-pencil-square-o"></i></button>
                                          <button className="btn btn-danger btn-sm" onClick={() => this.deleteproduct_price(list.product_price_id)} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  <i className="fa fa-trash-o"></i></button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  :
                                  <tr><td colSpan="14" align="center">Please Wait</td></tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.priceModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ priceModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.headerTitle}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              {(this.state.price_edit == true) ?
                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 mb-2">
                  <div className="card">
                    <div className='card-body'>
                      <div className='row'>
                        <div className="col-sm-7">
                          <div>
                            <label className="control-label">Item Name :</label>
                            <span>{this.state.p_name || ''}</span>
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div>
                            <label className="control-label">Style Code :</label>
                            <span>{this.state.product_code || ''}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : (this.state.price_edit == false) ?
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="product_id" className="control-label"><span className="text-danger">*</span>Item</label>
                      <select className="form-control select2" ref="product_id" name="product_id" value={this.state.product_id || "0"} id="product_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                        <option value="0">Select Item</option>
                        {productlist.map((list, index) => {
                          return (
                            <option key={index} value={list.product_id}>{list.prod_name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  : ''}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="unitId" className="control-label"><span className="text-danger">*</span>Unit</label>
                  <select className="form-control" ref="unitId" name="unitId" value={this.state.unitId || ""} id="unitId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                    <option value="">Select Unit</option>
                    {this.state.unit_list.map((list, index) => {
                      return (
                        <option key={index} value={list.id}>{list.unit_name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="product_size" className="control-label"><span className="text-danger">*</span>Size</label>
                  <input className="form-control" ref="product_size" name="product_size" value={this.state.product_size || ""} id="product_size" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="unit_stock" className="control-label"><span className="text-danger">*</span>Stock Qty</label>
                  <input className="form-control" ref="unit_stock" name="unit_stock" value={this.state.unit_stock || ""} id="unit_stock" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="unitmrp" className="control-label"><span className="text-danger">*</span>Unit MRP</label>
                  <input className="form-control" ref="unitmrp" name="unitmrp" value={this.state.unitmrp || ""} id="unitmrp" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="unitprice" className="control-label"><span className="text-danger">*</span>Unit Price</label>
                  <input className="form-control" readOnly disabled={true} ref="unitprice" name="unitprice" value={this.state.unitprice || ""} id="unitprice" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="unitdiscount" className="control-label"><span className="text-danger">*</span>Discount</label>
                  <input className="form-control" ref="unitdiscount" name="unitdiscount" value={this.state.unitdiscount || ""} id="unitdiscount" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="discountType" className="control-label"><span className="text-danger">*</span>Discount Type</label>
                  <select className="form-control" ref="discountType" name="discountType" value={this.state.discountType || ""} id="discountType" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                    <option value="percent">Percent</option>
                    <option value="flat">Flat</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="shipping_charge_type" className="control-label"><span className="text-danger">*</span>Shipping Charge Type</label>
                  <select className="form-control" ref="shipping_charge_type" name="shipping_charge_type" value={this.state.shipping_charge_type || ""} id="shipping_charge_type" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                    <option value="1">Auto</option>
                    <option value="2">Manual</option>
                  </select>
                </div>
              </div>
              {this.state.shipping_charge_type == 2 && <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="shipping_charge" className="control-label"><span className="text-danger">*</span>Shipping Charge</label>
                  <input type="text" name="shipping_charge" value={this.state.shipping_charge} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="shipping_charge" />
                </div>
              </div>}
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="p_barcode" className="control-label"><span className="text-danger">*</span>Barcode</label>
                  <input type="text" name="p_barcode" value={this.state.p_barcode} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="p_barcode" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="max_order_qty" className="control-label"><span className="text-danger">*</span>Max Order Qty</label>
                  <input type="number" min={0} className="form-control" ref="max_order_qty" name="max_order_qty" value={this.state.max_order_qty || "0"} id="max_order_qty" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_weight" className="control-label"><span className="text-danger">*</span>Item Weight(In KG)</label>
                  <input type="text" name="item_weight" value={this.state.item_weight} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_weight" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_length" className="control-label"><span className="text-danger">*</span>Item Length(In CMS)</label>
                  <input type="text" name="item_length" value={this.state.item_length} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_length" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_breadth" className="control-label"><span className="text-danger">*</span>Item Breadth(In CMS)</label>
                  <input type="text" name="item_breadth" value={this.state.item_breadth} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_breadth" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_height" className="control-label"><span className="text-danger">*</span>Item Height(In CMS)</label>
                  <input type="text" name="item_height" value={this.state.item_height} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_height" />
                </div>
              </div>
              <div className="col-sm-12 text-center">
                <button className="btn btn-sm btn-success mr-5" disabled={this.state.loading} onClick={() => this.save_price()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Save</button>
                <button className="btn btn-sm btn-danger" onClick={(e) => this.setState({ priceModel: false })}>Cancel</button>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div></div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default Product_price_list;
