import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import FadeIn from "react-lazyload-fadein";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import notfoundImg from "../../assets/img/no_image.jpg";
class Blog_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      imageSelect: false,
      modalShow: false,
      blog_id: 0,
      blog_description: "",
      loading_text: "Loading Please Wait",
      blog_list: [],
    };
  }
  componentDidMount() {
    this.fetch_blog_list();
  }
  fetch_blog_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/blog-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ blog_list: resolve.data, data_status: true })
          }
          else {
            this.setState({ loading_text: resolve.message, data_status: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  update_web_prod() {
    let blog_id = this.state.blog_id;
    let blog_title = this.state.blog_title || '';
    let blog_description = this.state.blog_description || '';
    let sequence = this.state.sequence || 0;

    if (blog_title == '') {
      alert("Blog Title Can't be empty");
    }
    else if (sequence == '') {
      alert("Sequence Can't be empty");
    }
    else {
      var file = this.refs.blog_image.files[0];
      this.setState({ loading: true })
      var imageurl = '';
      var self = this;
      if (file != null && this.state.imageSelect == true) {
        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            imageurl = reader.result;
          }
        }
      }

      setTimeout(function () {
        let optopns = {
          'LoginToken': localStorage.getItem('LoginToken'),
          'blog_title': blog_title,
          'blog_id': blog_id,
          'sequence': sequence,
          'blog_description': blog_description,
          'imageurl': imageurl,
        }
        call_api('a/blog-update', optopns)
          .then(
            (resolve) => {
              if (resolve.status == true) {
                self.setState({ blog_list: resolve.data, editModel: false, loading: false, data_status: true })
                notify.show(resolve.message, 'success', 2000);
              }
              else {
                notify.show(resolve.message, 'error', 2000);
                self.setState({ loading: false })
              }
            },
            (reject) => {
              console.log(reject);
            }
          )
      }, 1000);
    }
  }
  changePriveImage(event) {
    let image = event.target.files[0];
    if (image != null) {
      if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg") {
        this.setState({ temp_image: URL.createObjectURL(image), imageSelect: true });
      }
      else {
        this.setState({ imageSelect: false, temp_image: '' });
      }
    }
    else {
      this.setState({ imageSelect: false, temp_image: '' });
    }
  }
  save_web_prod() {
    let blog_title = this.state.blog_title || '';
    let blog_description = this.state.blog_description || '';
    let sequence = this.state.sequence || 0;

    if (blog_title == '') {
      alert("Blog title Can't be empty");
    }
    else if (sequence == '') {
      alert("Sequence Can't be empty");
    }
    else {
      var file = this.refs.blog_image.files[0];
      this.setState({ loading: true })
      var self = this;
      if (file != null && this.state.imageSelect == true) {
        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            let imageurl = reader.result;
            let optopns = {
              'LoginToken': localStorage.getItem('LoginToken'),
              'blog_title': blog_title,
              'sequence': sequence,
              'blog_description': blog_description,
              'imageurl': imageurl,
            }
            call_api('a/add-blog', optopns)
              .then(
                (resolve) => {
                  if (resolve.status == true) {
                    self.setState({ blog_list: resolve.data, editModel: false, loading: false, data_status: true })
                    notify.show(resolve.message, 'success', 2000);
                  }
                  else {
                    notify.show(resolve.message, 'error', 2000);
                    self.setState({ loading: false })
                  }
                },
                (reject) => {
                  console.log(reject);
                }
              )
          }
        }
        else {
          this.setState({ loading: false })
        }
      }
      else {
        this.setState({ loading: false })
      }
    }
  }
  editData = (list) => {
    this.setState({
      blog_id: list.id, sequence: list.sequence, blog_title: list.blog_title, blog_description: list.blog_description,
      editModel: true, imageSelect: false, modelHeader: 'Edit Blog', temp_image: apiUrl + list.blog_image
    })
  }
  add_new_prod_brand() {
    this.setState({
      editModel: true, blog_title: '', blog_id: 0, blog_description: '',
      sequence: '', modelHeader: 'Add Blog', temp_image: '', imageSelect: false
    })
  }


  changeStatus(id, blog_status) {
    let optopns = {
      'blog_status': blog_status, 'id': id,
      'LoginToken': localStorage.getItem('LoginToken'),
    };
    call_api('a/blog-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ blog_list: resolve.data, modalShow: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  render() {
    const { data_status, blog_list } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Blog Listing</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <Button type="button" onClick={() => this.add_new_prod_brand()} className="btn btn-sm btn-info">Add Blog</Button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Sequence</th>
                              <th>Name</th>
                              <th>Image </th>
                              <th>Date </th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                blog_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.sequence}</td>
                                      <td>{list.blog_title}</td>
                                      <td>
                                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img alt={"Blog Image"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" width="200px" src={apiUrl + list.blog_image} onLoad={onload} style={{ cursor: 'pointer' }} />
                                          )}
                                        </FadeIn>
                                      </td>
                                      <td>{list.created_at}</td>
                                      <td>{list.blog_status == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.changeStatus(list.id, 0)}>Active</button> : <button className="btn btn-danger btn-sm" onClick={(e) => this.changeStatus(list.id, 1)}>Inactive</button>}</td>
                                      <td><button className="btn btn-sm btn-info" onClick={() => this.editData(list, 'edit')}>Edit</button></td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="7" align="center">{this.state.loading_text}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="blog_title" className="control-label"><span className="text-danger">*</span> Blog Title</label>
                  <input type="text" name="blog_title" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.blog_title || ''} className="form-control" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="sequence" className="control-label"><span className="text-danger">*</span> Sequence</label>
                  <input type="number" min={0} name="sequence" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.sequence || ''} className="form-control" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="blog_image" className="control-label"><span className="text-danger">*</span>Image</label>
                  <input type="file" accept="images/*" ref="blog_image" name="blog_image" onChange={(event) => this.changePriveImage(event)} />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mt-5 mb-5 text-center">
                  <FadeIn height={50} duration={50} easing={'ease-out'}>
                    {onload => (
                      <img alt={"Blog Image"} title="Header Image" height="180px" width="100%" src={this.state.temp_image || ''} onLoad={onload} style={{ cursor: 'pointer' }} />
                    )}
                  </FadeIn>
                  <br />
                </div>
              </div>
              <div className="col-lg-12">
                <SunEditor onChange={(event) => {
                  this.setState({ blog_description: event });
                }} onBlur={(event) => {
                  this.setState({ blog_description: event.target.innerHTML });
                }} defaultValue={this.state.blog_description}
                  setOptions={{
                    height: 200,
                    buttonList: [['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat']],
                    callBackSave: function (contents, isChanged) {
                      console.log(contents, isChanged);
                    }
                  }} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.blog_id != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.update_web_prod()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_web_prod()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default Blog_List;
