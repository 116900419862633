import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import FadeIn from "react-lazyload-fadein";
import notfoundImg from "../../assets/img/no_image.jpg";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { sleeves_list, fit_list, color_list, ocacsion_list, pattern_List, frabic_List } from '../../components/product_data.js';
import Product_Import_Mdl from './Product_Import_Mdl.js';
import { product_jquery } from "../../components/jquery/Web_jquery.js";
import SizeChart from './SizeChart.js'
class Product_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      stock_update_status: false,
      import_mdl: false,
      product_list_show: true,
      data_status: false,
      modalShow: false,
      price_list_show: true,
      showBtn: false,
      vender_parent_id: 0,
      categorylist: [],
      image_list: [],
      productlist: [],
      new_image_list: [],
      priceListlist: [],
      venderlist: [],
      brand_list: [],
      product_brand: 0,
      all_discount: "",
      stock_barcode: "",
      loading_text: "Please Wait",
      tem_product_list: []
    };
  }
  componentDidMount() {
    this.fetch_product_list();
    this.fetch_category();
    //this.fetch_vender_list();
    this.fetch_unit_setting();
    this.fetch_prod_brand();
    product_jquery(apiUrl, call_api);
  }

  fetch_product_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'search': '',
      'limit': 50,
      'start': 0,
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            let category_id = this.state.category_id || '';
            this.setState({ tem_product_list: resolve.data, data_status: true })
            if (category_id == '' || category_id == '0') {
              this.setState({ productlist: resolve.data })
            }
            else {
              var filter_data = resolve.data.filter(item => item.prodg_parent_id == category_id);
              this.setState({ productlist: filter_data })
            }
          }
          else {
            this.setState({ loading_text: resolve.message })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_vender_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': 3
    }
    call_api('a/fetch-vender-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ venderlist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_unit_setting() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/setting-unit', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ unit_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_category() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  save_discount_price = () => {
    let all_discount = this.state.all_discount;
    let product_id = this.state.product_id || '0';
    if (all_discount == '') {
      notify.show("Enter Item Discount", 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      let optopns = { 'product_id': product_id, 'all_discount': all_discount, 'LoginToken': localStorage.getItem('LoginToken'), };
      call_api('a/update-product-discount', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 2000);
              this.price_list({ prod_name: this.state.product_Name, product_code: this.state.product_code, product_id: product_id });
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
            this.setState({
              loading: false
            });
          },
          (reject) => {
            console.log(reject);
            notify.show("Server not responding", 'error', 2000);
            this.setState({
              loading: false
            });
          }
        )
    }
  }
  barcode_scanned_for_stock = (e) => {
    if (e.target.value != '' && e.which != 17) {
      this.setState({ stock_barcode: e.target.value });
      this.save_product_stock();
    }
  }
  save_product_stock = () => {
    let stock_barcode = this.state.stock_barcode;
    let product_id = this.state.product_id || '0';
    if (stock_barcode == '') {
      notify.show("Enter Barcode", 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      let optopns = { 'product_id': product_id, 'stock_barcode': stock_barcode, 'LoginToken': localStorage.getItem('LoginToken'), };
      call_api('a/update-product-stock', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 2000);
              this.price_list({ prod_name: this.state.product_Name, product_code: this.state.product_code, product_id: product_id });
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
            this.setState({
              loading: false
            });
          },
          (reject) => {
            console.log(reject);
            notify.show("Server not responding", 'error', 2000);
            this.setState({
              loading: false
            });
          }
        )
    }
  }
  price_list = (product) => {
    let product_id = product.product_id;
    let optopns = { 'product_id': product_id, 'LoginToken': localStorage.getItem('LoginToken'), };
    call_api('a/product-price-fetch', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({
              priceModel: true, priceListlist: resolve.data,
              product_Name: product.prod_name, loading: false,
              product_code: product.product_code, all_discount: '', stock_barcode: '',
              product_id: product_id, data_status: true, price_list_show: true
            });
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({
              priceModel: true, priceListlist: [], stock_barcode: '',
              product_Name: product.prod_name,
              product_code: product.product_code, loading: false,
              product_id: product_id, data_status: true, price_list_show: true
            });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  fetch_prod_brand() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/brand-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ brand_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  newPriceAdd = () => {
    this.setState({
      price_list_show: false, unitId: '', unit_stock: '', product_size: '',
      unitmrp: '', unitprice: '', unitdiscount: '', discountType: 'flat', p_barcode: '',
      item_weight: '', item_length: '', item_breadth: '', item_height: '', shipping_charge_type: 1,
      priceId: 0, max_order_qty: 0, headerTitle: 'Add New Item Price', shipping_charge: 0
    });
  }
  add_stock = () => {
    this.setState({
      stock_update_status: true, stock_barcode: ''
    });
  }
  newPriceEdit = (price) => {
    this.setState({
      price_list_show: false, unitId: price.unitId, unit_stock: price.unit_stock, product_size: price.product_size,
      unitmrp: price.unit_mrp, unitprice: price.unit_price, unitdiscount: price.unit_discount, p_barcode: price.p_barcode,
      item_weight: price.item_weight, item_length: price.item_length, item_breadth: price.item_breadth, item_height: price.item_height,
      discountType: price.disc_type, priceId: price.product_price_id, max_order_qty: price.max_order_qty, shipping_charge: price.shipping_charge,
      product_id: price.product_id, headerTitle: 'Update Item Price', shipping_charge_type: price.shipping_charge_type
    });
  }
  save_price = () => {
    let unitId = this.state.unitId || '';
    let unit_stock = this.state.unit_stock || '';
    let unitmrp = this.state.unitmrp || '';
    let product_size = this.state.product_size || '';
    let unitprice = this.state.unitprice || '';
    let unitdiscount = this.state.unitdiscount || '';
    let discountType = this.state.discountType || '';
    let p_barcode = this.state.p_barcode || '';
    let priceId = this.state.priceId || '0';
    let product_id = this.state.product_id || '0';
    let max_order_qty = this.state.max_order_qty || '0';
    let item_weight = this.state.item_weight;
    let item_length = this.state.item_length;
    let item_height = this.state.item_height;
    let item_breadth = this.state.item_breadth;
    let shipping_charge_type = this.state.shipping_charge_type;
    let shipping_charge = this.state.shipping_charge;
    if (unitId == '') {
      this.refs.unitId.focus();
      notify.show("Select Item Unit", 'error', 2000);
    }
    else if (unit_stock == '') {
      this.refs.unit_stock.focus();
      notify.show("Enter Item Stock", 'error', 2000);
    }
    else if (product_size == '') {
      this.refs.product_size.focus();
      notify.show("Enter Item Size", 'error', 2000);
    }
    else if (unitmrp == '') {
      this.refs.unitmrp.focus();
      notify.show("Enter Item Price", 'error', 2000);
    }
    else if (unitprice == '') {
      this.refs.unitprice.focus();
      notify.show("Enter Sale Price", 'error', 2000);
    }
    else if (unitdiscount == '') {
      this.refs.unitdiscount.focus();
      notify.show("Enter Discount", 'error', 2000);
    }
    else if (discountType == '' || discountType == 0) {
      this.refs.discountType.focus();
      notify.show("Select Discount Type", 'error', 2000);
    }
    else if (p_barcode == '') {
      this.refs.p_barcode.focus();
      notify.show("Enter Barcode", 'error', 2000);
    }
    else if (max_order_qty == '' || max_order_qty == 0) {
      this.refs.max_order_qty.focus();
      notify.show("Enter Max Order Quantity", 'error', 2000);
    }
    else if (item_weight == '') {
      notify.show("Enter Item Weight", 'error', 2000);
    }
    else if (item_length == '') {
      notify.show("Enter Item Length", 'error', 2000);
    }
    else if (item_height == '') {
      notify.show("Enter Item Height", 'error', 2000);
    }
    else if (item_breadth == '') {
      notify.show("Enter Item Breadth", 'error', 2000);
    }
    else {
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'unitId': unitId,
        'unit_stock': unit_stock,
        'unitmrp': unitmrp,
        'unitprice': unitprice,
        'product_size': product_size,
        'unitdiscount': unitdiscount,
        'discountType': discountType,
        'max_order_qty': max_order_qty,
        'shipping_charge_type': shipping_charge_type,
        'shipping_charge': shipping_charge,
        'priceId': priceId,
        'product_id': product_id,
        'item_height': item_height,
        'item_breadth': item_breadth,
        'p_barcode': p_barcode,
        'item_length': item_length,
        'item_weight': item_weight,
      }
      this.setState({ loading: true });
      call_api('a/price-save-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ price_list_show: true, loading: false, priceListlist: resolve.data, data_status: true });
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  show_image_list = (product) => {
    const image_list = product.product_images || [];
    this.setState({
      modalShow: true,
      showBtn: false,
      product_list_show: true,
      imageupload_text_message: '',
      image_list: JSON.parse(image_list),
      product_id: product.product_id,
      product_Name: product.prod_name,
      product_code: product.product_code,
    })
  }
  upload_size_chart = (product) => {
    this.setState({ alert: <SizeChart product={product} model_handler={this.close_model} /> });
  }
  close_model = (reload) => {
    this.setState({ alert: null });
    if (reload) {
      this.fetch_product_list();
    }
  }
  save_product_data() {
    let child_id = this.state.child_id;
    let prodg_parent_id = this.state.prodg_parent_id;
    let product_code = this.state.product_code;
    let product_Name = this.state.product_Name;
    let product_id = this.state.product_id;
    let sub_id = this.state.sub_id;
    let hsn_code = this.state.hsn_code;
    let vender_parent_id = this.state.vender_parent_id;
    let dataContent = this.state.dataContent;
    let product_brand = this.state.product_brand;
    let key_feature = this.state.key_feature;
    let tax_category = this.state.tax_category;
    let item_frabic = this.state.item_frabic;
    let item_pattern = this.state.item_pattern;
    let item_ocacsion = this.state.item_ocacsion;
    let item_sleeves = this.state.item_sleeves;
    let item_fiting = this.state.item_fiting;
    let item_color = this.state.item_color;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'prodg_parent_id': prodg_parent_id,
      'product_Name': product_Name,
      'product_code': product_code,
      'product_id': product_id,
      'child_id': child_id,
      'vender_parent_id': vender_parent_id,
      'tax_category': tax_category,
      'hsn_code': hsn_code,
      'sub_id': sub_id,
      'dataContent': dataContent,
      'product_brand': product_brand,
      'key_feature': key_feature,
      'key_feature': key_feature,
      'item_color': item_color,
      'item_fiting': item_fiting,
      'item_sleeves': item_sleeves,
      'item_ocacsion': item_ocacsion,
      'item_pattern': item_pattern,
      'item_frabic': item_frabic,
    }
    /*if (vender_parent_id == '0' || vender_parent_id == '') {
      alert("Please select vender");
    }
    else*/ if (product_brand == '0' || product_brand == '' || product_brand == null) {
      notify.show("Select brand", 'error', 2000);
    }
    else if (prodg_parent_id == '0' || prodg_parent_id == '' || prodg_parent_id == null) {
      notify.show("Select Main Category", 'error', 2000);
    }
    else if (sub_id == '0' || sub_id == '' || sub_id == null) {
      notify.show("Select Sub Category", 'error', 2000);
    }
    else if (child_id == '0' || child_id == '' || child_id == null) {
      notify.show("Select Child Category", 'error', 2000);
    }
    else if (tax_category == '0' || tax_category == '' || tax_category == null) {
      notify.show("Select Tax Category", 'error', 2000);
    }
    else if (item_color == '0' || item_color == '' || item_color == null) {
      notify.show("Select Color", 'error', 2000);
    }
    else if (item_fiting == '0' || item_fiting == '' || item_fiting == null) {
      notify.show("Select Fiting", 'error', 2000);
    }
    else if (item_sleeves == '0' || item_sleeves == '' || item_sleeves == null) {
      notify.show("Select Sleeves", 'error', 2000);
    }
    else if (item_ocacsion == '0' || item_ocacsion == '' || item_ocacsion == null) {
      notify.show("Select Occasion", 'error', 2000);
    }
    else if (item_pattern == '0' || item_pattern == '' || item_pattern == null) {
      notify.show("Select Pattern", 'error', 2000);
    }
    else if (item_frabic == '0' || item_frabic == '' || item_frabic == null) {
      notify.show("Select Frabic", 'error', 2000);
    }
    else if (product_Name == '') {
      notify.show("Item Name Can't be empty", 'error', 2000);
    }
    else if (product_code == '') {
      notify.show("Enter SKU Code", 'error', 2000);
    } else if (hsn_code == '') {
      notify.show("Enter HSN Code", 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      call_api('a/product-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ editModel: false, loading: false, data_status: true });
              notify.show(resolve.message, 'success', 2000);
              this.fetch_product_list();
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  save_new_product_data() {
    let child_id = this.state.child_id;
    let prodg_parent_id = this.state.prodg_parent_id;
    let product_Name = this.state.product_Name;
    let product_code = this.state.product_code;
    let sub_id = this.state.sub_id;
    let vender_parent_id = this.state.vender_parent_id;
    let hsn_code = this.state.hsn_code;
    let dataContent = this.state.dataContent;
    let product_brand = this.state.product_brand;
    let key_feature = this.state.key_feature;
    let tax_category = this.state.tax_category;
    let item_frabic = this.state.item_frabic;
    let item_pattern = this.state.item_pattern;
    let item_ocacsion = this.state.item_ocacsion;
    let item_sleeves = this.state.item_sleeves;
    let item_fiting = this.state.item_fiting;
    let item_color = this.state.item_color;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'prodg_parent_id': prodg_parent_id,
      'product_Name': product_Name,
      'product_code': product_code,
      'child_id': child_id,
      'vender_parent_id': vender_parent_id,
      'hsn_code': hsn_code,
      'sub_id': sub_id,
      'dataContent': dataContent,
      'tax_category': tax_category,
      'product_brand': product_brand,
      'key_feature': key_feature,
      'item_color': item_color,
      'item_fiting': item_fiting,
      'item_sleeves': item_sleeves,
      'item_ocacsion': item_ocacsion,
      'item_pattern': item_pattern,
      'item_frabic': item_frabic,
    }
    /* if (vender_parent_id == '0' || vender_parent_id == '0') {
      alert("Please select vender");
    } */
    if (product_brand == '0' || product_brand == '' || product_brand == null) {
      notify.show("Select brand", 'error', 2000);
    }
    else if (prodg_parent_id == '0' || prodg_parent_id == '' || prodg_parent_id == null) {
      notify.show("Select Main Category", 'error', 2000);
    }
    else if (sub_id == '0' || sub_id == '' || sub_id == null) {
      notify.show("Select Sub Category", 'error', 2000);
    }
    else if (child_id == '0' || child_id == '' || child_id == null) {
      notify.show("Select Child Category", 'error', 2000);
    }
    else if (tax_category == '0' || tax_category == '' || tax_category == null) {
      notify.show("Select Tax Category", 'error', 2000);
    }
    else if (item_color == '0' || item_color == '' || item_color == null) {
      notify.show("Select Color", 'error', 2000);
    }
    else if (item_fiting == '0' || item_fiting == '' || item_fiting == null) {
      notify.show("Select Fiting", 'error', 2000);
    }
    else if (item_sleeves == '0' || item_sleeves == '' || item_sleeves == null) {
      notify.show("Select Sleeves", 'error', 2000);
    }
    else if (item_ocacsion == '0' || item_ocacsion == '' || item_ocacsion == null) {
      notify.show("Select Occasion", 'error', 2000);
    }
    else if (item_pattern == '0' || item_pattern == '' || item_pattern == null) {
      notify.show("Select Pattern", 'error', 2000);
    }
    else if (item_frabic == '0' || item_frabic == '' || item_frabic == null) {
      notify.show("Select Frabic", 'error', 2000);
    }
    else if (product_Name == '') {
      notify.show("Item Name Can't be empty", 'error', 2000);
    }
    else if (product_code == '') {
      notify.show("Enter SKU Code", 'error', 2000);
    }
    else if (hsn_code == '') {
      notify.show("Enter HSN Code", 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      call_api('a/product-save', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ editModel: false, loading: false, data_status: true });
              notify.show(resolve.message, 'success', 2000);
              this.fetch_product_list();
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  editData = (list) => {
    this.setState({
      product_Name: list.prod_name, prodg_parent_id: list.prodg_parent_id, hsn_code: list.hsn_code,
      product_code: list.product_code, child_id: list.child_id, product_id: list.product_id, sub_id: list.sub_id, dataContent: list.description,
      product_brand: list.product_brand, tax_category: list.tax_category, item_fiting: list.item_fiting, item_color: list.item_color,
      item_sleeves: list.item_sleeves, item_ocacsion: list.item_ocacsion, item_pattern: list.item_pattern, item_frabic: list.item_frabic,
      vender_parent_id: list.venderId, editModel: true, modelHeader: 'Edit Item', key_feature: list.key_feature
    })
  }
  upload_new_product_image = () => {
    this.setState({ product_list_show: false, imageupload_text_message: '', showBtn: false, new_image_list: [], uploadSuccess: false })
  }
  deleteProductImage = (imageId) => {
    this.setState({ loading: true })
    let product_id = this.state.product_id;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'product_id': product_id,
      'ImageIdkey': imageId,
    }
    call_api('a/product-image-delete', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            this.setState({ loading: false });
            const items = this.state.tem_product_list.filter(item => item.product_id == this.state.product_id ?
              item['product_images'] = resolve.image : item['product_images'] = item['product_images']);
            const items2 = this.state.productlist.filter(item => item.product_id == this.state.product_id ?
              item['product_images'] = resolve.image : item['product_images'] = item['product_images']);
            this.setState({ tem_product_list: items, productlist: items2, image_list: JSON.parse(resolve.image || []) });
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  chnageImageStatus = (imageId, status, KeyType) => {
    let product_id = this.state.product_id;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'product_id': product_id,
      'KeyType': KeyType,
      'ImageIdkey': imageId,
      'status': status,
    }
    call_api('a/product-image-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            const items = this.state.tem_product_list.filter(item => item.product_id == this.state.product_id ?
              item['product_images'] = resolve.image : item['product_images'] = item['product_images']);
            const items2 = this.state.productlist.filter(item => item.product_id == this.state.product_id ?
              item['product_images'] = resolve.image : item['product_images'] = item['product_images']);
            this.setState({ tem_product_list: items, productlist: items2, image_list: JSON.parse(resolve.image || []) });
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  pricechangeStatus = (priceId, productId, key, status) => {
    let optopns = { 'status': status, 'key': key, 'priceId': priceId, 'productId': productId, 'LoginToken': localStorage.getItem('LoginToken') };
    call_api('a/price-change-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ priceModel: true, priceListlist: resolve.data });
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  product_export = () => {
    let optopns = { 'LoginToken': localStorage.getItem('LoginToken') };
    call_api('a/product-export', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  add_product_mdl_display() {
    this.setState({
      mainCatId: 0, vender_parent_id: 0, product_Name: '', hsn_code: '', product_id: 0, sub_id: 0,
      prodg_parent_id: 0, child_id: 0, product_brand: 0, editModel: true, modelHeader: 'Add New Item', product_code: '',
      key_feature: '', tax_category: '', item_fiting: '', item_color: '',
      item_sleeves: '', item_ocacsion: '', item_pattern: '', item_frabic: '',
    });
  }

  async changePriveImage(target) {
    this.setState({ imageupload_text_message: '', new_image_list: [], showBtn: false });
    var new_image_list = [];
    await Promise.all([...target.files].map((image) => {
      if (image != null) {
        if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg" || image.type == "image/webp") {
          var reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            new_image_list.push(reader.result);
            this.setState({ showBtn: true, new_image_list: new_image_list, imageupload_text_message: '' });
          };
        }
      }
    })
    )
    if (new_image_list) {
      //this.setState({ showBtn: true, new_image_list: new_image_list, imageupload_text_message: '' });
    }
    else {
      //this.setState({ imageupload_text_message: 'invalid image selection' });
    }
  }

  save_upload_image() {
    let new_image_list = this.state.new_image_list;
    if (new_image_list) {
      this.setState({ loading: true, new_image_list: [] });
      let optopns = { 'images': new_image_list, 'product_id': this.state.product_id };
      call_api('a/product-image-upload', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ showBtn: false, new_image_list: [], imageupload_text_message: resolve.message, product_list_show: true, loading: false, image_list: JSON.parse(resolve.image || []) });
              const items = this.state.tem_product_list.filter(item => item.product_id == this.state.product_id ?
                item['product_images'] = resolve.image : item['product_images'] = item['product_images']);
              this.setState({ tem_product_list: items });
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              this.setState({ imageupload_text_message: resolve.message, loading: false });
            }
          },
          (reject) => {
            console.log(reject);
            this.setState({ loading: false });
          }
        )
    }
    else {
      this.setState({ showBtn: false });
    }
  }

  filter_list_data = (type, value) => {
    let list = this.state.tem_product_list;
    if (type == 'category_id') {
      var filter_data = list.filter(item => item.prodg_parent_id === value);
    }
    else if (type == 'subcategory_id') {
      var filter_data = list.filter(item => item.sub_id === value);
    }
    else if (type == 'search_product_name') {
      if (value == '') {
        var filter_data = list;
      }
      else {
        var filter_data = list.filter((item) =>
          (item.prod_name.toLowerCase()).includes((value.toLowerCase())) ||
          (item.product_code.toLowerCase()).includes((value.toLowerCase())) ||
          (item.hsn_code.toLowerCase()).includes((value.toLowerCase()))
        );
      }
    }
    else {
    }
    this.setState({
      productlist: filter_data
    })
  }
  deleteproduct = (productId) => {
    this.setState({ loading: true })
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        onCancel={() => { this.setState({ alert: null, loading: false }); }}
        onConfirm={() => { this.conformDeleteProduct(productId) }} >
        You will not be able to recover this imaginary file,and also deleted related product price and all images
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });
  }
  conformDeleteProduct = (productId) => {
    this.setState({ alert: null });
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'productId': productId
    }
    call_api('a/product-delete', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            let tem_product_list = this.state.tem_product_list || [];
            let productlist = this.state.productlist || [];
            this.setState({ loading: false });
            var filter_data = tem_product_list.filter(item => item.product_id !== productId);
            var filter_data2 = productlist.filter(item => item.product_id !== productId);
            this.setState({ productlist: filter_data2, tem_product_list: filter_data });
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  chnage_sequence = (e, product_id) => {
    let value = e.target.value;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'product_id': product_id, 'value': value
    };
    call_api('a/change-product-sequence', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            let key = 'sequence';
            const items = this.state.productlist.filter(item => item.product_id == atob(product_id) ?
              item[key] = value : item[key] = item[key]);
            const items2 = this.state.tem_product_list.filter(item => item.product_id == atob(product_id) ?
              item[key] = value : item[key] = item[key]);
            this.setState({ productlist: items, tem_product_list: items2 });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  product_import = () => {
    this.setState({ import_mdl: true });
  }
  handleCallback = () => {
    this.setState({ import_mdl: false })
  }
  render() {
    const { data_status, categorylist, productlist, import_mdl, brand_list, new_image_list } = this.state;
    return (
      <>
        {this.state.alert}
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
          <Product_Import_Mdl model_status={import_mdl} parentCallback={this.handleCallback} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Inventory Item </h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <Button type="button" onClick={() => this.product_export()} className="btn btn-sm btn-success mr-2">Export</Button>
                  <Button type="button" onClick={() => this.product_import()} className="btn btn-sm btn-warning mr-2">Import</Button>
                  <Button type="button" onClick={() => this.add_product_mdl_display()} className="ml-2 btn btn-sm btn-info">Add New Item</Button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-lg-3 col-sm-12 col-sm-3 col-md-3">
                          <div className="form-group">
                            <label className="control-label">Category :</label>
                            <select name="category_id" value={this.state.category_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('category_id', e.target.value) }} className="form-control" id="category_id">
                              <option key={"c00"} value="">Select Category</option>
                              {categorylist.map((cats, index) => {
                                if (cats.main_group_flag == 1) {
                                  return (
                                    <option key={"mc_" + index} value={cats.pg_id1}>{cats.pg_name1}</option>
                                  )
                                }
                              })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 col-sm-4 col-md-3">
                          <div className="form-group">
                            <label className="control-label">Sub Category </label>
                            <select ref="subcategory_id" name="subcategory_id" value={this.state.subcategory_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('subcategory_id', e.target.value) }} className="form-control" id="subcategory_id">
                              <option key={"s00"}>Select Sub Category</option>
                              {categorylist.map((scat, index) => {
                                if (scat.main_group_flag == 2 && scat.pg_parent_id == this.state.category_id) {
                                  return (
                                    <option key={"sc_" + index} value={scat.pg_id1}>{scat.pg_name1}</option>
                                  )
                                }
                              })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 col-sm-3 col-md-3">
                          <div className="form-group">
                            <label className="control-label" htmlFor="search_product_name">Item Name/HSN/Style Code</label>
                            <input type="text" name="search_product_name" value={this.state.search_product_name || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('search_product_name', e.target.value) }} className="form-control" id="search_product_name" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th width="30%">Item Info</th>
                              <th>Category</th>
                              <th>Sequence</th>
                              {/* <th>Vender</th> */}
                              <th>Status</th>
                              <th>Verified</th>
                              <th>Size Chart</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                productlist.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div className="text-left" style={{ display: "flex" }}>
                                          <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                            {onload => (
                                              <img alt={list.prod_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={apiUrl + list.pro_image} onLoad={onload} />
                                            )}
                                          </FadeIn>
                                          <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                            <p className="text-info">{list.prod_name.slice(0, 40)}</p>
                                            <p>HSN Code : {list.hsn_code || '---'}</p>
                                            <p>SKU Id : {list.product_code || '---'}</p>
                                            <p>Brand : {list.brand_name || '---'}</p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{list.product_group}{'->'}{list.sub_id != 0 ? list.sub_group_name : '--'} {list.child_id != 0 ? ('->' + list.child_group_name) : ''}</td>
                                      <td><input className="form-control" style={{ width: '80px' }} value={list.sequence} min={0} onChange={(e) => this.chnage_sequence(e, btoa(list.product_id))} type="number" /></td>
                                      {/* <td>{list.vender_name}</td> */}
                                      <td>{list.active_status == 1 ? <button className="btn btn-success btn-sm productStatus" id={"pro_s_" + list.product_id} data-product-id={list.product_id} data-product-status="2" data-product-label="active_status">Active</button> : <button className="btn btn-danger btn-sm productStatus" id={"pro_s_" + list.product_id} data-product-id={list.product_id} data-product-status="1" data-product-label="active_status">Inactive</button>}</td>
                                      <td>{list.verified == 1 ? <button className="btn btn-success btn-sm productStatus" id={"pro_v_" + list.product_id} data-product-id={list.product_id} data-product-status="2" data-product-label="verified">Yes</button> : <button className="btn btn-danger btn-sm productStatus" data-product-label="verified" id={"pro_v_" + list.product_id} data-product-id={list.product_id} data-product-status="1">No</button>}</td>
                                      <td>
                                        <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img onClick={() => this.upload_size_chart(list)} alt={list.prod_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={apiUrl + list.size_chart} onLoad={onload} />
                                          )}
                                        </FadeIn>
                                      </td>
                                      <td>
                                        <button className="btn-sm btn-warning mr-2" onClick={() => this.editData(list, 'edit')}><i className="fa fa-pencil-square-o"></i></button>
                                        <button className="btn-sm btn-success mr-2" onClick={() => this.price_list(list)}><i className="fa fa-inr"></i></button>
                                        <button className="btn-sm btn-info mr-2" onClick={() => this.show_image_list(list)}><i className="fa fa-file-image-o"></i></button>
                                        <button className="btn-danger btn-sm" onClick={() => this.deleteproduct(list.product_id)} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  <i className="fa fa-trash-o"></i></button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="8" align="center">{this.state.loading_text}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" fade={false} isOpen={this.state.priceModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ priceModel: false })}>X</button>}>
            <CardText className="modal-title">
              Price List
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="card">
                  <div className='card-body'>
                    <div className='row'>
                      <div className="col-sm-4">
                        <div>
                          <label className="control-label">Item Name :</label>
                          <small>{this.state.product_Name || ''}</small>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div>
                          <label className="control-label">Style Code :</label>
                          <small>{this.state.product_code || ''}</small>
                        </div>
                      </div>
                      {this.state.stock_update_status == false ?
                        <div className="col-sm-4">
                          <div className="form-group">
                            <div className="input-group">
                              <label htmlFor="all_discount" className="control-label mr-2 mt-auto"><span className="text-danger">*</span>Discount</label>
                              <input className="form-control" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1'); }} ref="all_discount" name="all_discount" value={this.state.all_discount || ""} id="all_discount" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                              <div className="input-group-append">
                                <button className="btn btn-success btn-sm" disabled={this.state.loading} onClick={() => this.save_discount_price()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="col-sm-5">
                          <div className="form-group">
                            <div className="input-group">
                              <label htmlFor="stock_barcode" className="control-label mr-2 mt-auto"><span className="text-danger">*</span>Enter Barcode</label>
                              <input className="form-control" onKeyUp={(e) => this.barcode_scanned_for_stock(e)} value={this.state.stock_barcode} name="stock_barcode" id="stock_barcode" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                              <div className="input-group-append">
                                <button className="btn btn-success btn-sm" disabled={true} onClick={() => this.save_product_stock()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => this.setState({ stock_update_status: false })}>Cancel</button>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 col-sm-12 col-md-12 mt-2">
                {
                  (this.state.price_list_show == true) ?
                    <>
                      <button className="btn btn-success btn-sm" onClick={() => this.newPriceAdd()}>New Price</button>
                      {this.state.stock_update_status == false ? <button className="btn btn-info btn-sm ml-2" onClick={() => this.add_stock()}>Add Stock</button> : <></>}
                      <div className="table-responsive mt-2">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              {/* <th>Price Id</th> */}
                              <th>Barcode</th>
                              <th>Unit</th>
                              <th>Size</th>
                              <th>Stock Qty</th>
                              <th>Item Price</th>
                              <th>Discount</th>
                              <th>Type</th>
                              <th>Sale Price</th>
                              <th>Shipping Charge</th>
                              <th>Sequence</th>
                              <th>Weight</th>
                              <th>Length</th>
                              <th>Height</th>
                              <th>Breadth</th>
                              <th>Status</th>
                              <th>Verify</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.priceListlist.map((list, index) => {
                              return (
                                <tr key={"price" + index}>
                                  {/* <td>{list.product_price_id}</td> */}
                                  <td>{list.p_barcode || '--'}</td>
                                  <td>{list.unit}</td>
                                  <td>{list.product_size}</td>
                                  <td>{list.unit_stock}</td>
                                  <td>{list.unit_mrp}</td>
                                  <td>{list.unit_discount}</td>
                                  <td>{list.disc_type}</td>
                                  <td>{list.unit_price}</td>
                                  <td>
                                    {list.shipping_charge_type == 1 ? 'Auto' : list.shipping_charge}
                                  </td>
                                  <td>{list.sequence}</td>
                                  <td>{list.item_weight}KG</td>
                                  <td>{list.item_length}Cms</td>
                                  <td>{list.item_height}Cms</td>
                                  <td>{list.item_breadth}Cms</td>
                                  <td>{list.active_status == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.pricechangeStatus(list.product_price_id, list.product_id, 'active_status', 2)}>Active</button> : <button className="btn btn-danger btn-sm" onClick={(e) => this.pricechangeStatus(list.product_price_id, list.product_id, 'active_status', 1)}>Inactive</button>}</td>
                                  <td>{list.verified == 1 ? <button className="btn btn-success btn-sm" onClick={(e) => this.pricechangeStatus(list.product_price_id, list.product_id, 'verified', 2)}>Yes</button> : <button className="btn btn-danger btn-sm" onClick={(e) => this.pricechangeStatus(list.product_price_id, list.product_id, 'verified', 1)}>No</button>}</td>
                                  <td><button className="btn btn-danger btn-sm" onClick={(e) => this.newPriceEdit(list)}><i className="fa fa-pencil-square-o"></i></button></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                    :
                    <>
                      <h4 className="mb-2">{this.state.headerTitle}</h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="unitId" className="control-label"><span className="text-danger">*</span>Unit</label>
                            <select className="form-control" ref="unitId" name="unitId" value={this.state.unitId || ""} id="unitId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                              <option value="">Select Unit</option>
                              {this.state.unit_list.map((list, index) => {
                                return (
                                  <option key={index} value={list.id}>{list.unit_name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="product_size" className="control-label"><span className="text-danger">*</span>Size</label>
                            <input className="form-control" ref="product_size" name="product_size" value={this.state.product_size || ""} id="product_size" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="unit_stock" className="control-label"><span className="text-danger">*</span>Stock Qty</label>
                            <input className="form-control" ref="unit_stock" name="unit_stock" value={this.state.unit_stock || ""} id="unit_stock" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="unitmrp" className="control-label"><span className="text-danger">*</span>Unit MRP</label>
                            <input className="form-control" ref="unitmrp" name="unitmrp" value={this.state.unitmrp || ""} id="unitmrp" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="unitprice" className="control-label"><span className="text-danger">*</span>Unit Price</label>
                            <input className="form-control" readOnly disabled={true} ref="unitprice" name="unitprice" value={this.state.unitprice || ""} id="unitprice" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="unitdiscount" className="control-label"><span className="text-danger">*</span>Discount</label>
                            <input className="form-control" ref="unitdiscount" name="unitdiscount" value={this.state.unitdiscount || ""} id="unitdiscount" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="discountType" className="control-label"><span className="text-danger">*</span>Discount Type</label>
                            <select className="form-control" ref="discountType" name="discountType" value={this.state.discountType || ""} id="discountType" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                              <option value="percent">Percent</option>
                              <option value="flat">Flat</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="shipping_charge_type" className="control-label"><span className="text-danger">*</span>Shipping Charge Type</label>
                            <select className="form-control" ref="shipping_charge_type" name="shipping_charge_type" value={this.state.shipping_charge_type || ""} id="shipping_charge_type" onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                              <option value="1">Auto</option>
                              <option value="2">Manual</option>
                            </select>
                          </div>
                        </div>
                        {this.state.shipping_charge_type == 2 && <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="shipping_charge" className="control-label"><span className="text-danger">*</span>Shipping Charge</label>
                            <input type="text" name="shipping_charge" value={this.state.shipping_charge} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="shipping_charge" />
                          </div>
                        </div>}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="p_barcode" className="control-label"><span className="text-danger">*</span>Barcode</label>
                            <input type="text" name="p_barcode" value={this.state.p_barcode} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="p_barcode" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="max_order_qty" className="control-label"><span className="text-danger">*</span>Max Order Qty</label>
                            <input type="number" min={0} className="form-control" ref="max_order_qty" name="max_order_qty" value={this.state.max_order_qty || "0"} id="max_order_qty" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="item_weight" className="control-label"><span className="text-danger">*</span>Item Weight(In KG)</label>
                            <input type="text" name="item_weight" value={this.state.item_weight} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_weight" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="item_length" className="control-label"><span className="text-danger">*</span>Item Length(In CMS)</label>
                            <input type="text" name="item_length" value={this.state.item_length} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_length" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="item_breadth" className="control-label"><span className="text-danger">*</span>Item Breadth(In CMS)</label>
                            <input type="text" name="item_breadth" value={this.state.item_breadth} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_breadth" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="item_height" className="control-label"><span className="text-danger">*</span>Item Height(In CMS)</label>
                            <input type="text" name="item_height" value={this.state.item_height} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="item_height" />
                          </div>
                        </div>
                        <div className="col-sm-12 text-center">
                          <button className="btn btn-sm btn-success mr-2" disabled={this.state.loading} onClick={() => this.save_price()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Save</button>
                          <button className="btn btn-sm btn-danger" onClick={(e) => this.setState({ price_list_show: true })}>Cancel</button>
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div></div>
          </ModalFooter>
        </Modal>
        <Modal size="lg" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              {/*<div className="col-sm-6">
                 <div className="form-group">
                  <label htmlFor="vender_parent_id" className="control-label"><span className="text-danger">*</span>Item Vender</label>
                    <select name="vender_parent_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.vender_parent_id} className="form-control">
                      <option value="0" key={"v11"}>Select Vender</option>
                      {this.state.venderlist.map((list, index) => {
                        return (
                          <option key={"v_" + index} value={list.id}>{list.full_name}</option>
                        )
                      })
                      }
                    </select>
                  </div>
                </div> */}
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="product_brand" className="control-label"><span className="text-danger">*</span>Item Brand</label>
                  <select name="product_brand" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.product_brand} className="form-control">
                    <option value="0" key={"b11"}>Select Brand</option>
                    {this.state.brand_list.map((list, index) => {
                      return (
                        <option key={"b_" + index} value={list.id}>{list.brand_name}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="prodg_parent_id" className="control-label"><span className="text-danger">*</span>Main Category</label>
                  <select name="prodg_parent_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.prodg_parent_id} className="form-control">
                    <option value="0" key={"m11"}>Select Main Category</option>
                    {categorylist.map((list, index) => {
                      if (list.main_group_flag == 1) {
                        return (
                          <option key={"m_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                        )
                      }
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="sub_id" className="control-label"><span className="text-danger">*</span>Sub Category</label>
                  <select name="sub_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.sub_id} className="form-control">
                    <option value="0" key={"s11"}>Select Sub Category</option>
                    {categorylist.map((list, index) => {
                      if (list.main_group_flag == 2 && list.pg_parent_id == this.state.prodg_parent_id) {
                        return (
                          <option key={"s_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                        )
                      }
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="child_id" className="control-label"><span className="text-danger">*</span>Child Category</label>
                  <select name="child_id" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.child_id} className="form-control">
                    <option value="0" key={"s11"}>Select Child Category</option>
                    {categorylist.map((list, index) => {
                      if (list.main_group_flag == 3 && list.pg_parent_id == this.state.sub_id) {
                        return (
                          <option key={"s_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                        )
                      }
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="tax_category" className="control-label"><span className="text-danger">*</span>Tax Category</label>
                  <select name="tax_category" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.tax_category} className="form-control">
                    <option value="0">Select Tax Category</option>
                    <option value="5">5%</option>
                    <option value="12">12%</option>
                    <option value="18">18%</option>
                    <option value="28">28%</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="product_Name" className="control-label"><span className="text-danger">*</span>Item Name</label>
                  <input type="text" name="product_Name" value={this.state.product_Name} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="product_Name" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="product_code" className="control-label"><span className="text-danger">*</span>SKU Code</label>
                  <input type="text" name="product_code" value={this.state.product_code} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="product_code" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="hsn_code" className="control-label"><span className="text-danger">*</span>HSN Code</label>
                  <input type="text" name="hsn_code" value={this.state.hsn_code} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="hsn_code" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_color" className="control-label"><span className="text-danger">*</span>Select Color</label>
                  <select name="item_color" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.item_color || 0} className="form-control">
                    <option value="0" key={"c11"}>Select Color</option>
                    {color_list().map((list, index) => {
                      return (
                        <option key={"c_" + index} value={list.id}>{list.id}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_fiting" className="control-label"><span className="text-danger">*</span>Select Fiting</label>
                  <select name="item_fiting" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.item_fiting || 0} className="form-control">
                    <option value="0" key={"f11"}>Select Fiting</option>
                    {fit_list().map((list, index) => {
                      return (
                        <option key={"f_" + index} value={list.id}>{list.id}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_sleeves" className="control-label"><span className="text-danger">*</span>Select Sleeves</label>
                  <select name="item_sleeves" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.item_sleeves || 0} className="form-control">
                    <option value="0" key={"s_11"}>Select Sleeves</option>
                    {sleeves_list().map((list, index) => {
                      return (
                        <option key={"s_" + index} value={list.id}>{list.id}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_ocacsion" className="control-label"><span className="text-danger">*</span>Select Occasion</label>
                  <select name="item_ocacsion" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.item_ocacsion || 0} className="form-control">
                    <option value="0" key={"o_11"}>Select Occasion</option>
                    {ocacsion_list().map((list, index) => {
                      return (
                        <option key={"o_" + index} value={list.id}>{list.id}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_pattern" className="control-label"><span className="text-danger">*</span>Select Pattern</label>
                  <select name="item_pattern" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.item_pattern || 0} className="form-control">
                    <option value="0" key={"p_11"}>Select Pattern</option>
                    {pattern_List().map((list, index) => {
                      return (
                        <option key={"p_" + index} value={list.id}>{list.id}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="item_frabic" className="control-label"><span className="text-danger">*</span>Select Frabic</label>
                  <select name="item_frabic" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.item_frabic || 0} className="form-control">
                    <option value="0" key={"f_11"}>Select Frabic</option>
                    {frabic_List().map((list, index) => {
                      return (
                        <option key={"f_" + index} value={list.id}>{list.id}</option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <label htmlFor="key_feature" className="control-label">Features & Details</label>
                <textarea name="key_feature" value={this.state.key_feature} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="key_feature"></textarea>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="product_description" className="control-label"><span className="text-danger">*</span>Item Description</label>
                  <SunEditor onChange={(event) => {
                    this.setState({ dataContent: event });
                  }} onBlur={(event) => {
                    this.setState({ dataContent: event.target.innerHTML });
                  }} defaultValue={this.state.dataContent}
                    setOptions={{
                      height: 300,
                      buttonList: [['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                      ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                      ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat']],
                      callBackSave: function (contents, isChanged) {
                        console.log(contents, isChanged);
                      }
                    }} />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.product_id != 0 ? <Button type="button" disabled={this.state.loading} onClick={() => this.save_product_data()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</Button>
              : <Button type="button" disabled={this.state.loading} onClick={() => this.save_new_product_data()} className="btn btn-sm btn-danger"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>}
          </ModalFooter>
        </Modal>
        <Modal size="lg" fade={false} isOpen={this.state.modalShow} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ ProductActionModel: false, modalShow: false })}>X</button>}>
            <CardText className="modal-title">
              Product Image
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                  <div className="card">
                    <div className='card-body'>
                      <div className='row'>
                        <div className="col-sm-6">
                          <div>
                            <label className="control-label">Item Name :</label>
                            <span>{this.state.product_Name || ''}</span>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div>
                            <label className="control-label">Style Code :</label>
                            <span>{this.state.product_code || ''}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  {this.state.product_list_show == true ?
                    <>
                      <button className="btn btn-info btn-sm" onClick={() => this.upload_new_product_image()}>Upload Product Image</button>
                      <hr />
                      <div className="table-wrap mt-2">
                        <div className="table-responsive">
                          <table className="table mb-0 text-center">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Image</th>
                                <th>Status</th>
                                <th>Default</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.image_list.map((file, index) => {
                                let image_path = apiUrl + file.images;
                                return (
                                  <tr key={"pimg" + index}>
                                    <td>{index + 1}</td>
                                    <td><FadeIn height={50} duration={50} easing={'ease-out'}>
                                      {onload => (
                                        <img alt={"Product Image"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" height="90px" width="90px" src={image_path} onLoad={onload} style={{ cursor: 'pointer' }} />
                                      )}
                                    </FadeIn></td>
                                    <td>{file.status == 1 ? <button className="btn btn-success btn-xs" onClick={() => this.chnageImageStatus(file.id, 0, 'status')}>Active</button> : <button className="btn btn-danger btn-xs" onClick={() => this.chnageImageStatus(file.id, 1, 'status')}>Inactive</button>}</td>
                                    <td>{file.isDefault == 1 ? <button className="btn btn-success btn-xs"><i className="fa fa-check"></i></button> : <button className="btn btn-danger btn-xs" onClick={() => this.chnageImageStatus(file.id, 1, 'isDefault')}><i className="fa fa-times"></i></button>}</td>
                                    <td><button className="btn btn-xs btn-danger" onClick={() => this.deleteProductImage(file.id)}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Delete</button></td>
                                  </tr>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                    :
                    <div className="row">
                      <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                        <div className="text-center">
                          <center>
                            <input type="file" ref="product_file" accept="images/*" multiple name="cat_image" onChange={(e) => this.changePriveImage(e.target)} />
                          </center>
                          <br />
                          {new_image_list ?
                            <div className="row">
                              {new_image_list.map((item, index) => {
                                return (
                                  <div className="col-sm-3 mt-2" key={"n_i" + index}>
                                    <FadeIn item_height={50} duration={50} easing={'ease-out'}>
                                      {onload => (
                                        <img alt={"Item Image"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" src={item} onLoad={onload} style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '200px' }} />
                                      )}
                                    </FadeIn>
                                  </div>
                                )
                              })}
                            </div>
                            : <></>}
                          <h5>{this.state.imageupload_text_message || ''}</h5>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="text-center">
              {this.state.showBtn == true ? <Button type="button" disabled={this.state.loading} onClick={() => this.save_upload_image()} className="btn btn-info btn-sm mr-2"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Upload</Button> : ''}
              {(this.state.product_list_show == false) ? <Button type="button" onClick={() => this.setState({ product_list_show: true, imageupload_text_message: '', showBtn: false })} className="btn btn-danger btn-sm">Cancel</Button> : ''}
              {this.state.uploadSuccess == true ? <Button type="button" onClick={() => this.setState({ product_list_show: true, imageupload_text_message: '', showBtn: false })} className="btn btn-success btn-sm">Ok</Button> : ''}
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default Product_list;
