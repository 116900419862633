import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import FadeIn from "react-lazyload-fadein";
import notfoundImg from "../../assets/img/no_image.jpg";
import Notifications, { notify } from 'react-notify-toast';
class Web_page_setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      data_status: false,
      editModel: false,
      editId: 0,
      dataContent: "",
      datalist: [],
    };
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(content) {
    this.setState({ dataContent: content });
  }
  componentDidMount() {

    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/setting-page', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ datalist: resolve.data, loading: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  dataEdit = (dataContent, dataId) => {
    this.setState({ editModel: true, editId: dataId, dataContent: dataContent });
  }
  viewImage = (image_url, dataId) => {

  }
  save_page_content = () => {
    let dataContent = this.state.dataContent;
    let dataId = this.state.editId;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'dataContent': dataContent,
      'dataId': dataId
    }
    this.setState({ loading: true });
    call_api('a/setting-page-update', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ loading: false, editModel: false });
            notify.show(resolve.message, 'success', 2000);
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  render() {
    const { error, loading, data_status, datalist } = this.state;

    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Web Setting</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Page Name</th>
                              {/* <th>Header Image</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                datalist.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.page_title}</td>
                                      {/* <td>
                                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img alt={list.page_title} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" src={apiUrl + 'images/upload_slider/' + list.headerImage} onClick={() => this.viewImage(apiUrl + 'images/upload_slider/' + list.headerImage, list.page_id)} onLoad={onload} style={{ height: 80, cursor: 'pointer' }} />
                                          )}
                                        </FadeIn>
                                      </td> */}
                                      <td>
                                        <button className="btn btn-sm btn-info veiw_update_page_details" onClick={() => this.dataEdit(list.page_content, list.page_id)}>Edit</button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="3" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              Edit
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 mb-10">
                <SunEditor onChange={(event) => {
                  this.setState({ dataContent: event });
                }} onBlur={(event) => {
                  this.setState({ dataContent: event.target.innerHTML });
                }} defaultValue={this.state.dataContent}
                  setOptions={{
                    height: 200,
                    buttonList: [['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat']],
                    callBackSave: function (contents, isChanged) {
                      console.log(contents, isChanged);
                    }
                  }} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="mt-15 mr-20">
              <br />
              <Button type="button" disabled={this.state.loading} onClick={() => this.save_page_content()} className="btn btn-danger btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default Web_page_setting;
