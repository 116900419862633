import React, { Component } from 'react';
import VendorModel from '../../components/master/VendorModel.js';
import { call_api, apiUrl } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import Moment from 'react-moment';
import { vender_master_logistic_jquery } from "../../components/jquery/Web_jquery.js";
class Logistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      modalShow: false,
      actionType: 0,
      headerTitle: '',
      UserId: 0,
      vender_list: [],
    };
  }
  componentDidMount() {
    vender_master_logistic_jquery(apiUrl, call_api);
    this.fetch_logistics_list();
  }
  fetch_logistics_list = () => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken')
    }
    call_api('a/logistics-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ vender_list: resolve.data, tem_ven_list: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  selectAction = (userId, actionType, headerTitle) => {
    this.setState({ modalShow: true, actionType: actionType, UserId: userId, headerTitle: headerTitle });

  }
  add_new_logistics = () => {
    this.setState({ modalShow: true, actionType: 5, headerTitle: 'Add New Logistics' });

  }
  filter_list_data = (search) => {
    let list = this.state.tem_ven_list;
    if (search == '') {
      var filter_data = list;
    }
    else {
      var filter_data = list.filter(item => (item.full_name.toLowerCase()).includes((search.toLowerCase())) ||
        (item.email_identity.toLowerCase()).includes((search.toLowerCase())) ||
        (item.reg_Id.toLowerCase()).includes((search.toLowerCase())) ||
        (item.contact_details.toLowerCase()).includes((search.toLowerCase())));
    }
    this.setState({
      vender_list: filter_data
    })
  }
  handleCallback = (status) => {
    if (status == true) {
      this.fetch_logistics_list();
      this.setState({ modalShow: false });
      this.filter_list_data(this.state.search_logistics || '');
    }
  }
  render() {
    const { data_status, vender_list, modalShow, actionType, UserId, headerTitle } = this.state;
    return (
      <div>
        <VendorModel parentCallback={this.handleCallback} displayPopup={modalShow} modelType={actionType} modelId={UserId} headerTitle={headerTitle} />
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
        <div className="page-wrapper" style={{ minHeight: "359px" }}>
          <div className="container-fluid">
            <div className="row heading-bg">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 className="txt-dark">Logictics Report</h5>
              </div>
              <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol className="breadcrumb">
                  <li><a href="/">Dashboard</a></li>
                  <li className="active"><span>Logictics</span></li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <button className="btn btn-primary btn-sm" onClick={() => this.add_new_logistics()}>Add New Logistics</button>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-lg-4 col-xs-12 col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="control-label" htmlFor="search_logistics">Search : </label>
                            <input type="text" name="search_logistics" placeholder="Search By Name/Reg.Id/Mobile/Email" value={this.state.search_logistics || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data(e.target.value) }} className="form-control" id="search_logistics" />
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="table-wrap mt-20">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                {/* <th>User Id</th> */}
                                <th>Reg. Id</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                {/* <th>Country</th> */}
                                {/* <th>State</th> */}
                                <th>City</th>
                                <th>Reg Date</th>
                                {/* <th>Pass</th> */}
                                <th>Status</th>
                                <th>Verified</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data_status == true ?
                                  vender_list.map((list, index) => {
                                    return (
                                      <tr key={"row" + index}>
                                        <td>{index + 1}</td>
                                        <td>{list.full_name}</td>
                                        {/* <td>{list.User_name}</td> */}
                                        <td>{list.reg_Id}</td>
                                        <td>{list.email_identity}</td>
                                        <td>{list.contact_details}</td>
                                        {/* <td>{list.country_name}</td> */}
                                        {/* <td>{list.state_name}</td> */}
                                        <td>{list.city_name}</td>
                                        <td><Moment format="DD MMM YYYY">
                                          {list.created_on}
                                        </Moment></td>
                                        {/* <td>{list.comp_pass}</td> */}
                                        <td>{list.owner_status == 1 ? <span data-label="owner_status" data-status="2" data-id={list.id} id={"status_" + list.id} className="label label-success cursor change_master_status">Active</span> : <span data-label="owner_status" data-status="1" data-id={list.id} id={"status_" + list.id} className="label label-danger cursor change_master_status">Inactive</span>}</td>
                                        <td>{list.owner_verified == 1 ? <span data-label="owner_verified" data-status="2" data-id={list.id} id={"verified_" + list.id} className="label label-success cursor change_master_status"><i className="fa fa-check"></i></span> : <span data-label="owner_verified" data-status="1" data-id={list.id} id={"verified_" + list.id} className="label label-danger cursor change_master_status"><i className="fa fa-times"></i></span>}</td>
                                        <td>
                                          <button onClick={() => this.selectAction(list.id, 1, 'Profile Detail')} title="Edit" className="btn-xs btn-danger mr-5"><i className="fa fa-pencil-square-o"></i></button>
                                          <button onClick={() => this.selectAction(list.id, 3, 'Kyc Document')} title="Document" className="btn-xs btn-primary mr-5"><i className="fa fa-file-pdf-o"></i></button>
                                          <button onClick={() => this.selectAction(list.id, 10, 'Area Allotments')} title="Service Location" className="btn-xs btn-info"><i className="fa fa-map-marker"></i></button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  :
                                  <tr>
                                    <td colSpan="10" align="center">Record not found</td>
                                  </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Logistics;
