import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fetch_status_string, fetch_payment_mode_string } from '../../components/General_data.js';
class OrderPrintSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_status: false,
      new_order: [],
      loading_text: 'Loading Please Wait',
    };
    if (props.match.isExact == true) {
      let new_order = props.location.state['new_order'];
      let backPath = props.location.state['backPath'];
      if (new_order.length > 0) {
        this.call_order_list(new_order);
      }
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        let mythis =this;
        mediaQueryList.addListener(function (mql) {
          //console.log('print_action', mql);
          if (mql.matches == false) {
            //console.log(mql.matches);
            //console.log(mythis);
            //mythis.props.history.goBack();
            mythis.props.history.push(backPath);
          }
        });
      }
    }
  }
  call_order_list = (new_order) => {
    this.state = { new_order: new_order, data_status: true, loading_text: '' };
  }
  componentDidMount() {
    let new_order = this.state.new_order;
    if (new_order.length > 0) {
      window.print();
    }
  }

  render() {

    const { data_status, new_order } = this.state;
    return (
      <div>
        <div style={{ minHeight: "359px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="text-center">Order Report </h5>
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>SrNo</th>
                      <th>Order No.</th>
                      <th>Logistics</th>
                      <th>L Contact</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Pincode</th>
                      <th>Area</th>
                      <th>Amount</th>
                      <th><span title="Shipping Charge">SC</span></th>
                      <th>Total</th>
                      <th><span title="Payment Mode">PM</span></th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data_status == true ?
                        new_order.map((list, index) => {
                          return (
                            <tr key={"row_order" + index}>
                              <td>{index + 1}</td>
                              <td>{list.invoiceNumber}</td>
                              <td>{list.full_name}</td>
                              <td>{list.contact_details}</td>
                              <td>{list.muser_name}</td>
                              <td>{list.mobilenumber}</td>
                              <td>{list.o_pincode_id}</td>
                              <td>{list.area_name}</td>
                              <td>{list.order_amount}</td>
                              <td>{list.shipping_amount}</td>
                              <td>{list.final_amount}</td>
                              <td>{fetch_payment_mode_string(list.p_mode)}</td>
                              <td>{list.order_date}</td>
                              <td>
                                {fetch_status_string(list.order_status)}
                              </td>
                            </tr>
                          )
                        })
                        : <tr><td colSpan="14" align="center">{this.state.loading_text}</td></tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(OrderPrintSection);
