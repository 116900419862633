import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Button } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import { fetch_status_string, fetch_payment_mode_string } from '../../components/General_data.js';
class SaleReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      order_reports: [],
      logistic_list: [],
      courier_company_id: '',
      p_mode: '',
      order_number: '',
      order_date_from: '',
      order_date_to: ''
    };
  }
  componentDidMount() {
    this.orderReports();
    this.logisticsList();
  }
  logisticsList() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/order-courier-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ logistic_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  orderReports() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'category_id': this.state.category_id,
      'order_date_from': this.state.order_date_from,
      'p_mode': this.state.p_mode,
      'order_number': this.state.order_number,
      'courier_company_id': this.state.courier_company_id,
      'order_date_to': this.state.order_date_to
    }
    this.setState({ order_reports: [], loading: true })
    call_api('a/order-sale-report', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ order_reports: resolve.data, loading: false })
          }
          else {
            this.setState({ loading: false })
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false })
          notify.show("Server Not Responding", 'error', 2000);
        }
      )
  }
  getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  orderReports_export = () => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'category_id': this.state.category_id,
      'order_date_from': this.state.order_date_from,
      'p_mode': this.state.p_mode,
      'order_number': this.state.order_number,
      'courier_company_id': this.state.courier_company_id,
      'order_date_to': this.state.order_date_to
    }
    this.setState({ loading: true });
    call_api('a/sale-report-export', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            if (resolve.excell_url) {
              window.open(resolve.excell_url, '_blank');
            }
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
          this.setState({ loading: false });
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false });
        }
      )
  }
  render() {
    const { order_reports, logistic_list } = this.state;
    var p_qty = 0;
    var p_price = 0;
    var p_discount = 0;
    var total_loyalty_discount = 0;
    var total_couponAmount = 0;
    var total_shipping_amount = 0;
    var total_net_amount = 0;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 9999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Sale Report</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_from" value={this.state.order_date_from} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_to" value={this.state.order_date_to} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="courier_company_id" value={this.state.courier_company_id} className="form-control" >
                              <option value="">Select Courier</option>
                              {
                                logistic_list.map((logList, logs) => {
                                  return (
                                    <option key={"option2" + logs} value={logList.courier_company_id}>{logList.courier_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select id="p_mode" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="p_mode" value={this.state.p_mode} className="form-control">
                              <option value="">Payment Mode</option>
                              <option value="2">Online</option>
                              <option value="1">COD</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xs-12 col-sm-4 col-md-4">
                          <div className="form-group">
                            <input type="text" placeholder='Order No./AWB/Mobile No./Product Name/Style Code/Barcode' onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_number" value={this.state.order_number} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 text-center">
                          <div className="form-group mt-35">
                            <button type="button" disabled={this.state.loading} onClick={() => this.orderReports_export()} className="btn btn-sm btn-success mr-2">{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Export</button>
                            <Button type="button" disabled={this.state.loading} onClick={() => this.orderReports()} className="btn btn-info btn-sm"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Search</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Order No.</th>
                              <th>Barcode</th>
                              <th>SKU Id</th>
                              <th>HSN Code</th>
                              <th>Size</th>
                              <th>QTY</th>
                              <th>MRP</th>
                              <th>Product Decription</th>
                              <th>Sale Price</th>
                              <th>Discount Amount</th>
                              <th>Customer Name</th>
                              <th>Contact</th>
                              <th>Loyalty Discount</th>
                              <th>Coupon Discount</th>
                              <th>Shipping Charge</th>
                              <th>Payment Mode</th>
                              <th>Order Status</th>
                              <th>AWB No.</th>
                              <th>Courier partner</th>
                              <th>Net Sale Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order_reports.map((list, index) => {
                              let shipping_amount = (list.shipping_amount > 0) ? (parseFloat(list.shipping_amount) / parseInt(list.total_qty)) : list.shipping_amount;
                              let loyalty_discount = (list.loyalty_discount > 0) ? (parseFloat(list.loyalty_discount) / parseInt(list.total_qty)) : list.loyalty_discount;
                              let couponAmount = (list.couponAmount > 0) ? (parseFloat(list.couponAmount) / parseInt(list.total_qty)) : list.couponAmount;
                              let net_amount = parseFloat(list.total_price) - parseFloat(loyalty_discount) - parseFloat(couponAmount);
                              p_qty += parseInt(list.p_qty);
                              p_price += parseFloat(list.p_price);
                              p_discount += parseFloat(list.p_discount);
                              total_loyalty_discount += parseFloat(loyalty_discount);
                              total_shipping_amount += parseFloat(shipping_amount);
                              total_couponAmount += parseFloat(couponAmount);
                              if (list.order_status == 8 || list.order_status == 9 && net_amount > 0) {
                                //total_net_amount -= parseFloat(net_amount);
                              }
                              else {
                                total_net_amount += parseFloat(net_amount);
                              }
                              return (
                                <tr key={index}>
                                  <td>{list.order_date}</td>
                                  <td>{list.invoiceNumber}</td>
                                  <td>{list.p_barcode}</td>
                                  <td>{list.sku}</td>
                                  <td>{list.hsn}</td>
                                  <td>{list.product_size}</td>
                                  <td>{list.p_qty}</td>
                                  <td>{list.actual_price}</td>
                                  <td>{list.p_name}</td>
                                  <td>{list.p_price}</td>
                                  <td>{list.p_discount}</td>
                                  <td>{list.muser_name}</td>
                                  <td>{list.mobilenumber}</td>
                                  <td>{loyalty_discount}</td>
                                  <td>{couponAmount}</td>
                                  <td>{shipping_amount}</td>
                                  <td>{fetch_payment_mode_string(list.p_mode)}</td>
                                  <td>{fetch_status_string(list.order_status)}</td>
                                  <td>{list.awb_code}</td>
                                  <td>{list.courier_name}</td>
                                  <td>{(list.order_status == 8 || list.order_status == 9 && net_amount > 0) ? '-' : ''}{net_amount}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={6}>Total</td>
                              <td>{p_qty}</td>
                              <td colSpan={2}></td>
                              <td>{p_price}</td>
                              <td>{p_discount}</td>
                              <td colSpan={2}></td>
                              <td>{total_loyalty_discount}</td>
                              <td>{total_couponAmount}</td>
                              <td>{total_shipping_amount}</td>
                              <td colSpan={4}></td>
                              <td>{total_net_amount}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(SaleReport);
