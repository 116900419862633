import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import { Modal, ModalBody, ModalHeader, CardText } from 'reactstrap';
import * as XLSX from 'xlsx';
class Product_Import_Mdl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      upload_btn: true,
      model_status: false,
      loading: false,
      product_list: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    let model_status = nextProps.model_status;
    this.setState({ model_status: model_status, upload_btn: true, loading: false })
  }
  componentDidMount() {
  }
  upload_exell_file = (e) => {
    let image = e.target.files[0];
    this.setState({ product_list: [], upload_btn: true });
    if (image != null) {
      if (image.type == "text/csv" || image.type == "application/vnd.ms-excel" || image.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || image.type == "application/vnd.oasis.opendocument.spreadsheet") {
        var reader = new FileReader();
        reader.readAsBinaryString(image);
        reader.onloadend = (evt) => {
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });
          /* Update state */
          this.setState({ product_list: data, upload_btn: false });
        };
      }
    }
  }
  upload_product() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag'),
      'product_list': this.state.product_list,
    }
    this.setState({ upload_btn: true, loading: true })
    call_api('a/product-upload', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            this.close_modal();
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  close_modal = () => {
    this.setState({ product_list: [] })
    this.props.parentCallback()
  }
  render() {
    const { product_list } = this.state;
    return (
      <>
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
        <Modal size="lg" fade={false} isOpen={this.state.model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.close_modal()}>X</button>}>
            <CardText className="modal-title">
              Import Product
            </CardText>
          </ModalHeader>
          <ModalBody className="px-2 py-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <label htmlFor="excell_file" className="control-label mr-2 mt-auto"><span className="text-danger">*</span>Select Excell File</label>
                            <input className="form-control" type="file" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet" onChange={(e) => this.upload_exell_file(e)} name="excell_file" id="excell_file" />
                            <div className="input-group-append">
                              <button className="btn btn-success btn-sm" disabled={this.state.upload_btn} onClick={() => this.upload_product()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</button>
                              <button className="btn btn-danger btn-sm" onClick={() => this.close_modal()}>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 mt-2">
                        <div className="table-responsive">
                          <table className="table mb-0 text-center">
                            <thead>
                              <tr>
                                <th>Sr No</th>
                                <th>Category</th>
                                <th>HSN Code</th>
                                <th>Style Code/SKU</th>
                                <th>Barcode</th>
                                <th>MRP</th>
                                <th>QTY</th>
                                <th>Brand</th>
                                <th>Description</th>
                                <th>SEASON</th>
                                <th>Size</th>
                                <th>Colour</th>
                                <th>NECK STYLE</th>
                                <th>FIT</th>
                                <th>SLEEVES</th>
                                <th>PATTERN</th>
                                <th>FABRIC</th>
                                <th>GENDER</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                product_list.slice(1).map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list['0']}</td>
                                      <td>{list['1']}</td>
                                      <td>{list['2']}</td>
                                      <td>{list['3']}</td>
                                      <td>{list['4']}</td>
                                      <td>{parseInt(list['5'])}</td>
                                      <td>{list['6']}</td>
                                      <td>{list['7']}</td>
                                      <td>{list['8']}</td>
                                      <td>{list['9']}</td>
                                      <td>{list['10']}</td>
                                      <td>{list['11']}</td>
                                      <td>{list['12']}</td>
                                      <td>{list['13']}</td>
                                      <td>{list['14']}</td>
                                      <td>{list['15']}</td>
                                      <td>{list['16']}</td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default Product_Import_Mdl;
