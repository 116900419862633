import React, { Component } from 'react';
class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="d-sm-flex justify-content-center justify-content-sm-between">
                        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © Shlok It Solution 2022</span>
                    </div>
                    <div>
                        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block"> Distributed By: <a href="https://shlokitsolution.com" target="_blank">Shlok It Solution</a></span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;