import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { call_api } from '../../connect/Connect.js';
import "../../assets/orderPrint_style.css";
import { fetch_status_string, fetch_payment_mode_string } from '../../components/General_data.js';
class AllOrderDetailPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_status: false,
      order_report_list: [],
      loading_text: 'Loading Please Wait',
    };
    if (props.match.isExact == true) {
      let new_order = props.location.state['new_order'];
      let backPath = props.location.state['backPath'];
      if (new_order.length > 0) {
        this.call_order_list(new_order);
      }
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        let mythis = this;
        mediaQueryList.addListener(function (mql) {
          if (mql.matches == false) {
            mythis.props.history.push(backPath);
          }
        });
      }
    }
  }
  call_order_list = (new_order) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'orderIdDetail': new_order
    }
    call_api('a/all-order-deatils', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ order_report_list: resolve.data, data_status: true });
            window.print();
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  ucwords = (words) => {
    return words.toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase())
  }
  call_empty_table = (count) => {
    var limit = 20 - count;
    var i = 1;
    let data_list = []
    for (i = 1; i <= limit; i++) {
      data_list.push(i)
    }
    var count = 1;
    return data_list.map((item) => {
      return (
        <tr key={item}>
          <td className='rowtd-up' colSpan="6"></td>
        </tr>
      )
    })
  }
  render() {
    const { data_status, order_report_list } = this.state;
    var count = 1;
    var current = 0;
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card" id="all-order-detail" style={{ fontSize: '12px', marginTop: '10px' }}>
                {
                  order_report_list.map((item, index) => {
                    return (
                      <div key={index} style={{ pageBreakAfter: 'always' }}>
                        <h5 className="text-center">Order Invoice </h5>
                        <div style={{ marginLeft: '30px', padding: '1px' }}>
                          <table width="100%">
                            <tr>
                              <td colSpan="6">
                                <h3 align="center">Harpra Fashion Store</h3>
                                <p align="center">EWS BLOCK NO 8/11 HOUSING BOARD PIRDA RAIPUR (C.G.) - 492101</p>
                                <p align="center" style={{ fontSize: '13px', marginTop: '-8px' }}>Mobile:- 8815290183,Email:-support@harpra.co.in, rohit.jagat@harpra.co.in,website:-www.harpra.co.in</p>
                              </td>
                            </tr>
                          </table>
                          <br />
                          <table width="100%" style={{ border: '1px solid #a3a3a3' }}>
                            <tr>
                              <td width="55%" style={{ borderRight: '1px solid #9b9696', paddingLeft: '15px', paddingTop: '5px' }}>
                                {/* <h6 align="center">Customer Detail</h6> */}
                                <p><span style={{ fontWeight: "600" }}>Name :-</span> {this.ucwords(item['order']['muser_name'])}</p>
                                <p><span style={{ fontWeight: "600" }}>Address :-</span> <span>{this.ucwords(item['order']['muser_address'])},{this.ucwords(item['order']['area_name'])}
                                  {item['order']['city_name']}, {item['order']['state_name']} {/* - {item['order']['o_pincode_id']} */}</span></p>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>
                                      <p style={{ marginLeft: '5px' }}><span><p className="section-header">Order No. </p></span> <span className="section-saparater">:</span>
                                        <span className="section-data">#{item['order']['invoiceNumber']}</span>
                                      </p>
                                      <p style={{ marginLeft: '5px' }}><span><p className="section-header">Order Date</p></span> <span className="section-saparater">:</span>
                                        <span className="section-data">{item['order']['order_date']} {item['order']['o_created_at']}</span></p>
                                      <p style={{ marginLeft: '5px' }}><span><p className="section-header">Payment Mode</p></span> <span className="section-saparater">:</span>
                                        <span className="section-data">{fetch_payment_mode_string(item['order']['p_mode'])}</span></p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <br />
                          {/* <div className="row">
                              <div className="col-lg-6">
                                <p>Order Status : <span>{fetch_status_string(item['order']['order_status'])}</span></p>
                              </div>
                            </div> */}
                          <table width="100%" border="1" className="product-detail">
                            <thead>
                              <tr>
                                <th>Sr.No.</th>
                                <th>Item Detail</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Rate</th>
                                <th>Total Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item['cart'].map((crt_p, index) => {
                                return (
                                  <tr key={"crt_p" + index}>
                                    <td>{index + 1}</td>
                                    <td>{this.ucwords(crt_p.product_name)}</td>
                                    <td>{crt_p.p_unit_qty} {crt_p.p_unit}</td>
                                    <td>{crt_p.p_qty}</td>
                                    {/* <td>{crt_p.actual_price}</td> */}
                                    <td>{crt_p.p_price}</td>
                                    <td>{crt_p.total_price}</td>
                                  </tr>
                                )
                              }
                              )}
                              {
                                this.call_empty_table(item['cart'].length)
                              }
                              <tr>
                                <td style={{ borderTop: "2px solid grey" }} colSpan="5">Shipping Charge</td>
                                <td style={{ borderTop: "2px solid grey" }} className="txtcntr">+{item['order']['shipping_amount']}</td>
                              </tr>
                              <tr>
                                <th colSpan="5">Total</th>
                                <th className="txtcntr">{item['order']['final_amount']}</th>
                              </tr>
                              {/*  <tr>
                                <td colSpan="6" style={{ textAlign: 'right' }}>
                                  <br /><br /><br />
                                  <p style={{ marginRight: "50px" }}>
                                    <strong>For Surana Enterprises</strong><br />
                                    Authorized Signatory</p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="6">
                                  <b>Terms & Condition</b>
                                  <br />Declarations: We declare that this invoice shows the actual price of the goods described and that all
                                  particulars are true and correct.
                                  <br />1. On Payment after due date 18% pa Interest will be charged.
                                  <br />2. No Claims are entered unless brought to our notice in writing with in 7 days on receipt of goods.
                                  <br />3. This Transaction is subject to “Raipur Jurisdiction”.
                                  <br />4. Goods once sold will not be taken back.
                                  <br />5. Our responsibility ceases as soon as the goods leaves our premises/godown.
                                  <br />Request you to use the link <a href="https://harpra.co.in" target="_blank">www.harpra.co.in</a> to place sales order.<p></p>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AllOrderDetailPrint);
