import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import Moment from 'react-moment';
import SweetAlert from 'react-bootstrap-sweetalert';
class EnquiryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading_text: "Loading please wait",
      loading: true,
      data_status: false,
      detailModel: false,
      UserId: 0,
      active_status: "",
      search_date_1: "",
      search_date_2: "",
      enquery_list: [],
      enquery_detail: [],
    };
  }
  componentDidMount() {
    this.get_all_inquery_list();
  }
  get_all_inquery_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag'),
      'date_1': this.state.search_date_1,
      'date_2': this.state.search_date_2,
      'active_status': this.state.active_status,
    }
    this.setState({ loading: true });
    call_api('a/get-all-enquiry', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ enquery_list: resolve.enquery_list, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ data_status: false, loading_text: resolve.message })
          }
          this.setState({ loading: false });
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  editData = (data) => {
    this.setState({ detailModel: true, enquery_detail: data });
  }
  deleteInialize = (en_id) => {
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        onCancel={() => { this.setState({ alert: null }); }}
        onConfirm={() => { this.confirm_enquery_delete(en_id) }} >
        You will not be able to recover this imaginary file
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });

  }
  confirm_enquery_delete(en_id) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'en_id': en_id
    }
    this.setState({ alert: null, loading: true });
    call_api('a/delete-enquiry', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            const items = this.state.enquery_list.filter(item => item.en_id !== en_id);
            this.setState({ enquery_list: items || [], loading: false })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false })
          }
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false })
        }
      )
  }
  change_status(en_id) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'en_id': en_id
    }
    this.setState({ loading: true });
    call_api('a/update-status-enquiry', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.get_all_inquery_list();
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false })
          }
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false })
        }
      )
  }
  render() {
    const { alert, data_status, enquery_list, enquery_detail } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 9999, timeout: 1000, top: '100px' }} />
          {alert}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Enquiry List</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="search_date_1" value={this.state.search_date_1} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="search_date_2" value={this.state.search_date_2} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="active_status" value={this.state.active_status} className="form-control">
                              <option value="">Select Status</option>
                              <option value="0">UnRead</option>
                              <option value="1">Read</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-5 col-xs-12 col-sm-2 col-md-2">
                          <div className="mt-30">
                            <button type="button" disabled={this.state.loading} onClick={() => this.get_all_inquery_list()} className="btn btn-warning btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Search</button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Subject</th>
                              <th>Created</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                enquery_list.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.user_name}</td>
                                      <td>{list.user_email || '--'}</td>
                                      <td>{list.user_contact}</td>
                                      <td>{list.user_subject}</td>
                                      <td>
                                        <Moment format="DD MMM YYYY">
                                          {list.created_at}
                                        </Moment>
                                      </td>
                                      <td>{list.active_status == 0 ? <span onClick={() => this.change_status(list.en_id)} className="badge cursor badge-danger">Unread</span> : <span onClick={() => this.change_status(list.en_id)} className="badge cursor badge-success">Read</span>}</td>
                                      <td>
                                        <button className="btn btn-sm btn-warning mr-2" onClick={() => this.editData(list)}>View</button>
                                        <button disabled={this.state.loading} onClick={() => this.deleteInialize(list.en_id)} className="btn btn-sm btn-danger">{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Delete</button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="8" align="center">{this.state.loading_text}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" fade={false} isOpen={this.state.detailModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ detailModel: false })}>X</button>}>
            <CardText className="modal-title">
              Enquiry Detail
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                {enquery_detail.user_message}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div></div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default EnquiryList;
