import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './master/Dashboard.js';
import ChangePassword from './master/ChangePassword.js';
/* import Admin_List from './master/retailer/Admin_List.js';
import CountryList from './master/CountryList.js';
import StateList from './master/StateList.js'; */
import CityList from './master/CityList.js';
import PincodeList from './master/PincodeList.js';
import AreaList from './master/AreaList.js';
import CouponSetting from './master/CouponSetting.js';
import ProductCategory from './master/product/ProductCategory.js';
import Product_list from './master/product/Product_list.js';
import Product_brand from './master/product/Product_brand.js';
import WebsiteProductDisplay from './master/product/WebsiteProductDisplay.js';
import WebsitePriceSection from './master/product/WebsitePriceSection.js';
import Blog_List from './master/web_setting/Blog_List.js';
import Product_price_list from './master/product/Product_price_list.js';
import User_list from './master/user/User_list.js';
import EnquiryList from './master/user/EnquiryList.js';
import CouponCode from './master/coupon/CouponCode.js';
import OrderReport from './master/order/OrderReport.js';
import CourierWiseReport from './master/order/CourierWiseReport.js';
import OrderList from './master/order/OrderList.js';
import RROrderList from './master/order/RROrderList.js';
import OrderPrintSection from './master/order/OrderPrintSection.js';
import AllOrderDetailPrint from './master/order/AllOrderDetailPrint.js';
import OrderQuantityReport from './master/order/OrderQuantityReport.js';
import Rating_reviews from './master/web_setting/Rating_reviews.js';
import Top_viewed_product from './master/product/Top_viewed_product.js';
import SaleReport from './master/order/SaleReport.js';
import OrderDetails from './master/order/OrderDetails.js';
//import VendorList from './master/retailer/VendorList.js';
import Logistics from './master/retailer/Logistics.js';
/* import Notification from './master/web_setting/Notification.js'; */
import Web_setting from './master/web_setting/Web_setting.js';
import Web_page_setting from './master/web_setting/Web_page_setting.js';
import MasterUnit from './master/web_setting/MasterUnit.js';
/* import MasterShippingType from './master/web_setting/MasterShippingType.js';
import MasterShippingTime from './master/web_setting/MasterShippingTime.js'; */
import Slider from './master/web_setting/Slider.js';
import Logout from './components/Logout.js';
import Login_check from './components/Login_check.js';
import ProfileLogin from './components/ProfileLogin.js';
import PasswordForget from './components/PasswordForget.js';
import Header from './components/master/Header.js';
/* import Footer from './components/Footer.js'; */
import ErrorPage from './ErrorPage.js';
function App() {
  return (
    <div className='container-scroller'>
      <Router>
        <Switch>
          <Route exact path="/"> <Header /><Login_check cmp={Dashboard} /></Route>
          <Route exact path="/dashboard"> <Header /><Login_check cmp={Dashboard} /></Route>
          <Route exact path="/password"> <Header /><Login_check cmp={ChangePassword} /></Route>
          {/* <Route exact path="/super-admin"><Header/> <Login_check cmp={Admin_List} /></Route> */}
          <Route exact path="/logistic-list"><Header /> <Login_check cmp={Logistics} /></Route>
          {/* <Route exact path="/set-country"><Header/> <Login_check cmp={CountryList} /></Route>
          <Route exact path="/set-state"> <Header/><Login_check cmp={StateList} /></Route> */}
          <Route exact path="/set-city"><Header /> <Login_check cmp={CityList} /></Route>
          <Route exact path="/set-pincode"><Header /> <Login_check cmp={PincodeList} /></Route>
          <Route exact path="/set-area"> <Header /><Login_check cmp={AreaList} /></Route>
          <Route exact path="/product-category"> <Header /><Login_check cmp={ProductCategory} /></Route>
          <Route exact path="/product-list"> <Header /><Login_check cmp={Product_list} /></Route>
          <Route exact path="/product-brand"> <Header /><Login_check cmp={Product_brand} /></Route>
          <Route exact path="/top-viewed-product"> <Header /><Login_check cmp={Top_viewed_product} /></Route>
          <Route exact path="/rating-reviews"> <Header /><Login_check cmp={Rating_reviews} /></Route>
          <Route exact path="/website-product-display"> <Header /><Login_check cmp={WebsiteProductDisplay} /></Route>
          <Route exact path="/website-price-section"> <Header /><Login_check cmp={WebsitePriceSection} /></Route>
          <Route exact path="/product-price-list"> <Header /><Login_check cmp={Product_price_list} /></Route>
          <Route exact path="/user-list"> <Header /><Login_check cmp={User_list} /></Route>
          <Route path="/order-list"> <Header /><Login_check cmp={OrderList} /></Route>
          <Route path="/return-replace-order"> <Header /><Login_check cmp={RROrderList} /></Route>
          <Route exact path="/courier-wise-report"> <Header /><Login_check cmp={CourierWiseReport} /></Route>
          <Route exact path="/order-list-print"><Login_check cmp={OrderPrintSection} /></Route>
          <Route exact path="/all-order-deatils-print"><Login_check cmp={AllOrderDetailPrint} /></Route>
          <Route exact path="/product-order-report"> <Header /><Login_check cmp={OrderQuantityReport} /></Route>
          <Route exact path="/order-details/:order_id"> <Header /><Login_check cmp={OrderDetails} /></Route>
          <Route exact path="/order-report"> <Header /><Login_check cmp={OrderReport} /></Route>
          <Route exact path="/sale-report"> <Header /><Login_check cmp={SaleReport} /></Route>
          <Route exact path="/coupon-code"> <Header /><Login_check cmp={CouponCode} /></Route>
          <Route exact path="/enquiry-list"> <Header /><Login_check cmp={EnquiryList} /></Route>
          <Route exact path="/set-website"> <Header /><Login_check cmp={Web_setting} /></Route>
          <Route exact path="/set-slider"> <Header /><Login_check cmp={Slider} /></Route>
          <Route exact path="/blog-list"> <Header /><Login_check cmp={Blog_List} /></Route>
          <Route exact path="/set-unit"> <Header /><Login_check cmp={MasterUnit} /></Route>
          {/* <Route exact path="/set-shipping-type"> <Header/><Login_check cmp={MasterShippingType} /></Route>
          <Route exact path="/set-shipping-time"> <Header/><Login_check cmp={MasterShippingTime} /></Route> */}
          <Route exact path="/set-page-website"> <Header /><Login_check cmp={Web_page_setting} /></Route>
          {/* <Route exact path="/notification"> <Header/><Login_check cmp={Notification} /></Route> */}
          <Route exact path="/coupon-setting"> <Header /><Login_check cmp={CouponSetting} /></Route>
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/service-login" component={ProfileLogin} />
          <Route exact path="/master" component={ProfileLogin} />
          <Route exact path="/forgot-password" component={PasswordForget} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
