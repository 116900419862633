import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import user1 from '../../assets/img/icon.png';
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { full_name: '', reg_Id: '' };
	}
	componentDidMount() {
		const full_name = localStorage.getItem('full_name');
		const reg_Id = localStorage.getItem('reg_Id');
		if (full_name && reg_Id) {
			this.setState({ full_name: full_name, reg_Id: reg_Id });
		}
	}
	render() {
		const { full_name, reg_Id } = this.state;
		return (
			<div className="horizontal-menu fixed-on-scroll">
				<nav className="navbar top-navbar col-lg-12 col-12 p-0">
					<div className="container">
						<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
							<a className="navbar-brand brand-logo" href="/">
								<img src={logo} alt="logo" />
								{/* <span className="font-12 d-block font-weight-light">Admin Panel</span> */}
							</a>
							<a className="navbar-brand brand-logo-mini" href="/"><img src={logo} alt="logo" /></a>
						</div>
						<div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
							<ul className="navbar-nav mr-lg-2">
								<li className="nav-item nav-search d-none d-lg-block">
									<div className="input-group">
										<div className="input-group-prepend hover-cursor" id="navbar-search-icon">
											<span className="input-group-text" id="search">
												<i className="mdi mdi-magnify"></i>
											</span>
										</div>
										<input type="text" className="form-control" id="navbar-search-input" placeholder="Search" aria-label="search" aria-describedby="search" />
									</div>
								</li>
							</ul>
							<ul className="navbar-nav navbar-nav-right">
								<li className="nav-item nav-profile dropdown">
									<a className="nav-link" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
										<div className="nav-profile-img">
											<img src={user1} alt="image" />
										</div>
										<div className="nav-profile-text">
											<p className="text-black font-weight-semibold m-0">{full_name}</p>
											<span className="font-13 online-color">{reg_Id} <i className="mdi mdi-chevron-down"></i></span>
										</div>
									</a>
									<div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
										<Link className="dropdown-item" to="/profile">
											<i className="mdi mdi-account mr-2 text-success"></i>Profile</Link>
										<div className="dropdown-divider"></div>
										<Link className="dropdown-item" to="/password">
											<i className="mdi mdi-lock mr-2 text-primary"></i> Password </Link>
										<a className="dropdown-item" href="/logout">
											<i className="mdi mdi-logout mr-2 text-primary"></i> Signout </a>
									</div>
								</li>
							</ul>
							<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-bs-toggle="horizontal-menu-toggle">
								<span className="mdi mdi-menu"></span>
							</button>
						</div>
					</div>
				</nav>
				<nav className="bottom-navbar">
					<div className="container-fluid">
						<ul className="nav page-navigation">
							<li className="nav-item">
								<a className="nav-link" href="/">
									<i className="mdi mdi-compass-outline menu-icon"></i>
									<span className="menu-title">Dashboard</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="#" className="nav-link">
									<i className="mdi mdi-monitor-dashboard menu-icon"></i>
									<span className="menu-title">Setting</span>
									<i className="menu-arrow"></i>
								</a>
								<div className="submenu">
									<ul className="submenu-item">
										<li className="nav-item">
											<Link className="nav-link" to="/set-page-website">Page Setting</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/set-website">General Setting</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/coupon-setting">Coupon Setting</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/set-slider">Slider</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/blog-list">Blog</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<a href="/order-list" className="nav-link">
									<i className="mdi mdi-monitor-dashboard menu-icon"></i>
									<span className="menu-title">Order</span>
									<i className="menu-arrow"></i>
								</a>
								<div className="submenu">
									<ul className="submenu-item">
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=new_order">New Order</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=confirm_order">Confirmed Order</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=rtd_order">Pending RTD</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=pending_dispatch_Order">Pending Dispatch</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=dispatch_order">Dispatched Order</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=complete_order">Complete Order</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=cancelled_order">Cancelled Order</a>
										</li>
										{/* <li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=return_process">Return Process</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/order-list?order_state=return_order">Return Order</a>
										</li> */}
										<li className="nav-item">
											<a className="nav-link" href="/return-replace-order">Return/Replace Order</a>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<a href="#" className="nav-link">
									<i className="mdi mdi-chart-bar menu-icon"></i>
									<span className="menu-title">Order Report</span>
									<i className="menu-arrow"></i>
								</a>
								<div className="submenu">
									<ul className="submenu-item">
										<li className="nav-item">
											<Link className="nav-link" to="/courier-wise-report">Courier Wise Report</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/order-report">Order Report</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/product-order-report">Product Order Report</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/sale-report">Sale Report</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<a href="#" className="nav-link">
									<i className="mdi mdi-table-large menu-icon"></i>
									<span className="menu-title">Inventory</span>
									<i className="menu-arrow"></i>
								</a>
								<div className="submenu">
									<ul className="submenu-item">
										<li className="nav-item">
											<Link className="nav-link" to="/product-category">Category</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/product-list">Item</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/product-price-list">Item Price</Link>
										</li>
										{/* <li className="nav-item">
											<Link className="nav-link" to="/product-brand">Item Brand</Link>
										</li> */}
										<li className="nav-item">
											<Link to="/rating-reviews" className="nav-link">Rating and Reviews</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<a href="#" className="nav-link">
									<i className="mdi mdi-table-large menu-icon"></i>
									<span className="menu-title">Product Section</span>
									<i className="menu-arrow"></i>
								</a>
								<div className="submenu">
									<ul className="submenu-item">
										<li className="nav-item">
											<Link className="nav-link" to="/website-product-display">Website Product Section</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/website-price-section">Website Price Section</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/user-list">
									<i className="mdi mdi-contacts menu-icon"></i>
									<span className="menu-title">User List</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/coupon-code" className="nav-link">
									<i className="mdi mdi-file-document-box menu-icon"></i>
									<span className="menu-title">Coupon Code</span></Link>
							</li>
							<li className="nav-item">
								<Link to="/enquiry-list" className="nav-link">
									<i className="mdi mdi-file-document-box menu-icon"></i>
									<span className="menu-title">Enquiry</span></Link>
							</li>
							<li className="nav-item">
								<div className="nav-link d-flex">
									{/* <button className="btn btn-sm bg-danger text-white"> Trailing </button> */}
									<div className="nav-item dropdown">
										<a className="nav-link count-indicator dropdown-toggle text-white font-weight-semibold" id="notificationDropdown" href="#" data-bs-toggle="dropdown"> English </a>
										{/* <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
											<a className="dropdown-item" href="#">
												<i className="flag-icon flag-icon-bl mr-3"></i> English </a>
										</div> */}
									</div>
									<a className="text-white" href="/"><i className="mdi mdi-home-circle"></i></a>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		);
	}
}

export default Header;