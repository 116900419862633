import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import LazyLoad from "react-lazyload";
import OrderDetailsMdl from './OrderDetailsMdl.js';
import OrderTrackMdl from './OrderTrackMdl.js';
import notfoundImg from "../../assets/img/no_image.jpg";
import { fetch_status_string, fetch_payment_mode_string, fetch_status_btn_color } from '../../components/General_data.js';
class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      data_status: false,
      model_status: false,
      track_model_status: false,
      checkedAll: false,
      detail_model_status: false,
      order_action_button: true,
      new_order: [],
      item_in_order: [],
      order_Detail: [],
      shipment: [],
      order_id: 0,
      newOrder: 0,
      new_order_status: 0,
      remarks: '',
      order_id_selected: '',
      p_mode: '',
      admin_pincode: [],
      order_list_labels: [],
      admin_area: [],
      order_date_from: '',
      order_date_to: '',
      order_pincode: '',
      order_update_header_text: '',
      order_area: '',
      order_type: '',
      logistic_list: [],
      courier_company_id: '',
      courier_id_present: false,
      loading_text: 'Loading Please Wait',
      order_number: '',
    };
    /* const current_path = props.location.pathname + props.location.search;
    const params = new URLSearchParams(props.location.search);
    const order_state = params.get('order_state');
    if (order_state) {
      this.allOrderList(order_state, current_path);
    }
    else {
      this.allOrderList('', current_path);
    } */
  }
  log_order_details = (order_ids) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'order_id': order_ids,
    }
    call_api('a/order-details', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ item_in_order: resolve.cart, order_id_selected: order_ids, order_Detail: resolve.data, shipment: resolve.shipment, loading: false })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  allOrderList = async (order_type, current_path) => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag'),
      'order_type': order_type,
      'order_number': this.state.order_number,
      'p_mode': this.state.p_mode,
      'order_date_from': this.state.order_date_from,
      'order_date_to': this.state.order_date_to,
      'courier_company_id': this.state.courier_company_id,
    }
    await call_api('a/order-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ new_order: resolve.data, data_status: true })
            this.setState({ order_type: order_type, current_path: current_path });
          }
          else {
            this.setState({ order_type: order_type, new_order: [], loading_text: resolve.message, data_status: false });
          }
          this.setState({
            cancOrder: resolve.cancOrder,
            compOrder: resolve.compOrder,
            confOrder: resolve.confOrder,
            dispOrder: resolve.dispOrder,
            newOrder: resolve.newOrder,
            pending_dispatch_Order: resolve.pending_dispatch_Order,
            ret_order: resolve.ret_order,
            rtdOrder: resolve.rtdOrder,
          });
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  get_order_search = () => {
    this.allOrderList(this.state.order_type, this.state.current_path);
  }
  update_order_length_weight = () => {
    const order_Detail = this.state.order_Detail;
    if (order_Detail.weight == '' || order_Detail.weight == '0') {
      notify.show('Enter item weight', 'error', 2000);
    }
    else if (order_Detail.item_length == '' || order_Detail.item_length == '0') {
      notify.show('Enter item length', 'error', 2000);
    }
    else if (order_Detail.height == '' || order_Detail.height == '0') {
      notify.show('Enter item height', 'error', 2000);
    }
    else if (order_Detail.breadth == '' || order_Detail.breadth == '0') {
      notify.show('Enter item breadth', 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      let options = {
        item_length: order_Detail.item_length,
        weight: order_Detail.weight,
        height: order_Detail.height,
        breadth: order_Detail.breadth,
        order_id: order_Detail.order_id,
        'LoginToken': localStorage.getItem('LoginToken'),
      }
      call_api('a/update-orders-detail', options)
        .then(
          (resolve) => {
            this.setState({ loading: false });
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  update_pickup_scheduled_date = () => {
    const order_Detail = this.state.shipment;
    if (order_Detail.new_pickup_scheduled == '') {
      notify.show('Select New Pickup Scheduled  Date', 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      let options = {
        new_pickup_scheduled: order_Detail.new_pickup_scheduled || order_Detail.pickup_scheduled_date,
        order_id: order_Detail.order_id,
        'LoginToken': localStorage.getItem('LoginToken'),
      }
      call_api('a/update-shipment-pickup-date', options)
        .then(
          (resolve) => {
            this.setState({ loading: false });
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  adminPincode() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/admin-pincode', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ admin_pincode: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  adminArea() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/admin-area', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ admin_area: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  logisticsList() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/order-courier-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ logistic_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  update_order_status = async () => {
    let new_order_status = this.state.new_order_status || "";
    var newList = [];
    this.state.new_order.map((item, index) => {
      if (item.checked === true)
        newList.push(item['order_id']);
    })

    let options = {
      'LoginToken': localStorage.getItem('LoginToken'),
      new_order_status: new_order_status,
      remarks: this.state.remarks,
      order_ids: newList
    };
    if (new_order_status == "") {
      notify.show('select order status', 'error', 2000);
    }
    else if (newList.length === 0) {
      notify.show('select order', 'error', 2000);
    }
    else if (new_order_status === 'cancelled_order' && this.state.remarks == '') {
      notify.show('select order', 'error', 2000);
    }
    else {
      this.setState({ loading: true });
      await call_api('a/update-orders-status', options)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ loading: false, new_order: [], model_status: false, checkedAll: false, order_action_button: true });
              this.allOrderList(this.state.order_type, this.state.current_path);
              notify.show(resolve.message, 'success', 2000);
              if (new_order_status == 'rtd_order' && resolve['label_url']) {
                window.open(resolve.label_url, '_blank');
              }
              else if (new_order_status == 'download_manifests' && resolve['manifest_url']) {
                window.open(resolve.manifest_url, '_blank');
              }
              else if (new_order_status == 'print_invoice' && resolve['invoice_url']) {
                window.open(resolve.invoice_url, '_blank');
              }
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  confirm_order_update = (order_status) => {
    if (order_status == 'cancelled_order') {
      var order_update_header_text = "Confirm to cancel new order";
    }
    else if (order_status == 'confirm_order') {
      var order_update_header_text = "Confirm to confirmation of new order";
    }
    else if (order_status == 'download_manifests') {
      var order_update_header_text = "Download Manifests";
    }
    else if (order_status == 'print_invoice') {
      var order_update_header_text = "Download Invoice";
    }
    else if (order_status == 'rtd_order') {
      var order_update_header_text = "Generate Labels";
      const order_list_labels = this.state.new_order.filter(item => { return item.checked === true });
      this.setState({ order_list_labels: order_list_labels, item_in_order: [], order_Detail: [], shipment: [] })
      this.log_order_details(order_list_labels[0].order_id);
    }
    else if (order_status == 'pending_dispatch_Order') {
      var order_update_header_text = "Ready For Dispatch";
      const order_list_labels = this.state.new_order.filter(item => { return item.checked === true });
      this.setState({ order_list_labels: order_list_labels, item_in_order: [], order_Detail: [], shipment: [] })
      this.log_order_details(order_list_labels[0].order_id);
    }
    this.setState({
      new_order_status: order_status, model_status: true, order_update_header_text: order_update_header_text
    })
  }
  print_order_list = (new_order) => {
    if (new_order.length > 0) {
      this.props.history.push({ pathname: '/order-list-print', state: { new_order: new_order, backPath: this.state.current_path } });
    }
  }
  orderDetail = (order_id) => {
    this.setState({
      detail_model_status: true, order_id_selected: order_id
    })
  }
  track_order = (order_id) => {
    this.setState({
      track_model_status: true, order_id_selected: order_id
    })
  }
  print_order_detail = (new_order) => {
    if (new_order.length > 0) {
      var newList = [];
      new_order.map((item, index) => {
        newList.push(item['order_id']);
      })
      if (newList.length > 0) {
        this.props.history.push({ pathname: '/all-order-deatils-print', state: { new_order: newList, backPath: this.state.current_path } });
      }
    }
  }
  orderPrint = (OrderId) => {
    var newList = [];
    newList.push(OrderId);
    this.props.history.push({ pathname: '/all-order-deatils-print', state: { new_order: newList, backPath: this.state.current_path } });
  }
  check_all_order = (e) => {
    const new_order = this.state.new_order.map((item) => { return { ...item, checked: e.target.checked } });
    this.setState({ new_order: new_order, checkedAll: e.target.checked })
    if (new_order.filter(item => { return item.checked === true }).length > 0) {
      this.setState({ order_action_button: false })
    }
    else {
      this.setState({ order_action_button: true })
    }
  }
  update_input_value = (key, value) => {
    const detail = { ...this.state.order_Detail, [key]: value };
    this.setState({ order_Detail: detail })
  }
  update_courier_value = (key, value) => {
    const detail = { ...this.state.shipment, [key]: value };
    this.setState({ shipment: detail })
  }
  go_order_list = (order_type) => {
    this.props.history.push("order-list?order_state=" + order_type)
  }
  single_checked = (product, e) => {
    const new_order = this.state.new_order.map((item) => { return product.order_id === item.order_id ? { ...item, checked: e.target.checked } : item });
    this.setState({ new_order: new_order })
    if (new_order.filter(item => { return item.checked === true }).length === this.state.new_order.length) {
      this.setState({ checkedAll: true })
    }
    else {
      this.setState({ checkedAll: false })
    }
    if (new_order.filter(item => { return item.checked === true }).length > 0) {
      this.setState({ order_action_button: false })
    }
    else {
      this.setState({ order_action_button: true })
    }
  }
  componentDidMount = async () => {
    const current_path = this.props.location.pathname + this.props.location.search;
    const params = new URLSearchParams(this.props.location.search);
    const order_state = params.get('order_state');
    const courier_id = params.get('courier_id');
    if (courier_id) {
      this.setState({ courier_id_present: true, courier_company_id: courier_id });
    }
    this.logisticsList();
    setTimeout(async () => {
      if (order_state) {
        await this.allOrderList(order_state, current_path);
      }
      else {
        await this.allOrderList('', current_path);
      }
    }, 10);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.search != prevProps.location.search) {
      const next_params = new URLSearchParams(this.props.location.search);
      window.scroll({ top: 0, behavior: 'smooth' });
      const current_path = prevProps.location.pathname + prevProps.location.search;
      this.setState({ order_action_button: true })
      const order_state = next_params.get('order_state');
      if (order_state) {
        this.allOrderList(order_state, current_path);
      }
      else {
        this.allOrderList('', current_path);
      }
    }
  }
  handleCallback = () => {
    this.setState({ detail_model_status: false, track_model_status: false, order_id_selected: '' })
  }
  render() {
    const { data_status, new_order, admin_pincode, admin_area, courier_id_present, logistic_list, item_in_order, order_type, order_Detail, shipment } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
          <OrderDetailsMdl model_status={this.state.detail_model_status} order_id={this.state.order_id_selected} parentCallback={this.handleCallback} />
          <OrderTrackMdl model_status={this.state.track_model_status} order_id={this.state.order_id_selected} parentCallback={this.handleCallback} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Order List</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_from" value={this.state.order_date_from} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_to" value={this.state.order_date_to} className="form-control" />
                          </div>
                        </div>
                        {/* <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select id="order_type" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_type" value={this.state.order_type} className="form-control">
                              <option value="">All</option>
                              <option value="new_order">New Order</option>
                              <option value="confirm_order">Confirm</option>
                              <option value="dispatch_order">Dispatched</option>
                              <option value="complete_order">Delivered</option>
                              <option value="cancelled_order">Cancelled</option>
                              <option value="return_order">Return</option>
                            </select>
                          </div>
                        </div> */}
                        {/* <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="control-label"><span className="text-danger"></span>Order Pincode :</label>
                            <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_pincode" value={this.state.order_pincode} className="form-control" >
                              <option value="">Select option</option>
                              {
                                admin_pincode ?
                                  admin_pincode.map((pins, indexs) => {
                                    return (
                                      <option key={"option3" + indexs} value={pins.p_id}>{pins.pincode}</option>
                                    )
                                  })
                                  : ''}
                            </select>
                          </div>
                        </div> */}
                        {/* <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="control-label"><span className="text-danger"></span>Order Area :</label>
                            <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_area" value={this.state.order_area} className="form-control" >
                              <option value="">Select Area</option>
                              {
                                admin_area ?
                                  admin_area.map((areas, indexs) => {
                                    return (
                                      <option key={"option1" + indexs} value={areas.a_id}>{areas.area_name}</option>
                                    )
                                  })
                                  : ''}
                            </select>
                          </div>
                        </div>*/}
                        {(courier_id_present == false) ?
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="courier_company_id" value={this.state.courier_company_id} className="form-control" >
                                <option value="">Select Courier</option>
                                {
                                  logistic_list ?
                                    logistic_list.map((logList, logs) => {
                                      return (
                                        <option key={"option2" + logs} value={logList.courier_company_id}>{logList.courier_name}</option>
                                      )
                                    })
                                    : ''}
                              </select>
                            </div>
                          </div> : <></>}
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select id="p_mode" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="p_mode" value={this.state.p_mode} className="form-control">
                              <option value="">Payment Mode</option>
                              <option value="2">Online</option>
                              <option value="1">COD</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                          <div className="form-group">
                            <input type="text" placeholder='Order No./AWB/Mobile No./Pincode' onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_number" value={this.state.order_number} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 text-center">
                          <div className="form-group">
                            <button type="button" disabled={this.state.loading} onClick={() => this.get_order_search()} className="btn btn-primary btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Search</button>
                            <Button type="button" onClick={() => this.print_order_list(new_order)} className="ml-5 btn btn-info btn-sm"> Print</Button>
                            <Button type="button" onClick={() => this.print_order_detail(new_order)} className="ml-5 btn btn-warning btn-sm"> Print With Detail</Button>
                          </div>
                        </div>
                      </div>
                      {(courier_id_present == false) ?
                        <div className="card-body border-top bg-white">
                          <div className="row">
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('new_order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">New</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.newOrder || 0}</h4>
                              </div>
                            </div>
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('confirm_order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">Confirmed</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.confOrder || 0}</h4>
                              </div>
                            </div>
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('rtd_order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">Pending RTD</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.rtdOrder || 0}</h4>
                              </div>
                            </div>
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('pending_dispatch_Order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">Pending Dispatch</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.pending_dispatch_Order || 0}</h4>
                              </div>
                            </div>
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('dispatch_order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">Dispatched</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.dispOrder || 0}</h4>
                              </div>
                            </div>
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('complete_order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">Delivered</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.compOrder || 0}</h4>
                              </div>
                            </div>
                            <div className="col d-flex cursor" onClick={() => this.go_order_list('cancelled_order')}>
                              <div className="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                                <i className="mdi mdi-chart-arc icon-sm my-0 mx-1"></i>
                              </div>
                              <div className="wrapper pl-3">
                                <p className="mb-0 font-weight-semibold text-muted">Cancelled</p>
                                <h4 className="font-weight-semibold mb-0">{this.state.cancOrder || 0}</h4>
                              </div>
                            </div>
                          </div>
                        </div> : <></>}
                      <div className="row py-3">
                        {(order_type == 'new_order') ?
                          <div className="col-sm-12 text-center m-auto">
                            <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.order_action_button}>Cancel New Order</button>
                            <button className="btn btn-success btn-sm" onClick={() => this.confirm_order_update("confirm_order")} disabled={this.state.order_action_button}>Confirm New Order</button>
                          </div>
                          : (order_type == 'confirm_order') ?
                            <div className="col-sm-12 text-center m-auto">
                              <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.order_action_button}>Cancel Confirmed Order</button>
                              <button className="btn btn-success btn-sm" onClick={() => this.confirm_order_update("rtd_order")} disabled={this.state.order_action_button}>Generate Labels</button>
                            </div>
                            :
                            (order_type == 'rtd_order') ?
                              <div className="col-sm-12 text-center m-auto">
                                <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.order_action_button}>Cancel Order</button>
                                <button className="btn btn-success btn-sm mr-3" onClick={() => this.confirm_order_update("rtd_order")} disabled={this.state.order_action_button}>Regenerate Labels</button>
                                <button className="btn btn-info btn-sm" onClick={() => this.confirm_order_update("pending_dispatch_Order")} disabled={this.state.order_action_button}>Ready For Dispatch</button>
                              </div>
                              :
                              (order_type == 'pending_dispatch_Order') ?
                                <div className="col-sm-12 text-center m-auto">
                                  <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.order_action_button}>Cancel Order</button>
                                  <button className="btn btn-info btn-sm mr-3" onClick={() => this.confirm_order_update("pending_dispatch_Order")} disabled={this.state.order_action_button}>Again Request Pickup</button>
                                  <button className="btn btn-primary btn-sm mr-3" onClick={() => this.confirm_order_update("download_manifests")} disabled={this.state.order_action_button}>Download Manifest</button>
                                  <button className="btn btn-success btn-sm mr-3" onClick={() => this.confirm_order_update("print_invoice")} disabled={this.state.order_action_button}>Download Invoice</button>
                                </div>
                                : (order_type == 'dispatch_order') ?
                                  <div className="col-sm-12 text-center m-auto">
                                    <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("cancelled_order")} disabled={this.state.order_action_button}>Cancel Order</button>
                                    <button className="btn btn-primary btn-sm mr-3" onClick={() => this.confirm_order_update("download_manifests")} disabled={this.state.order_action_button}>Download Manifest</button>
                                    <button className="btn btn-info btn-sm mr-3" onClick={() => this.confirm_order_update("print_invoice")} disabled={this.state.order_action_button}>Download Invoice</button>
                                  </div>
                                  : (order_type == 'complete_order' || order_type == 'cancelled_order') ?
                                    <div className="col-sm-12 text-center m-auto">
                                      <button className="btn btn-danger btn-sm mr-3" onClick={() => this.confirm_order_update("print_invoice")} disabled={this.state.order_action_button}>Download Invoice</button>
                                    </div>
                                    : <>
                                    </>
                        }
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th><input type="checkbox" checked={this.state.checkedAll} onChange={(e) => this.check_all_order(e)} /></th>
                              <th>Order No.</th>
                              <th>Date</th>
                              <th>Name</th>
                              <th>Address</th>
                              <th>Courier</th>
                              <th>Amount</th>
                              <th><span>Shipping</span></th>
                              <th>Total</th>
                              <th><span title="Payment Mode">Mode</span></th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                new_order.map((list, index) => {
                                  return (
                                    <tr key={"row_order" + index}>
                                      <td>
                                        {(order_type == 'new_order' || order_type == 'complete_order' || order_type == 'cancelled_order' || order_type == 'confirm_order' || order_type == 'labels_order' || order_type == 'rtd_order' || order_type == 'pending_dispatch_Order') ?
                                          <input onChange={(e) => this.single_checked(list, e)} type="checkbox" checked={list.checked || false} />
                                          : <></>
                                        }
                                      </td>
                                      {/* <td>{index + 1}</td> */}
                                      <td>{list.invoiceNumber}</td>
                                      <td>{list.order_date}</td>
                                      <td>
                                        {list.muser_name}
                                        <br />
                                        {list.mobilenumber}
                                      </td>
                                      <td>{list.muser_address}, <br />{list.area_name} {list.city_name} <br />{list.state_name} {list.o_pincode_id}</td>
                                      <td>{
                                        (list.courier_name && list.awb_code) ?
                                          <>
                                            {list.courier_name}
                                            <br />
                                            AWB:{list.awb_code}
                                          </>
                                          :
                                          "---"
                                      }</td>
                                      <td>{list.order_amount}</td>
                                      <td>{list.shipping_amount}</td>
                                      <td>{list.final_amount}</td>
                                      <td>{fetch_payment_mode_string(list.p_mode)}</td>
                                      <td>
                                        <button className={"btn btn-xs " + fetch_status_btn_color(list.order_status)}>{fetch_status_string(list.order_status)}</button>
                                      </td>
                                      <td>
                                        <button onClick={() => this.orderDetail(list.order_id)} className="btn btn-sm btn-dark mr-2">View</button>
                                        <button onClick={() => this.track_order(list.order_id)} className="btn btn-sm btn-primary mr-2">Track</button>
                                        <button className="btn btn-sm btn-info" onClick={() => this.orderPrint(list.order_id)}>Print</button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="12" align="center">{this.state.loading_text}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.model_status == true ?
          <Modal size={this.state.new_order_status == 'rtd_order' ? "lg" : "md"} fade={false} isOpen={this.state.model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
            <ModalHeader close={<button className="close" onClick={() => this.setState({ model_status: false })}>X</button>}>
              <CardText className="modal-title">
                {this.state.order_update_header_text}
              </CardText>
            </ModalHeader>
            <ModalBody className="px-2 py-2">
              {(this.state.new_order_status == 'cancelled_order') ?
                <div className="row">
                  <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="control-label">Remark</label>
                      <textarea name="remarks" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" value={this.state.remarks}> </textarea>
                    </div>
                  </div>
                </div>
                : (this.state.new_order_status == 'rtd_order' || this.state.new_order_status == 'pending_dispatch_Order') ?
                  <>
                    <div className="card chat-app-wrapper">
                      <div className="row mx-0">
                        <div className="col-sm-3 chat-list-wrapper px-0">
                          <h4 className="py-3 text-center border-bottom">Order Id</h4>
                          <div className="chat-list-item-wrapper">
                            {this.state.order_list_labels.map(order => {
                              return (
                                <div key={"order" + order.order_id} className={'list-item border-bottom cursor ' + (order.order_id == this.state.order_id_selected ? 'order-selected' : '')} onClick={() => this.log_order_details(order.order_id)}>
                                  <h5>{order.invoiceNumber}</h5>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div className="col-sm-9 my-2">
                          <div className='pd-2 row'>
                            {this.state.new_order_status == 'rtd_order' ?
                              <>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_weight" className="control-label-sm"><span className="text-danger">*</span>Item Weight(In KG)</label>
                                    <input type="text" value={order_Detail.weight || 0} onChange={(e) => this.update_input_value('weight', e.target.value)} className="form-control" id="item_weight" />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_length" className="control-label-sm"><span className="text-danger">*</span>Item Length(In CMS)</label>
                                    <input type="text" value={order_Detail.item_length || 0} onChange={(e) => this.update_input_value('item_length', e.target.value)} className="form-control" id="item_length" />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_breadth" className="control-label-sm"><span className="text-danger">*</span>Item Breadth(In CMS)</label>
                                    <input type="text" value={order_Detail.breadth || 0} onChange={(e) => this.update_input_value('breadth', e.target.value)} className="form-control" id="item_breadth" />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label htmlFor="item_height" className="control-label-sm"><span className="text-danger">*</span>Item Height(In CMS)</label>
                                    <input type="text" value={order_Detail.height || 0} onChange={(e) => this.update_input_value('height', e.target.value)} className="form-control" id="item_height" />
                                  </div>
                                </div>
                                <div className="col-sm-2 mt-auto">
                                  <div className="form-group">
                                    <button className="btn btn-sm btn-info" disabled={this.state.loading} onClick={() => this.update_order_length_weight()} >{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</button>
                                  </div>
                                </div>
                              </>
                              : (this.state.new_order_status == 'pending_dispatch_Order') ?
                                <>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="new_pickup_scheduled" className="control-label-sm"><span className="text-danger">*</span>Scheduled Pickup Date </label>
                                      <input type="datetime-local" min={shipment.pickup_scheduled_date} value={shipment.new_pickup_scheduled || shipment.pickup_scheduled_date || ''} onChange={(e) => this.update_courier_value('new_pickup_scheduled', e.target.value)} className="form-control" id="new_pickup_scheduled" />
                                    </div>
                                  </div>
                                  <div className="col-sm-2 mt-auto">
                                    <div className="form-group">
                                      <button className="btn btn-sm btn-info" disabled={this.state.loading} onClick={() => this.update_pickup_scheduled_date()} >{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</button>
                                    </div>
                                  </div>
                                </>
                                : <></>}
                          </div>
                          <label className="control-label">Item In This Order</label>
                          <div className="table-responsive">
                            <table className="table mb-0 table-bordered text-center table-striped">
                              <thead>
                                <tr>
                                  <th width="50%">Item Info</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  item_in_order.length > 0 ?
                                    item_in_order.map((list, index) => {
                                      return (
                                        <tr key={"row1" + index}>
                                          <td>
                                            <div className="text-left" style={{ display: "flex" }}>
                                              <LazyLoad height={50} once>
                                                <img alt={list.product_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={apiUrl + list.p_img} />
                                              </LazyLoad>
                                              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                                <p className="text-info">{list.product_name.slice(0, 50)}</p>
                                                <p>SKU Id : {list.sku || '---'}</p>
                                                <p>HSN Code : {list.hsn || '---'}</p>
                                                <p>BarCode : {list.p_barcode || '---'}</p>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    : <tr><td colSpan="1" align="center">Item not found</td></tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : <></>}
            </ModalBody>
            <ModalFooter>
              <Button type="button" disabled={this.state.loading} onClick={() => this.update_order_status()} className="btn btn-danger btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Submit</Button>
            </ModalFooter>
          </Modal> : ''}
      </>
    );
  }
}
export default withRouter(OrderList);
