import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { call_api } from '../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
class ProfileLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      InputEmail: '',
      Inputpwd: '',
      loading: false,
    };
  }
  login() {
    this.setState({ loading: true })
    let InputEmail = this.state.InputEmail;
    let Inputpwd = this.state.Inputpwd;
    if (InputEmail == '') {
      notify.show('Inter valid user id', 'error', 2000);
      this.InputEmail.focus();
      this.setState({ loading: false });
    }
    else if (Inputpwd == '') {
      notify.show('Inter valid password!', 'error', 2000);
      this.Inputpwd.focus();
      this.setState({ loading: false })
    }
    else {
      call_api('a/service-login', this.state)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              if (resolve.owner_flag == 1) {
                localStorage.setItem('reg_Id', (resolve.reg_Id));
                localStorage.setItem('LoginToken', (resolve.LoginToken));
                localStorage.setItem('full_name', (resolve.full_name));
                localStorage.setItem('owner_Type', (resolve.owner_Type));
                localStorage.setItem('owner_flag', (resolve.owner_flag));
                localStorage.setItem('deviceToken', (resolve.deviceToken));
                notify.show(resolve.message, 'success', 2000);
                this.props.history.push('/dashboard');
              }
              else {
                notify.show("Invalid credential", 'error', 2000);
                this.setState({ loading: false })
              }
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  render() {
    return (
      <>
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
        <div className="container-scroller d-flex">
          <div className="container-fluid page-body-wrapper full-page-wrapper d-flex">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className="brand-logo mb-3">
                      <img src={logo} alt="Authorised Login" />
                    </div>
                    <h6 className="font-weight-light">Sign in to continue.</h6>
                    <div className="form-group">
                      <input type="text" ref={(input) => { this.InputEmail = input; }} className="form-control" onChange={(e) => this.setState({ InputEmail: e.target.value })} id="InputEmail" placeholder="User Id" />
                    </div>
                    <div className="form-group">
                      <input type="password" ref={(input) => { this.Inputpwd = input; }} className="form-control" required onChange={(e) => this.setState({ Inputpwd: e.target.value })} id="Inputpwd" autocomplete="off" placeholder="Password" />
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="buttom" disabled={this.state.loading} onClick={() => this.login()}>{this.state.loading && <i className="fa fa-spin fa-spinner"></i>} SIGN IN</button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          Keep me signed in
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ProfileLogin);
