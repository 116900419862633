import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import RatingShow from './RatingShow.js';
import FadeIn from "react-lazyload-fadein";
import notfoundImg from "../../assets/img/no_image.jpg";
class Rating_reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: false,
      ratingDetailModel: false,
      image_model_status: false,
      data_status: false,
      date_1: '',
      detail_date_1: '',
      search_product: '',
      search_product2: '',
      date_2: '',
      detail_date_2: '',
      prod_search_id: '',
      product_id: '',
      message: 'Loading Please Wait',
      datalist: [],
      productlist: [],
      ratinglist_temp: [],
      image_list: [],
      ratinglist: [],
    };
  }
  componentDidMount() {
    this.fetch_rating_list();
    this.fetch_product_list();
  }
  fetch_rating_list() {
    let date_1 = this.state.date_1;
    let date_2 = this.state.date_2;
    let prod_search_id = this.state.prod_search_id;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'), 'date_1': date_1, 'date_2': date_2,
      'prod_search_id': prod_search_id, 'search_product': this.state.search_product
    }
    call_api('a/rating-reviews', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ datalist: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ message: resolve.message, datalist: [], data_status: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_product_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'search': '',
      'limit': 100,
      'start': 0,
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/product-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ productlist: resolve.data || [] })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_rating_detail = () => {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'date_1': this.state.detail_date_1,
      'date_2': this.state.detail_date_2,
      'search_product': this.state.search_product2,
      'product_id': this.state.product_id,
    }
    this.setState({ ratinglist: [], ratinglist_temp: [], ratingDetailModel: true });
    call_api('a/rating-reviews-detail', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ ratinglist: resolve.data || [], ratinglist_temp: resolve.data || [] });
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  deleteInialize = (ratingId) => {
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        onCancel={() => { this.setState({ alert: null }); }}
        onConfirm={() => { this.confirm_rating_delete(ratingId) }} >
        You will not be able to recover this imaginary file
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });

  }
  confirm_rating_delete(ratingId) {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'rating_id': ratingId
    }
    this.setState({ alert: null, loading: true });
    call_api('a/rating-reviews-remove', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            const items = this.state.datalist.filter(item => item.ratingId !== ratingId);
            this.setState({ datalist: items || [], loading: false })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false })
          }
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading: false })
        }
      )
  }

  view_image_list = (image) => {
    if (image != '') {
      this.setState({ image_list: JSON.parse(image) });
    }
    this.setState({ image_model_status: true });
  }
  render() {
    const { alert, data_status, image_model_status, image_list, datalist, productlist, ratinglist } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: 99999 }} />
          {alert}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Rating & Reviews </h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <select className="form-control select2" name="prod_search_id" value={this.state.prod_search_id} onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                              <option value="0">Select Product</option>
                              {productlist.map((list, index) => {
                                return (
                                  <option key={"sch" + index} value={list.product_id}>{list.prod_name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 col-sm-3 col-md-3">
                          <div className="form-group">
                            <input type="text" name="search_product" value={this.state.search_product} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" placeholder="Item Name/HSN/Style Code" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="date_1" value={this.state.date_1} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="date_2" value={this.state.date_2} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-5 col-xs-12 col-sm-2 col-md-2">
                          <div className="mt-30">
                            <Button type="button" disabled={this.state.loading} onClick={() => this.fetch_rating_list()} className="btn btn-primary mr-5 btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Search</Button>
                            <Button type="button" disabled={this.state.loading} onClick={() => this.fetch_rating_detail()} className="btn btn-warning btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Product Rating Detail</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center table-striped">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Product</th>
                              <th>SKU Id</th>
                              <th>Size</th>
                              <th>Barcode</th>
                              <th>User</th>
                              <th>Contact</th>
                              <th>Rating</th>
                              <th>Comments</th>
                              <th>Created</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                datalist.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.p_name}</td>
                                      <td>{list.product_code}</td>
                                      <td>{list.product_size}</td>
                                      <td>{list.p_barcode}</td>
                                      <td>{list.muser_name}</td>
                                      <td>{list.muser_mobno}</td>
                                      <td>{list.ratingNumber}<i className="fa fa-star"></i></td>
                                      <td>{list.comments}</td>
                                      <td>{list.created}</td>
                                      <td>
                                        <button onClick={() => this.view_image_list(list.helpfullImages)} className="btn btn-xs btn-info"> Image</button>
                                        {/* <button disabled={this.state.loading} onClick={() => this.deleteInialize(list.ratingId)} className="btn btn-xs btn-danger">{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Delete</button> */}
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="11" align="center">{this.state.message}</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <Modal size="md" fade={false} isOpen={image_model_status} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ image_model_status: false, image_list: [] })}>X</button>}>
            <CardText className="modal-title">
              Product Rating Image
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  {image_list.map((item, index) => {
                    return (
                      <div className="col-md-3 mt-2 text-center" key={index}>
                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                          {onload => (
                            <img alt={"Product Image"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" src={item.image} onLoad={onload} style={{ cursor: 'pointer', maxHeight: '200px', width: '100%' }} />
                          )}
                        </FadeIn>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <>
            </>
          </ModalFooter>
        </Modal>
        <Modal size="lg" fade={false} isOpen={this.state.ratingDetailModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ ratingDetailModel: false, ratinglist: [] })}>X</button>}>
            <CardText className="modal-title">
              Product Rating Detail
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <select className="form-control select2" name="product_id" value={this.state.product_id || "0"} onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                        <option value="0">Select Product</option>
                        {productlist.map((list, index) => {
                          return (
                            <option key={"sch" + index} value={list.product_id}>{list.prod_name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-sm-3 col-md-3">
                    <div className="form-group">
                      <input type="text" name="search_product2" value={this.state.search_product2} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" placeholder="Item Name/HSN/Style Code" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="detail_date_1" value={this.state.detail_date_1} className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                    <div className="form-group">
                      <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="detail_date_2" value={this.state.detail_date_2} className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-5 col-xs-12 col-sm-2 col-md-2">
                    <div className="mt-30">
                      <Button type="button" disabled={this.state.loading} onClick={() => this.fetch_rating_detail()} className="btn btn-warning btn-sm"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Search</Button>
                    </div>
                  </div>
                </div>
                <table className="table table-striped table-bordered fixed_tbl table-sm text-center">
                  <thead className="t_head">
                    <tr>
                      <th>S.No.</th>
                      <th>Product</th>
                      <th>SKU Id</th>
                      <th>Rating</th>
                      <th width="40%">Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ratinglist.map((list, index) => {
                        return (
                          <tr key={"rd_row" + index}>
                            <td>{index + 1}</td>
                            <td>{list.p_name}</td>
                            <td>{list.product_code}</td>
                            <td>{list.total_count > 0 ? (parseInt(list.ratingNumber) / parseInt(list.total_count)).toFixed(1) : 0}<i className="fa fa-star"></i></td>
                            <td width="40%">
                              <RatingShow data={list.list} total_count={list.total_count} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <>
            </>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default Rating_reviews;