import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { call_api, } from '../connect/Connect.js';
import { fetch_status_string, fetch_payment_mode_string, fetch_status_btn_color } from '../components/General_data.js';
//import img_5 from '../assets/img/img_5.jpg';
import Footer from '../components/Footer';
import OrderDetailsMdl from './order/OrderDetailsMdl.js';
import OrderTrackMdl from './order/OrderTrackMdl.js';
import Notifications, { notify } from 'react-notify-toast';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      new_order_list: [],
      new_order_count: 0,
      conf_order_count: 0,
      des_order_count: 0,
      comp_order_count: 0,
      detail_model_status: false,
      track_model_status: false,
      canc_order_count: 0,
      order_id_selected: '',
      return_order_count: 0,
      new_order_sum: 0,
      tot_order_sum: 0,
      pending_dispatch_Order: 0,
      reg_Id: "---",
      comp_order_sum: 0,
      canc_order_sum: 0,
      rtdOrder: 0,
      tot_product: 0,
      active_product: 0,
      inactive_prod: 0,
      log_count: 0,
      tot_city: 0,
      order_load_status: false,
      loading_text: 'Data Loading Please Wait',
      tot_pincode: 0,
      tot_area: 0,
      tot_user: 0
    };
  }
  go_order_list(order_type) {
    window.open("/order-list?order_state=" + order_type, '_self');
  }
  componentDidMount() {
    var LoginToken = localStorage.getItem('LoginToken');
    var deviceToken = localStorage.getItem('deviceToken');
    const reg_Id = localStorage.getItem('reg_Id');
    if (reg_Id) {
      this.setState({ reg_Id: reg_Id });
    }
    //var ua = window.navigator.userAgent;
    call_api('a/check-login-session', { 'LoginToken': LoginToken, 'deviceToken': deviceToken })
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.getAllOrderCount();
            //this.getAllOrderAmountSum();
            this.getAllProductCount();
            //this.getAllLocationCount();
            this.todayAllOrderReport();
          }
          else {
            this.props.history.push('/service-login');
          }
        })
  }
  getAllOrderCount() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
    }
    call_api('a/admin-order-count', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({
              new_order_count: resolve.newOrder, conf_order_count: resolve.confOrder, des_order_count: resolve.dispOrder,
              rtdOrder: resolve.rtdOrder, pending_dispatch_Order: resolve.pending_dispatch_Order,
              comp_order_count: resolve.compOrder, canc_order_count: resolve.cancOrder, return_order_count: resolve.ret_order
            })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  getAllOrderAmountSum() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
    }
    call_api('a/admin-order-sum', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ new_order_sum: resolve.newOrder, tot_order_sum: resolve.totOrder, comp_order_sum: resolve.compOrder, canc_order_sum: resolve.cancOrder })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  getAllProductCount() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
    }
    call_api('a/admin-product-count', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ tot_product: resolve.totProduct, active_product: resolve.actProduct, inactive_prod: resolve.inProduct, log_count: resolve.logistics })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  getAllLocationCount() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
    }
    call_api('a/admin-location-count', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ tot_city: resolve.totCity, tot_pincode: resolve.totPincode, tot_area: resolve.totArea, tot_user: resolve.totUser })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  todayAllOrderReport() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
    }
    call_api('a/admin-today-all-order', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ new_order_list: resolve.data, order_load_status: true })
          }
          else {
            this.setState({ loading_text: resolve.message })
          }
        },
        (reject) => {
          console.log(reject);
          this.setState({ loading_text: "Record not loaded" })
        }
      )
  }
  orderDetail = (order_id) => {
    this.setState({
      detail_model_status: true, order_id_selected: order_id
    })
  }
  handleCallback = () => {
    this.setState({ detail_model_status: false, track_model_status: false, order_id_selected: '' })
  }
  track_order = (order_id) => {
    this.setState({
      track_model_status: true, order_id_selected: order_id
    })
  }
  render() {
    const { new_order_count, new_order_list, order_load_status, conf_order_count, des_order_count, comp_order_count,
      canc_order_count, return_order_count, comp_order_sum, canc_order_sum, tot_order_sum, tot_product, active_product, inactive_prod,
      log_count, tot_city, tot_pincode, tot_area, tot_user, rtdOrder, pending_dispatch_Order } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
          <OrderDetailsMdl model_status={this.state.detail_model_status} order_id={this.state.order_id_selected} parentCallback={this.handleCallback} />
          <OrderTrackMdl model_status={this.state.track_model_status} order_id={this.state.order_id_selected} parentCallback={this.handleCallback} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <a href='/order-list' className="btn btn-primary mb-2 mb-md-0 mr-2"> Order List </a>
                  <a href="/user-list" className="btn btn-outline-primary mb-2 mb-md-0"> User List </a>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">
                    <a href="/">
                      <p className="m-0 pr-3">Dashboard</p>
                    </a>
                    <a className="pl-3 mr-4" href="#">
                      <p className="m-0">{this.state.reg_Id}</p>
                    </a>
                  </div>
                  <a href='/product-list?productAdd' className="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                    <i className="mdi mdi-plus-circle"></i> Add Prodcut </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 col-xl-3 stretch-card grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-warning">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('new_order')}>
                          <h4 className="font-weight-bold text-warning mb-0"> {new_order_count} </h4>
                          <p className="text-muted">New Order</p>
                        </div>
                      </div>
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-info">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('confirm_order')}>
                          <h4 className="font-weight-bold text-info mb-0"> {conf_order_count} </h4>
                          <p className="text-muted">Confirm Order</p>
                        </div>
                      </div>
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-info">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('rtd_order')}>
                          <h4 className="font-weight-bold text-info mb-0"> {rtdOrder} </h4>
                          <p className="text-muted">Pending RTD</p>
                        </div>
                      </div>
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-warning">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('pending_dispatch_Order')}>
                          <h4 className="font-weight-bold text-warning mb-0"> {pending_dispatch_Order} </h4>
                          <p className="text-muted">Pending Dispatch</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-xl-3 stretch-card grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-warning">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('dispatch_order')}>
                          <h4 className="font-weight-bold text-warning mb-0"> {des_order_count} </h4>
                          <p className="text-muted">Dispatched Order</p>
                        </div>
                      </div>
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-success">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('complete_order')}>
                          <h4 className="font-weight-bold text-success mb-0"> {comp_order_count} </h4>
                          <p className="text-muted">Delivered Order</p>
                        </div>
                      </div>
                      <div className="d-flex border-bottom mb-4 pb-2">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-info">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('cancelled_order')}>
                          <h4 className="font-weight-bold text-info mb-0"> {canc_order_count} </h4>
                          <p className="text-muted mb-0">Cancelled Order</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="hexagon">
                          <div className="hex-mid hexagon-danger">
                            <i className="mdi mdi-clock-outline"></i>
                          </div>
                        </div>
                        <div className="pl-4 cursor" onClick={() => this.go_order_list('return_order')}>
                          <h4 className="font-weight-bold text-danger mb-0"> {return_order_count} </h4>
                          <p className="text-muted mb-0">Return Order</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-xl-3 stretch-card grid-margin">
                  <div className="card color-card-wrapper">
                    <div className="card-body">
                      {/* <img className="img-fluid card-top-img w-100" src={img_5} alt="product Listing" /> */}
                      <div className="d-flex flex-wrap justify-content-around color-card-outer">
                        <div className="col-6 p-0 mb-4">
                          <div className="color-card primary m-auto">
                            <i className="mdi mdi-tshirt-crew"></i>
                            <p className="font-weight-semibold mb-0">Total Listing</p>
                            <span className="small">{tot_product} Product</span>
                          </div>
                        </div>
                        <div className="col-6 p-0 mb-4">
                          <div className="color-card bg-success m-auto">
                            <i className="mdi mdi-trophy-outline"></i>
                            <p className="font-weight-semibold mb-0">Aproved  Listing</p>
                            <span className="small">{active_product} Product</span>
                          </div>
                        </div>
                        <div className="col-6 p-0">
                          <div className="color-card bg-danger m-auto">
                            <i className="mdi mdi-clock-outline"></i>
                            <p className="font-weight-semibold mb-0">Pending Listing</p>
                            <span className="small">{inactive_prod} Product</span>
                          </div>
                        </div>
                        <div className="col-6 p-0">
                          <div className="color-card bg-info m-auto">
                            <i className="mdi mdi-clock"></i>
                            <p className="font-weight-semibold mb-0">Self Service</p>
                            <span className="small">{tot_user} User</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-xl-12 stretch-card grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title mb-2">Latest Order</div>
                      {/* <h3 className="mb-3">23 september 2021</h3> */}
                      <div className="d-flex border-top py-3">
                        <div className="table-responsive">
                          <table className="table mb-0 text-center table-striped">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Order No.</th>
                                <th>Date</th>
                                <th>Courier</th>
                                <th>Name</th>
                                <th>Contact</th>
                                <th>Address</th>
                                <th>Amount</th>
                                <th><span title="Shipping Charge">Shipping</span></th>
                                <th>Amount</th>
                                <th><span title="Payment Mode">Mode</span></th>
                                <th>Status</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                order_load_status == true ?
                                  new_order_list.map((list, index) => {
                                    return (
                                      <tr key={"row_order" + index}>
                                        <td>{index + 1}</td>
                                        <td>{list.invoiceNumber}</td>
                                        <td>{list.order_date}</td>
                                        <td>{list.courier_name || '---'}</td>
                                        <td>{list.muser_name}</td>
                                        <td>{list.mobilenumber}</td>
                                        <td>{list.muser_address}, <br />{list.area_name} {list.city_name} {list.o_pincode_id}</td>
                                        <td>{list.order_amount}</td>
                                        <td>{list.shipping_amount}</td>
                                        <td>{list.final_amount}</td>
                                        <td>{fetch_payment_mode_string(list.p_mode)}</td>
                                        <td>
                                          <button className={"btn btn-xs " + fetch_status_btn_color(list.order_status)}>
                                            {fetch_status_string(list.order_status)}
                                          </button>
                                        </td>
                                        <td>
                                          <button onClick={() => this.orderDetail(list.order_id)} className="btn btn-xs btn-dark mr-2">View</button>
                                          <button onClick={() => this.track_order(list.order_id)} className="btn btn-xs btn-primary mr-2">Track</button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  : <tr><td colSpan="13" align="center">{this.state.loading_text}</td></tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Dashboard);
