import React, { Component } from 'react';
import LocationModel from '../components/master/LocationModel.js';
import { call_api } from '../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
class PincodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data_status: false,
      model_status: false,
      state_id: 22,
      pincode_list: [],
      modelData: '',
      country_state_city: [],
      tem_pinc_list: []
    };
  }
  componentDidMount() {
    this.fetch_location(); 
    this.load_pincode_list(); 
  }
  load_pincode_list = ()=>
  {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag':localStorage.getItem('owner_flag')
    }
    call_api('a/admin-pincode', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ pincode_list: resolve.data, tem_pinc_list:resolve.data, isLoading: false, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  handleCallback = (type, status) => {
    this.setState({ model_status: status });
    if (type == 'pincode' && status == false) {
      this.load_pincode_list();
    }
  }
  fetch_location = () => {
    call_api('m/fetch_country_state_city_option', {})
      .then(
        (resolve) => {
          if (resolve['status'] == 1) {
            this.setState({ country_state_city: resolve['data'], pincode: resolve['pincode'], model_status: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  filter_list_data = (type, id) => {
    let list = this.state.tem_pinc_list;
    if (type == 'country_id') 
    {
      var filter_data = list.filter(item => item.countryId === id);
      this.setState({state_id:0});
    }
    else if (type == 'state_id') 
    {
      var filter_data = list.filter(item => item.stateCode === id);
    }
    else
    {
      var filter_data = list.filter(item => item.cityId === id);
    }
    this.setState({
      pincode_list: filter_data
    })
  }

  editData = (list,modelType)=>{
    this.setState({ modelData: list,model_status:true,modelType:modelType })
    
  }
  changeStatus(pincode_id, p_status) {
    let optopns = { 'p_status': p_status, 'pincode_id': pincode_id };
    call_api('a/pincode-status', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ pincode_list: resolve.data, tem_pinc_list: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ isLoading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  render() {
    const { isLoading, data_status, pincode_list,modelData,model_status } = this.state;
    return (
      <div>
        <LocationModel parentCallback={this.handleCallback} model_status={model_status} modelType={this.state.modelType} modelData={modelData} />
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
          <div className="page-wrapper" style={{ minHeight: "359px" }}>
            <div className="container-fluid">
              <div className="row heading-bg">
                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                  <h5 className="txt-dark">Pincode List</h5>
                </div>
                <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                  <ol className="breadcrumb">
                    <li><a href="/">Dashboard</a></li>
                    <li className="active"><span>Pincode</span></li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="panel panel-default card-view">
                    <div className="panel-wrapper collapse in">
                      <div className="panel-body">
                      <button className="btn btn-xs btn-success" onClick={()=>this.editData('','addpincode')}>Add New Pincode</button>
                        <div className="row" style={{ marginTop: "10px" }}>
                          {/* <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="col-md-4 control-label"><span className="text-danger"></span>Country</label>
                              <div className="col-md-8">
                                <select name="country_id" value={this.state.country_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('country_id', e.target.value) }} className="form-control" id="country_id">
                                  <option key={"c00"} value="">Select Country</option>
                                  {this.state.country_state_city.map((country, index) => {
                                    return (
                                      (country.l_master_type == 1) ?
                                        <option key={'c' + index} value={country.id}>{country.name}</option>
                                        : ''
                                    )
                                  }
                                  )
                                  }
                                </select>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="col-md-4 control-label"><span className="text-danger"></span>State</label>
                              <div className="col-md-8">
                                <select ref="state_id" name="state_id" value={this.state.state_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('state_id', e.target.value) }} className="form-control" id="state_id">
                                  <option key={"s00"}>Select State</option>
                                  {this.state.country_state_city.map((state, index) => {
                                    return (
                                      (state.l_master_type == 2 && this.state.country_id == state.l3) ?
                                        <option key={'c' + index} value={state.l1}>{state.name}</option>
                                        : ''
                                    )
                                  }
                                  )
                                  }
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="col-md-4 control-label"><span className="text-danger"></span>City</label>
                              <div className="col-md-8">
                              <select ref="city_id" name="city_id" value={this.state.city_id || ''} onChange={(e) => {this.setState({ [e.target.name]: e.target.value }); this.filter_list_data('city_id', e.target.value) }} className="form-control" id="city_id">
                                <option key={"c00"}>Select City</option>
                                {this.state.country_state_city.map((city, index) => {
                                    return (
                                        (city.l_master_type == 3 && this.state.state_id == city.l3) ?
                                            <option key={'c' + index} value={city.id}>{city.name}</option>
                                            : ''
                                    )
                                }
                                )
                                }
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="table-wrap mt-40">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Contry Name</th>
                                  <th>State Name</th>
                                  <th>City Name</th>
                                  <th>Pincode</th>
                                  <th>Service Status</th>
                                  <th>Edit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  data_status == true ?
                                  pincode_list.map((list, index) => {
                                      return (
                                        <tr key={"row" + index}>
                                          <td>{index + 1}</td>
                                          <td>{list.country_name}</td>
                                          <td>{list.state_name}</td>
                                          <td>{list.city_name}</td>
                                          <td>{list.pincode}</td>
                                          <td>{list.p_status == 1 ? <button className="btn btn-success btn-xs" onClick={(e) => this.changeStatus(list.p_id, 2)}>Active</button> : <button className="btn btn-danger btn-xs" onClick={(e) => this.changeStatus(list.p_id, 1)}>Inactive</button>}</td>
                                          <td><button className="btn btn-xs btn-success" onClick={()=>this.editData(list,'pincode')}>Edit</button></td>
                                        </tr>
                                      )
                                    })
                                    : <tr><td colSpan="7" align="center">Please Wait</td></tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
export default PincodeList;
