import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
class PasswordForget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			client_contact: '',
			mobileIsValid: false,
			loading: false,
			error_message: '',
			alert: null
		};
	}
	render() {
		return (
			<div>
				{this.state.alert}
				<div className="wrapper pa-0">
					<div className="page-wrapper pa-0 ma-0 auth-page">
						<div className="container-fluid">
							<div className="table-struct full-width full-height">
								<div className="table-cell vertical-align-middle auth-form-wrap">
									<div className="auth-form  ml-auto mr-auto no-float">
										<div className="row">
											<div className="col-sm-12 col-xs-12">
												<div className="sp-logo-wrap text-center pa-0 mb-30">
													<a href="/">
														<img className="brand-img mr-10" src={logo} alt="A1Mart" />
													</a>
												</div>
												<div className="mb-30">
													<h6 className="text-center txt-grey nonecase-font">Enter the email you use for A1Mart - Admin, and we’ll help you create a new password.</h6>
												</div>
												<div className="form-wrap">
													<div className="form-group">
														<label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email address</label>
														<input type="email" className="form-control" required="" id="exampleInputEmail_2" placeholder="Enter email" />
													</div>
													<div className="form-group text-center">
														<button type="submit" className="btn btn-info btn-rounded">Reset</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(PasswordForget);
