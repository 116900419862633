import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
class MasterUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      unit_name: false,
      editId: 0,
      displayModel: false,
      datalist: [],
    };
  }
  componentDidMount() {

    this.fetch_unit_setting();
  }

  fetch_unit_setting()
  {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/setting-unit', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ datalist: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  edit_unit =(data) =>{
    this.setState({ editId: data.id,unit_name:data.unit_name,displayModel:true,headerTitle:'Edit Unit' })
  }
  add_unit =() =>{
    this.setState({ editId: 0,unit_name:"",displayModel:true,headerTitle:'Add New Unit' })
  }
  save_update_unit =() =>{
    let unit_name = this.state.unit_name;
    let editId = this.state.editId;
    if (unit_name == '') {
      this.refs.unit_name.focus();
    }
    else
    {
      this.setState({ loading: true })
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'unit_name': unit_name,
        'editId': editId
      }
      this.setState({loading:true });
      call_api('a/setting-unit-save-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ datalist: resolve.data, loading: false, displayModel:false,loading:false })
              notify.show(resolve.message, 'success', 2000);
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false,displayModel:false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  render() {
    const { error, data_status, datalist } = this.state;
    return (
      <div>
        <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
          <div className="page-wrapper" style={{ minHeight: "359px" }}>
            <div className="container-fluid">
              <div className="row heading-bg">
                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                  <h5 className="txt-dark">Product Unit Setting</h5>
                </div>
                <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                  <ol className="breadcrumb">
                    <li><a href="/">Dashboard</a></li>
                    <li className="active"><span>Product Unit Setting</span></li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="panel panel-default card-view">
                    <div className="panel-wrapper collapse in">
                      <div className="panel-body">
                        <p className="text-muted">Product Unit</p>
                        <div className="table-wrap mt-40">
                        <button className="btn btn-info btn-xs" onClick={()=>this.add_unit()}><i className="fa fa-plus"></i>Add New Unit</button>
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>
                                <th>S.No.</th>
                                <th>Unit</th>
                                <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  data_status == true ?
                                  datalist.map((list, index) => {
                                      return (
                                        <tr key={"row" + index}>
                                          <td>{index + 1}</td>
                                          <td>{list.unit_name}</td>
                                          <td>
                                            <button className="btn btn-info btn-xs" onClick={()=>this.edit_unit(list)}><i className="fa fa-pencil"></i></button>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    : <tr><td colSpan="3" align="center">Please Wait</td></tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal size="md" fade={false} isOpen={this.state.displayModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader>
            <button type="button" className="close" onClick={() => this.setState({ displayModel: false })} aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <CardText className="modal-title">
              {this.state.headerTitle || 'Add New Unit'}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="form-wrap form-horizontal">
              <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="unit_name" className="col-md-4 control-label"><span className="text-danger">*</span>Unit Name</label>
                  <div className="col-md-8">
                    <input type="text" name="unit_name" ref="unit_name" value={this.state.unit_name || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="unit_name" />
                    <span className="text-danger"></span>
                  </div>
                </div>                
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <>
                <button className="btn btn-success btn-sm" disabled={this.state.loading} onClick={() => this.save_update_unit()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Save</button>
            </>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default MasterUnit;