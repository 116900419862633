import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Button } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
class OrderReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      isLoading: true,
      data_status: false,
      order_reports: [],
      order_id: 0,
      order_status: '',
      order_date_from: '',
      order_date_to: '',
      p_mode: '',
      order_pincode: '',
      order_type: '',
      logistic_list: [],
      courier_company_id: '',
      order_number: ''
    };
  }
  componentDidMount() {
    this.logisticsList();
    this.get_order_search();
  }
  logisticsList() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/order-courier-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ logistic_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  get_order_search() {
    let options = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'order_date_from': this.state.order_date_from,
      'order_date_to': this.state.order_date_to,
      'order_pincode': this.state.order_pincode,
      'courier_company_id': this.state.courier_company_id,
      'order_number': this.state.order_number,
      'p_mode': this.state.p_mode,
      'order_status': this.state.order_status
    };
    call_api('a/order-reports', options)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ order_reports: resolve.data, isLoading: false, data_status: true, orderStatus: false })
          }
          else {
            this.setState({ order_reports: [] })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  render() {
    const { isLoading, data_status, order_reports, logistic_list } = this.state;
    var total_amount = 0;
    var shipping_amount = 0;
    var order_amount = 0;
    return (
      <>
        {isLoading == true ?
          'Please Wait'
          :
          <div className="container-fluid page-body-wrapper">
            <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
            <div className="main-panel">
              <div className="content-wrapper pb-0">
                <div className="page-header flex-wrap">
                  <div className="header-left">
                    <h3 className="page-title">Order Report Search</h3>
                  </div>
                  <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                    <div className="d-flex align-items-center">

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_from" value={this.state.order_date_from} className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_to" value={this.state.order_date_to} className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="courier_company_id" value={this.state.courier_company_id} className="form-control" >
                                <option value="">Select Courier</option>
                                {
                                  data_status == true ?
                                    logistic_list.map((logList, logs) => {
                                      return (
                                        <option key={"option2" + logs} value={logList.courier_company_id}>{logList.courier_name}</option>
                                      )
                                    })
                                    : ''}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <select id="p_mode" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="p_mode" value={this.state.p_mode} className="form-control">
                                <option value="">Payment Mode</option>
                                <option value="2">Online</option>
                                <option value="1">COD</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <input type="text" placeholder='Order Number/AWB/Pincode' onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_number" value={this.state.order_number} className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-2 col-xs-12 col-sm-3 col-md-2">
                            <div className="form-group">
                              <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_status" value={this.state.order_status} className="form-control" >
                                <option value="0">Select Status</option>
                                <option value="1">New Order</option>
                                <option value="2">Confirm Order</option>
                                <option value="3">Pending RTD</option>
                                <option value="4">Pending Dispatch</option>
                                <option value="5">Dispatch Order</option>
                                <option value="6">Complete Order</option>
                                <option value="7">Cancelled Order</option>
                                <option value="8">Return Order</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 text-center">
                            <div className="form-group mt-1">
                              <Button type="button" disabled={this.state.loading} onClick={() => this.get_order_search()} className="btn btn-info btn-sm"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Search</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table mb-0 text-center">
                            <thead>
                              <tr>
                                <th>Payment Mode</th>
                                <th>Order Amount</th>
                                <th>Shipping Charge</th>
                                <th>Total Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order_reports.map((item, index) => {
                                order_amount += parseFloat(item.order_amount);
                                shipping_amount += parseFloat(item.shipping_amount);
                                total_amount += parseFloat(item.total_amount);
                                return (
                                  <tr key={index}>
                                    <td>{item.p_mode == 1 ? 'COD' : 'Online'}</td>
                                    <td>{item.order_amount || 0}</td>
                                    <td>{item.shipping_amount || 0}</td>
                                    <td>{item.total_amount || 0}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>Total</td>
                                <td>{order_amount}</td>
                                <td>{shipping_amount}</td>
                                <td>{total_amount}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
export default withRouter(OrderReport);
