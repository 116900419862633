import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import notfoundImg from "../../assets/img/no_image.jpg";
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { slider_jquery } from "../../components/jquery/Web_jquery.js";
import { fetch_slider_stattus_string } from "../../components/General_data.js";
import FadeIn from "react-lazyload-fadein";
import Notifications, { notify } from 'react-notify-toast';
class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      modalShow: false,
      editId: 0,
      categoryId: 0,
      cat_brand_type: 0,
      dataContent: "",
      datalist: [],
      categorylist: [],
      brand_list: [],
    };
  }
  componentDidMount() {
    slider_jquery(apiUrl, call_api);
    this.load_slider_list();
    let mythis = this;
    setTimeout(function () {
      mythis.fetch_category();
    }, 2000);
    setTimeout(function () {
      mythis.fetch_brand_list();
    }, 3000);
  }
  load_slider_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/web-slider', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            let filterSliderType = this.state.filterSliderType || '';
            this.setState({ temp_datalist: resolve['data'], data_status: true })
            if (filterSliderType == '') {
              this.setState({ datalist: resolve['data'] })
            }
            else {
              var filter_data = resolve['data'].filter(item => item.sliderType == filterSliderType);
              this.setState({ datalist: filter_data })
            }
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  dataEdit = (dataContent) => {
    this.setState({
      modalShow: true, cat_brand_type: dataContent.cat_brand_type, editId: dataContent.s_id, categoryId: dataContent.categoryId, slider_series: dataContent.serial,
      sliderTitle: dataContent.title, slider_position: dataContent.sliderType, headerTitle: 'Edit Slider',
      slider_link: dataContent.weblink, temp_image: apiUrl + dataContent.image
    });
  }
  addNewSlider = () => {
    this.setState({
      modalShow: true, slider_series: '0', cat_brand_type: 0, categoryId: 0, editId: 0, headerTitle: 'Add New Slider',
      temp_image: '', slider_link: '', sliderTitle: ''
    });
  }
  changePriveImage(event) {
    let image = event.target.files[0];
    if (image != null) {
      if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg") {
        this.setState({ temp_image: URL.createObjectURL(image) });
      }
    }
  }
  save_update_slider = () => {
    let sliderTitle = this.state.sliderTitle || '';
    let slider_link = this.state.slider_link || '';
    let slider_series = this.state.slider_series || '0';
    let categoryId = this.state.categoryId || '0';
    let cat_brand_type = this.state.cat_brand_type || '0';
    let slider_position = this.state.slider_position || '';
    let editId = this.state.editId || '0';
    let imageurl = '';
    var file = this.refs.slider_image.files[0];
    if (sliderTitle == '') {
      this.refs.sliderTitle.focus();
      return false;
    }
    else if (slider_link == '') {
      this.refs.slider_link.focus();
      return false;
    }
    else if (slider_series == '') {
      this.refs.slider_series.focus();
      return false;
    }
    else if (slider_position == '') {
      this.refs.slider_position.focus();
      return false;
    }
    else if (editId == '0' && file == '') {
      this.refs.slider_image.focus();
      return false;
    }
    this.setState({ loading: true })
    if (file != null) {
      if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/webp") {
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          imageurl = reader.result;
        }
      }
    }
    var self = this;
    setTimeout(function () {
      let optopns = {
        'LoginToken': localStorage.getItem('LoginToken'),
        'sliderTitle': sliderTitle,
        'slider_series': slider_series,
        'slider_position': slider_position,
        'editId': editId,
        'cat_brand_type': cat_brand_type,
        'categoryId': categoryId,
        'slider_image': imageurl,
        'slider_link': slider_link
      }
      call_api('a/add-update-slider', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              self.setState({ loading: false, modalShow: false });
              notify.show(resolve.message, 'success', 2000);
              self.load_slider_list();
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              self.setState({ loading: false });
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }, 1000);
  }
  deleteSlider = (sliderId) => {
    this.setState({ loading: true })
    let getAlert1 = () => (
      <SweetAlert warning showCancel title="Are you sure?" cancelBtnBsStyle="success"
        closeOnClickOutside={false} confirmBtnText="Yes, delete it!"
        btnSize="sm"
        onCancel={() => { this.setState({ alert: null, loading: false }); }}
        onConfirm={() => { this.conformDeleteSlider(sliderId) }} >
        You will not be able to recover this imaginary file!
      </SweetAlert>
    );
    this.setState({ alert: getAlert1() });
  }

  conformDeleteSlider = (slider_id) => {
    this.setState({ alert: null });
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag'),
      's_id': slider_id
    }
    call_api('a/slider_delete', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            notify.show(resolve.message, 'success', 2000);
            this.setState({ loading: false, datalist: [] });
            this.load_slider_list();
          }
          else {
            notify.show(resolve.message, 'error', 2000);
            this.setState({ loading: false });
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_category() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  fetch_brand_list() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken')
    }
    call_api('a/brand-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ brand_list: resolve.data, })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  filterSlider = (event) => {
    let temp_datalist = this.state.temp_datalist;
    if (event.target.value == '') {
      var filter_data = temp_datalist;
    }
    else {
      var filter_data = temp_datalist.filter(item => item.sliderType == event.target.value);
    }

    this.setState({
      datalist: filter_data, [event.target.name]: event.target.value
    })
  }
  render() {
    const { data_status, datalist } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          {this.state.alert}
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px', zIndex: '9999' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Web Slider</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                  <button type="button" onClick={() => this.addNewSlider()} className="btn btn-warning btn-sm">Add New Slider</button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="mt-30">
                            <select value={this.state.filterSliderType} className="form-control" name="filterSliderType" onChange={(e) => this.filterSlider(e)}>
                              <option value="">Select Position</option>
                              <option value="webtop">Web Top Slider(1350*375)</option>
                              <option value="webmiddle1">Web Middle 1(1350*375)</option>
                              <option value="webmiddle2">Web Middle 2(1350*375)</option>
                              <option value="webmiddle3">Web Middle 3(1350*375)</option>
                              <option value="apptop">App Top Slider(450*125)</option>
                              <option value="appmiddle1">App Middle 1(450*125)</option>
                              <option value="appmiddle2">App Middle 2(450*125)</option>
                              <option value="appmiddle3">App Middle 3(450*125)</option>
                              <option value="product1">Product Page1(915*73)</option>
                              <option value="product2">Product Page2(915*73)</option>
                              <option value="group1">Category (Above)(915*73)</option>
                              <option value="group2">Category (Left Side)(254*557)</option>
                              <option value="group3">Category (Middle)(1350*375)</option>
                              <option value="loginpage">Login(300*300)</option>
                              <option value="signup">Signup (300*300)</option>
                              <option value="about">About(600*732)</option>
                              <option value="promotion">Promotional(472*91)</option>
                              <option value="androidApp">App Download Image(720*1080)</option>
                              <option value="birthday">Birth Day Image</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Image</th>
                              <th>Web Link</th>
                              <th>Title</th>
                              <th>Serial</th>
                              <th>Place</th>
                              <th>Created</th>
                              {/*  <th>Updated</th> */}
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                datalist.map((list, index) => {
                                  return (
                                    <tr key={"row" + index} id={"row_" + list.s_id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <FadeIn height={50} duration={50} easing={'ease-out'}>
                                          {onload => (
                                            <img alt={"Slider"} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" width="200px" src={apiUrl + list.image} onLoad={onload} style={{ cursor: 'pointer' }} />
                                          )}
                                        </FadeIn>
                                      </td>
                                      <td>{list.weblink}</td>
                                      <td>{list.title}</td>
                                      <td>{list.serial}</td>
                                      <td>{fetch_slider_stattus_string(list.sliderType)}</td>
                                      <td>{list.created}</td>
                                      {/* <td>{list.updated}</td> */}
                                      <td>
                                        {(list.status == 1)
                                          ?
                                          <button data-id={list.s_id} id={"status_" + list.s_id} data-status="0" className="btn btn-success btn-sm SliderStatus">Acitve</button>
                                          :
                                          <button data-id={list.s_id} id={"status_" + list.s_id} data-status="1" className="btn btn-danger btn-sm SliderStatus">Inactive</button>
                                        }
                                      </td>
                                      <td>
                                        <button className="btn btn-success btn-sm mr-5" onClick={() => this.dataEdit(list)}><i className="fa fa-pencil"></i></button>
                                        <button className="btn btn-danger btn-sm" onClick={() => this.deleteSlider(list.s_id)} disabled={this.state.loading}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  <i className="fa fa-trash-o"></i></button>
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="9" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal size="md" fade={false} isOpen={this.state.modalShow} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ modalShow: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.headerTitle || 'Add New Slider'}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="sliderTitle" className="control-label"><span className="text-danger">*</span>Slider Title</label>
                  <input type="text" name="sliderTitle" ref="sliderTitle" value={this.state.sliderTitle || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="sliderTitle" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="slider_link" className="control-label"><span className="text-danger">*</span>Slider Link</label>
                  <input type="text" name="slider_link" ref="slider_link" value={this.state.slider_link || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="slider_link" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="slider_series" className="control-label"><span className="text-danger">*</span>Slider Series</label>
                  <input type="number" min={0} name="slider_series" ref="slider_series" value={this.state.slider_series || ''} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} className="form-control" id="slider_series" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="slider_position" className="control-label"><span className="text-danger">*</span>Slider Position</label>
                  <select name="slider_position" ref="slider_position" id="slider_position" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.slider_position || ''} className="form-control">
                    <option value="">Select Position</option>
                    <option value="webtop">Web Top Slider(1350*375)</option>
                    <option value="webmiddle1">Web Middle 1(1350*375)</option>
                    <option value="webmiddle2">Web Middle 2(1350*375)</option>
                    <option value="webmiddle3">Web Middle 3(1350*375)</option>
                    <option value="apptop">App Top Slider(450*125)</option>
                    <option value="appmiddle1">App Middle 1(450*125)</option>
                    <option value="appmiddle2">App Middle 2(450*125)</option>
                    <option value="appmiddle3">App Middle 3(450*125)</option>
                    <option value="product1">Product Page1(915*73)</option>
                    <option value="product2">Product Page2(915*73)</option>
                    <option value="group1">Category (Above)(915*73)</option>
                    <option value="group2">Category (Left Side)(254*557)</option>
                    <option value="group3">Category (Middle)(1350*375)</option>
                    <option value="loginpage">Login(300*300)</option>
                    <option value="signup">Signup (300*300)</option>
                    <option value="about">About(600*732)</option>
                    <option value="promotion">Promotional(472*91)</option>
                    <option value="androidApp">App Download Image(720*1080)</option>
                    <option value="birthday">Birth Day Image</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  {this.state.cat_brand_type == '1' ?
                    <>
                      <span><input type="radio" checked={true} value="1" id="cat_type" name="cat_brand_type" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />   <label htmlFor="cat_type">Category </label> </span>
                      <span className="ml-10"><input type="radio" checked={false} id="brand_type" value="2" name="cat_brand_type" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />  <label htmlFor="brand_type"> Brand</label></span>
                    </>
                    : this.state.cat_brand_type == '2' ?
                      <>
                        <span><input type="radio" checked={false} value="1" id="cat_type" name="cat_brand_type" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />   <label htmlFor="cat_type">Category </label> </span>
                        <span className="ml-10"><input type="radio" checked={true} id="brand_type" value="2" name="cat_brand_type" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />  <label htmlFor="brand_type"> Brand</label></span>
                      </>
                      :
                      <>
                        <span><input type="radio" checked={false} value="1" id="cat_type" name="cat_brand_type" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />   <label htmlFor="cat_type">Category </label> </span>
                        <span className="ml-10"><input type="radio" checked={false} id="brand_type" value="2" name="cat_brand_type" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />  <label htmlFor="brand_type"> Brand</label></span>
                      </>
                  }
                  <select type="text" name="categoryId" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.categoryId} className="form-control" >
                    {this.state.cat_brand_type == '1' ?
                      <>
                        <option value="0">Select Category</option>
                        {
                          this.state.categorylist.map((list, index) => {
                            return (
                              (list.main_group_flag == 1) ?
                                <option key={"m_" + index} value={list.pg_id1}>{list.pg_name1}</option>
                                : ''
                            )
                          })
                        }
                      </>
                      : this.state.cat_brand_type == '2' ?
                        <>
                          <option value="0">Select Brand</option>
                          {
                            this.state.brand_list.map((list, index) => {
                              return (
                                <option key={"m_" + index} value={list.id}>{list.brand_name}</option>
                              )
                            })
                          }
                        </>
                        :
                        <>
                        </>
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="slider_image" className="control-label">Slider Image</label>
                  <input type="file" accept="images/*" className='form-control-file' ref="slider_image" name="slider_image" onChange={(event) => this.changePriveImage(event)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-5 mb-5">
                  <FadeIn height={100} duration={50} easing={'ease-out'}>
                    {onload => (
                      <img alt={"Slider Image"} title="Slider" height="100px" src={this.state.temp_image || ''} onLoad={onload} style={{ cursor: 'pointer' }} />
                    )}
                  </FadeIn>
                  <br />
                </div>
              </div>
            </div>
            <button className="btn btn-primary btn-sm" disabled={this.state.loading} onClick={() => this.save_update_slider()}>{this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>}  Save</button>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </Modal >
      </>
    );
  }
}
export default Slider;