import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';

class Web_setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data_status: false,
      editModel: false,
      modalShow: false,
      editId: 0,
      dataContent: "",
      datalist: [],
    };
  }
  componentDidMount() {
    this.fetch_web_setting();
  }
  fetch_web_setting() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/setting-web', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ datalist: resolve.data, data_status: true })
          }
          else {
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  editData = (list) => {
    this.setState({
      setting_id: list.setting_id, setting_type: list.setting_type, setting_name: list.setting_name, setting_key: list.setting_key, setting_value: list.setting_value, editModel: true, modelHeader: 'Update Setting',
    })
  }

  update_web_setting() {
    let setting_value = this.state.setting_value;
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag'),
      'setting_id': this.state.setting_id,
      'setting_value': this.state.setting_value
    }
    if (setting_value == '') {
      alert("Setting value Can't be empty");
    }
    else {
      this.setState({ loading: true })
      call_api('a/setting-web-update', optopns)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              this.setState({ datalist: resolve.data, loading: false, data_status: true, editModel: false })
              notify.show(resolve.message, 'success', 2000);
              this.fetch_web_setting();
            }
            else {
              notify.show(resolve.message, 'error', 2000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }

  render() {
    const { error, data_status, datalist } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Web Setting</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Key Name</th>
                              <th width="150px">Value</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data_status == true ?
                                datalist.map((list, index) => {
                                  return (
                                    <tr key={"row" + index}>
                                      <td>{index + 1}</td>
                                      <td>{list.setting_name}</td>
                                      <td width="150px">
                                        {
                                          (list.setting_type == 1) ?
                                            <input value={list.setting_value} width="150px" type="input" readOnly />
                                            :
                                            (list.setting_value == 1) ?
                                              <input value={"Yes"} type="input" width="150px" readOnly />
                                              :
                                              <input value={"No"} type="input" width="150px" readOnly />
                                        }
                                      </td>
                                      <td><button className="btn btn-sm btn-danger" onClick={() => this.editData(list, 'edit')}>Edit</button></td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan="4" align="center">Please Wait</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal size="md" fade={false} isOpen={this.state.editModel} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
          <ModalHeader close={<button className="close" onClick={() => this.setState({ editModel: false })}>X</button>}>
            <CardText className="modal-title">
              {this.state.modelHeader}
            </CardText>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              {
                (this.state.setting_type == 1) ?
                  <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="setting_value" className="col-md-4 control-label"><span className="text-danger">*</span>{this.state.setting_name}</label>
                      <div className="col-md-8">
                        <input type="text" name="setting_value" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.setting_value} className="form-control" />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="setting_value" className="col-md-4 control-label"><span className="text-danger">*</span>{this.state.setting_name}</label>
                      <div className="col-md-8">
                        <select name="setting_value" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} value={this.state.setting_value} className="form-control">
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.pg_id1 != 0 ? <button type="button" disabled={this.state.loading} onClick={() => this.update_web_setting()} className="btn btn-sm btn-primary"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Update</button>
              : <button type="button" disabled={this.state.loading} onClick={() => this.save_new_brand()} className="btn btn-sm btn-primary"> {this.state.loading && <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>} Save</button>}
          </ModalFooter>
        </Modal>

      </>
    );
  }
}
export default Web_setting;