import React, { Component } from 'react';
import { call_api } from '../../connect/Connect.js';
import Notifications from 'react-notify-toast';
import { withRouter, Link } from 'react-router-dom';
class CourierWiseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      loading: false,
      order_date_from: "",
      order_date_to: "",
      order_reports: [],
    };
  }
  componentDidMount() {
    this.orderReports();
  }


  orderReports() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag'),
      'order_date_from': this.state.order_date_from,
      'order_date_to': this.state.order_date_to,
    }
    this.setState({ order_reports: [], loading: true })
    call_api('a/courier-wise-order-report', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ order_reports: resolve.data, loading: false })
          }
          else {
            this.setState({ loading: false })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }

  render() {
    const { isLoading, order_reports } = this.state;
    var rtd_order = 0;
    var pending_dispatch_Order = 0;
    var dispatch_order = 0;
    var complete_order = 0;
    var cancelled_order = 0;
    var total_order = 0;
    return (
      <>
        {isLoading == true ?
          'Please Wait'
          :
          <div className="container-fluid page-body-wrapper">
            <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
            <div className="main-panel">
              <div className="content-wrapper pb-0">
                <div className="page-header flex-wrap">
                  <div className="header-left">
                    <h3 className="page-title">Courier Wise Order Report</h3>
                  </div>
                  <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                    <div className="d-flex align-items-center">

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_from" value={this.state.order_date_from} className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                            <div className="form-group">
                              <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_to" value={this.state.order_date_to} className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-xs-12 col-sm-3 col-md-3">
                            <div className="form-group mt-30">
                              <button type="button" disabled={this.state.loading} onClick={() => this.orderReports()} className="btn btn-info btn-sm"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Search</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table mb-0 text-center">
                            <thead>
                              <tr>
                                <th>Sr</th>
                                <th>Courier Id</th>
                                <th>Courier Name</th>
                                <th>RTD</th>
                                <th>Pending Pickup</th>
                                <th>Dispatched</th>
                                <th>Complete</th>
                                <th>Cancel</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order_reports.map((item, index) => {
                                rtd_order += parseInt(item.rtd_order);
                                pending_dispatch_Order += parseInt(item.pending_dispatch_Order);
                                dispatch_order += parseInt(item.dispatch_order);
                                complete_order += parseInt(item.complete_order);
                                cancelled_order += parseInt(item.cancelled_order);
                                total_order += parseInt(item.total_order);
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.courier_company_id}</td>
                                    <td>{item.courier_name}</td>
                                    <td><Link className="btn btn-sm btn-info" to={"/order-list?order_state=rtd_order&courier_id=" + item.courier_company_id}>{item.rtd_order}</Link></td>
                                    <td><Link className="btn btn-sm btn-warning" to={"/order-list?order_state=pending_dispatch_Order&courier_id=" + item.courier_company_id}>{item.pending_dispatch_Order}</Link></td>
                                    <td><Link className="btn btn-sm btn-primary" to={"/order-list?order_state=dispatch_order&courier_id=" + item.courier_company_id}>{item.dispatch_order}</Link></td>
                                    <td><Link className="btn btn-sm btn-success" to={"/order-list?order_state=complete_order&courier_id=" + item.courier_company_id}>{item.complete_order}</Link></td>
                                    <td><Link className="btn btn-sm btn-danger" to={"/order-list?order_state=cancelled_order&courier_id=" + item.courier_company_id}>{item.cancelled_order}</Link></td>
                                    <td><Link className="btn btn-sm btn-dark" to={"/order-list?courier_id=" + item.courier_company_id}>{item.total_order}</Link></td>
                                  </tr>
                                )
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={3}>Total</td>
                                <td>{rtd_order}</td>
                                <td>{pending_dispatch_Order}</td>
                                <td>{dispatch_order}</td>
                                <td>{complete_order}</td>
                                <td>{cancelled_order}</td>
                                <td>{total_order}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
export default withRouter(CourierWiseReport);
