import React, { Component } from 'react';
import { call_api } from '../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import Footer from '../components/Footer.js';
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      InputoldPass: '',
      InputnewPass: '',
      InputconfirmnewPass: '',
    };
  }
  update_password = () => {
    let InputoldPass = this.state.InputoldPass || '';
    let InputnewPass = this.state.InputnewPass || '';
    let InputconfirmnewPass = this.state.InputconfirmnewPass || '';
    if (InputoldPass == '') {
      notify.show('Enter valid password!', 'error', 5000);
    }
    else if (InputnewPass == '') {
      notify.show('Enter valid new password!', 'error', 5000);
    }
    else if (InputconfirmnewPass == '' || (InputnewPass != InputconfirmnewPass)) {
      notify.show('Both password not match', 'error', 5000);
    }
    else {
      this.setState({ loading: true })
      var LoginToken = localStorage.getItem('LoginToken');
      var deviceToken = localStorage.getItem('deviceToken');
      let options = {
        'InputoldPass': InputoldPass, 'InputnewPass': InputnewPass, 'LoginToken': LoginToken,
        'InputconfirmnewPass': InputconfirmnewPass, 'deviceToken': deviceToken
      }
      call_api('a/update-login-password', options)
        .then(
          (resolve) => {
            if (resolve.status == true) {
              notify.show(resolve.message, 'success', 5000);
              this.setState({ loading: false })
              window.location = "/";
            }
            else {
              notify.show(resolve.message, 'error', 5000);
              this.setState({ loading: false })
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
    }
  }
  render() {
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Update Password</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4 margin-auto">
                          <div className="form-wrap">
                            <div className="form-group">
                              <label className="control-label mb-10" htmlFor="InputoldPass">Old Password</label>
                              <input type="password" ref={(input) => { this.InputoldPass = input; }} className="form-control" onChange={(e) => this.setState({ InputoldPass: e.target.value })} id="InputoldPass" placeholder="Enter old password" />
                            </div>
                            <div className="form-group">
                              <label className="pull-left control-label mb-10" htmlFor="InputnewPass">New Password</label>
                              <div className="clearfix"></div>
                              <input type="password" ref={(input) => { this.InputnewPass = input; }} className="form-control" onChange={(e) => this.setState({ InputnewPass: e.target.value })} id="InputnewPass" placeholder="Enter new password" />
                            </div>
                            <div className="form-group">
                              <label className="pull-left control-label mb-10" htmlFor="InputconfirmnewPass">Confirm New Password</label>
                              <div className="clearfix"></div>
                              <input type="password" ref={(input) => { this.InputconfirmnewPass = input; }} className="form-control" onChange={(e) => this.setState({ InputconfirmnewPass: e.target.value })} id="InputconfirmnewPass" placeholder="Confirm new password" />
                            </div>
                            <div className="form-group text-center">
                              <button type="button" className="btn btn-primary btn-sm" disabled={this.state.loading} onClick={() => this.update_password()}> {this.state.loading && <i className="fa fa-spin fa-spinner"></i>}  Update</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
export default ChangePassword;
