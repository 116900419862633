export function fetch_slider_stattus_string(status_id) {
    switch (status_id) {
        case 'webtop':
            return ('Web Top Slider(1350*375)')
        case 'webmiddle1':
            return ('Web Middle 1(1350*375)')
        case 'webmiddle2':
            return ('Web Middle 2(1350*375)')
        case 'webmiddle3':
            return ('Web Middle 3(1350*375)')
        case 'apptop':
            return ('App Top Slider(450*125)')
        case 'appmiddle1':
            return ('App Middle 1(450*125)')
        case 'appmiddle2':
            return ('App Middle 2(450*125)')
        case 'appmiddle3':
            return ('App Middle 3(450*125)')
        case 'product1':
            return ('Product Page1(915*73)')
        case 'product2':
            return ('Product Page2(915*73)')
        case 'group1':
            return ('Category (Above)(915*73)')
        case 'group2':
            return ('Category (Left Side)(254*557)')
        case 'group3':
            return ('Category (Middle)(1350*375)')
        case 'loginpage':
            return ('Login(300*300)')
        case 'signup':
            return ('Signup (300*300)')
        case 'about':
            return ('About(600*732)')
        case 'promotion':
            return ('Promotional(472*91)')
        case 'androidApp':
            return ('App Download Image(720*1080)')
        case 'birthday':
            return ('Birth Day Image')
        default:
            return ('Not Defined')
    }
}
export function fetch_slider_type_list() {
    return ([
        {
            'webtop':
                'Web Top Slider(1350*375)'
        }
        , {
            'webmiddle1':
                'Web Middle 1(1350*375)'
        }
        , {
            'webmiddle2':
                'Web Middle 2(1350*375)'
        }
        , {
            'webmiddle3':
                'Web Middle 3(1350*375)'
        }
        , {
            'apptop':
                'App Top Slider(450*125)'
        }
        , {
            'appmiddle1':
                'App Middle 1(450*125)'
        }
        , {
            'appmiddle2':
                'App Middle 2(450*125)'
        }
        , {
            'appmiddle3':
                'App Middle 3(450*125)'
        }
        , {
            'product1':
                'Product Page1(915*73)'
        }
        , {
            'product2':
                'Product Page2(915*73)'
        }
        , {
            'group1':
                'Category (Above)(915*73)'
        }
        , {
            'group2':
                'Category (Left Side)(254*557)'
        }
        , {
            'group3':
                'Category (Middle)(1350*375)'
        }
        , {
            'loginpage':
                'Login(300*300)'
        }
        , {
            'signup':
                'Signup (300*300)'
        }
        , {
            'about':
                'About(600*732)'
        }
        , {
            'androidApp':
                'App Download Image(720*1080)'
        }
        , {
            'promotion':
                'Promotional(472*91)'
        }
        , {
            'birthday':
                'Birth Day Image'
        }
    ])
}
export function fetch_rr_status_string(status_id, type) {
    switch (status_id) {
        case '0':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Failed')
        case '1':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Request')
        case '2':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Confirmed')
        case '3':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Processing')
        case '4':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Order Packed')
        case '5':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Dispatched')
        case '6':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Delivered')
        case '7':
            return ((type == 1 ? 'Return' : 'Replacement') + ' Cancelled')
        default:
            return ('Other')
    }
}
export function fetch_status_string(status_id) {
    switch (status_id) {
        case '0':
            return ('Failed')
        case '1':
            return ('Placed')
        case '2':
            return ('Confirmed')
        case '3':
            return ('Order Processing')
        case '4':
            return ('Order Packed')
        case '5':
            return ('Dispatched')
        case '6':
            return ('Delivered')
        case '7':
            return ('Cancelled')
        case '8':
            return ('Return Complete')
        case '9':
            return ('Return Request')
        case '12':
            return ('Return Accepted')
        case '17':
            return ('Return Process')
        case '10':
            return ('Payment Process')
        case '11':
            return ('Payment Failed')
        case '13':
            return ('Replacement Request')
        case '14':
            return ('Replacement Accepted')
        case '15':
            return ('Replacement Process')
        case '16':
            return ('Replacement Complete')
        default:
            return ('Other')
    }
}
export function fetch_payment_mode_string(status_id) {
    switch (status_id) {
        case '1':
            return ('COD')
        case '2':
            return ('Online')
        default:
            return ('Other')
    }
}
export function fetch_status_color(status_id) {
    switch (status_id) {
        case '0':
            return ('label-danger')
        case '1':
            return ('label-primary')
        case '2':
            return ('label-info')
        case '3':
            return ('label-primary')
        case '4':
            return ('label-primary')
        case '5':
            return ('label-info')
        case '6':
            return ('label-success')
        case '7':
            return ('label-danger')
        case '8':
            return ('label-warning')
        case '9':
            return ('label-warning')
        case '10':
            return ('label-info')
        case '12':
            return ('label-dark')
        case '13':
            return ('label-warning')
        case '14':
            return ('label-dark')
        default:
            return ('label-danger')
    }
}
export function fetch_status_btn_color(status_id) {
    switch (status_id) {
        case '0':
            return ('btn-danger')
        case '1':
            return ('btn-primary')
        case '2':
            return ('btn-info')
        case '3':
            return ('btn-dark')
        case '4':
            return ('btn-success')
        case '5':
            return ('btn-info')
        case '6':
            return ('btn-success')
        case '7':
            return ('btn-danger')
        case '8':
            return ('btn-primary')
        case '9':
            return ('btn-warning')
        case '12':
            return ('btn-dark')
        case '13':
            return ('btn-warning')
        case '14':
            return ('btn-dark')
        default:
            return ('btn-danger')
    }
}