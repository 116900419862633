import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import { Button } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import notfoundImg from "../../assets/img/no_image.jpg";
import LazyLoad from "react-lazyload";
import { SummerizeTable1 } from '../../components/jquery/General_jquary.js';
class OrderQuantityReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      data_status: false,
      order_reports: [],
      logistic_list: [],
      category_id: '',
      order_status: '',
      courier_company_id: '',
      p_mode: '',
      order_date_from: '',
      categorylist: [],
      order_date_to: ''
    };
  }
  componentDidMount() {
    this.orderReports();
    this.fetch_category();
    this.logisticsList();
  }
  fetch_category() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken')
    }
    call_api('a/product-category', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ categorylist: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  logisticsList() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'owner_flag': localStorage.getItem('owner_flag')
    }
    call_api('a/order-courier-list', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ logistic_list: resolve.data })
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  orderReports() {
    let optopns = {
      'LoginToken': localStorage.getItem('LoginToken'),
      'category_id': this.state.category_id,
      'order_date_from': this.state.order_date_from,
      'p_mode': this.state.p_mode,
      'courier_company_id': this.state.courier_company_id,
      'order_date_to': this.state.order_date_to,
      'order_status': this.state.order_status
    }
    this.setState({ order_reports: [], loading: true })
    call_api('a/order-reports-product', optopns)
      .then(
        (resolve) => {
          if (resolve.status == true) {
            this.setState({ order_reports: resolve.data, data_status: true, loading: false })
            //SummerizeTable1(0);
            //SummerizeTable1(1);
          }
          else {
            this.setState({ data_status: false, loading: false })
            notify.show(resolve.message, 'error', 2000);
          }
        },
        (reject) => {
          console.log(reject);
        }
      )
  }
  getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  render() {
    const { categorylist, order_reports, logistic_list, data_status } = this.state;
    return (
      <>
        <div className="container-fluid page-body-wrapper">
          <Notifications options={{ animationDuration: 200, zIndex: 9999, timeout: 1000, top: '100px' }} />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="page-header flex-wrap">
                <div className="header-left">
                  <h3 className="page-title">Order Quantity Report</h3>
                </div>
                <div className="header-right d-flex flex-wrap mt-md-2 mt-lg-0">
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_from" value={this.state.order_date_from} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <input type="date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_date_to" value={this.state.order_date_to} className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="courier_company_id" value={this.state.courier_company_id} className="form-control" >
                              <option value="">Select Courier</option>
                              {
                                logistic_list.map((logList, logs) => {
                                  return (
                                    <option key={"option2" + logs} value={logList.courier_company_id}>{logList.courier_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select id="p_mode" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="p_mode" value={this.state.p_mode} className="form-control">
                              <option value="">Payment Mode</option>
                              <option value="2">Online</option>
                              <option value="1">COD</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-3 col-md-2">
                          <div className="form-group">
                            <select onChange={(e) => this.setState({ [e.target.name]: e.target.value })} name="order_status" value={this.state.order_status} className="form-control" >
                              <option value="0">Select Status</option>
                              <option value="1">New Order</option>
                              <option value="2">Confirm Order</option>
                              <option value="3">Pending RTD</option>
                              <option value="4">Pending Dispatch</option>
                              <option value="5">Dispatch Order</option>
                              <option value="6">Complete Order</option>
                              <option value="7">Cancelled Order</option>
                              <option value="8">Return Order</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12 col-sm-2 col-md-2">
                          <div className="form-group">
                            <select name="category_id" value={this.state.category_id || ''} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }); }} className="form-control" id="category_id">
                              <option key={"c00"} value="">Select Category</option>
                              {categorylist.map((cats, index) => {
                                if (cats.main_group_flag == 1) {
                                  return (
                                    <option key={"mc_" + index} value={cats.pg_id1}>{cats.pg_name1}</option>
                                  )
                                }
                              })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 text-center">
                          <div className="form-group mt-30">
                            <Button type="button" disabled={this.state.loading} onClick={() => this.orderReports()} className="btn btn-info btn-sm"> {this.state.loading && <i className="mdi mdi-refresh mdi-spin" aria-hidden="true"></i>} Search</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Item Name</th>
                              <th>SKU Id</th>
                              <th>HSN Code</th>
                              <th>Size</th>
                              <th>Barcode</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Sale Price</th>
                              <th>Order Quantity</th>
                              <th>Order Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order_reports.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td id={"cat_" + list.parent_id + '_' + list.sub_id + '_' + list.child_id}>{list.main_category_name}{'->'}{list.sub_category_name}{"->"}{list.child_category_name}</td>
                                  {/*<td id={"pro_" + list.product_id}>
                                     <div className="text-left" style={{ display: "flex" }}>
                                      <LazyLoad height={50} once>
                                        <img alt={list.p_name} onError={(e) => { e.target.onerror = null; e.target.src = notfoundImg }} title="click" style={{ maxHeight: "65px", maxWidth: "65px", cursor: 'pointer' }} src={apiUrl + list.p_img} />
                                      </LazyLoad>
                                      <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                        <p className="text-info">{list.p_name.slice(0, 50)}</p>
                                        <p>SKU Id : {list.sku || '---'}</p>
                                        <p>HSN Code : {list.hsn || '---'}</p>
                                        <p>BarCode : {list.p_barcode || '---'}</p>
                                        <p>Size : {list.product_size || '---'}</p>
                                      </div>
                                    </div> 
                                  </td>*/}
                                  <td>{list.p_name}</td>
                                  <td>{list.sku}</td>
                                  <td>{list.hsn}</td>
                                  <td>{list.product_size}</td>
                                  <td>{list.p_barcode}</td>
                                  <td>{list.unit_mrp}</td>
                                  <td>{list.unit_discount}%</td>
                                  <td>{list.unit_price}</td>
                                  <td>{list.total_qty}</td>
                                  <td>{list.total_price}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(OrderQuantityReport);
