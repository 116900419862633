import $ from 'jquery';
export function slider_jquery(apiUrl, call_api) {
    $(document).ready(function () {
        $(document).on('click', '.SliderStatus', function () {
            var this_element = $(this);
            var slider_id = this_element.attr('data-id');
            var status = this_element.attr('data-status');
            let optopns = {
                'LoginToken': localStorage.getItem('LoginToken'),
                's_id': slider_id, 'status': status
            }
            call_api('a/slider_in_change_status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {

                            if (status == 1) {
                                $('#status_' + slider_id).attr('data-status', 0);
                                $('#status_' + slider_id).html('Active');
                                $("#status_" + slider_id).removeClass("btn-danger");
                                $("#status_" + slider_id).addClass("btn-success");
                            }
                            else {
                                $('#status_' + slider_id).attr('data-status', 1);
                                $('#status_' + slider_id).html('Inactive');
                                $("#status_" + slider_id).removeClass("btn-success");
                                $("#status_" + slider_id).addClass("btn-danger");
                            }
                        }
                        else {

                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        });
    });
}
export function product_jquery(apiUrl, call_api) {
    $(document).ready(function () {
        $(document).on('click', '.productStatus', function () {
            var this_element = $(this);
            var productId = this_element.attr('data-product-id');
            var productIdKey = this_element.attr('id');
            var status = this_element.attr('data-product-status');
            var key = this_element.attr('data-product-label');
            let optopns = { 'status': status, 'key': key, 'productId': productId };
            call_api('a/product-change-status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {

                            if (status == 1) {
                                $('#' + productIdKey).attr('data-product-status', 2);
                                (key == "active_status") ? $('#' + productIdKey).html('Active') : $('#' + productIdKey).html('Yes');
                                $("#" + productIdKey).removeClass("btn-danger");
                                $("#" + productIdKey).addClass("btn-success");
                            }
                            else {
                                $('#' + productIdKey).attr('data-product-status', 1);
                                (key == "active_status") ? $('#' + productIdKey).html('Inactive') : $('#' + productIdKey).html('No');
                                $("#" + productIdKey).removeClass("btn-success");
                                $("#" + productIdKey).addClass("btn-danger");
                            }
                        }
                        else {

                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        });
        $(document).on('click', '.productPriceStatus', function () {
            var this_element = $(this);
            var productId = this_element.attr('data-product-id');
            var priceId = this_element.attr('data-price-id');
            var status = this_element.attr('data-price-status');
            var key = this_element.attr('data-price-label');
            let optopns = { 'LoginToken': localStorage.getItem('LoginToken'), 'status': status, 'key': key, 'priceId': priceId, 'productId': productId };
            call_api('a/price-change-status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {

                            if (status == 1) {
                                $(this_element).attr('data-price-status', 2);
                                (key == "active_status") ? $(this_element).html('Active') : $(this_element).html('Yes');
                                $(this_element).removeClass("btn-danger");
                                $(this_element).addClass("btn-success");
                            }
                            else {
                                $(this_element).attr('data-price-status', 1);
                                (key == "active_status") ? $(this_element).html('Inactive') : $(this_element).html('No');
                                $(this_element).removeClass("btn-success");
                                $(this_element).addClass("btn-danger");
                            }
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        });
    });
}
export function shipping_type_time_jquery(call_api) {
    $(document).ready(function () {
        $(document).on('click', '.shippingTypeStatus', function () {
            var this_element = $(this);
            var masterId = this_element.attr('data-id');
            var status = this_element.attr('data-status');
            let optopns = { 'LoginToken': localStorage.getItem('LoginToken'), 'status': status, 'masterId': masterId };
            call_api('a/master-shipping-type-status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {

                            if (status == 1) {
                                $(this_element).attr('data-status', 2);
                                $(this_element).html('Active');
                                $(this_element).removeClass("btn-danger");
                                $(this_element).addClass("btn-success");
                            }
                            else {
                                $(this_element).attr('data-status', 1);
                                $(this_element).html('In Active');
                                $(this_element).removeClass("btn-success");
                                $(this_element).addClass("btn-danger");
                            }
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        })
        $(document).on('click', '.shippingTimeStatus', function () {
            var this_element = $(this);
            var masterId = this_element.attr('data-id');
            var status = this_element.attr('data-status');
            let optopns = { 'LoginToken': localStorage.getItem('LoginToken'), 'status': status, 'masterId': masterId };
            call_api('a/master-shipping-time-status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {

                            if (status == 1) {
                                $(this_element).attr('data-status', 2);
                                $(this_element).html('Active');
                                $(this_element).removeClass("btn-danger");
                                $(this_element).addClass("btn-success");
                            }
                            else {
                                $(this_element).attr('data-status', 1);
                                $(this_element).html('In Active');
                                $(this_element).removeClass("btn-success");
                                $(this_element).addClass("btn-danger");
                            }
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        })
    })
}
export function vender_master_logistic_jquery(apiUrl, call_api) {
    $(document).ready(function () {
        $(document).on('click', '.change_master_status', function () {
            var this_element = $(this);
            var masterId = this_element.attr('data-id');
            var productIdKey = this_element.attr('id');
            var status = this_element.attr('data-status');
            var key = this_element.attr('data-label');
            let optopns = { 'LoginToken': localStorage.getItem('LoginToken'), 'status': status, 'key': key, 'masterId': masterId };
            call_api('a/master-change-status', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            if (status == 1) {
                                $('#' + productIdKey).attr('data-status', 2);
                                (key == "owner_status") ? $('#' + productIdKey).html('Active') : $('#' + productIdKey).html('<i class="fa fa-check"></i>');
                                $("#" + productIdKey).removeClass("label-danger");
                                $("#" + productIdKey).addClass("label-success");
                            }
                            else {
                                $('#' + productIdKey).attr('data-status', 1);
                                (key == "owner_status") ? $('#' + productIdKey).html('Inactive') : $('#' + productIdKey).html('<i class="fa fa-times"></i>');
                                $("#" + productIdKey).removeClass("label-success");
                                $("#" + productIdKey).addClass("label-danger");
                            }
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        });
    });
}

export function Logistics_pincode_area_jquery(pincode_list, area_list, call_api) {
    $(document).ready(function () {

        var pincode_html = '';
        $.each(pincode_list, function (key, value) {
            pincode_html = pincode_html + '<div class="col-md-4 col-lg-4">'
                + '<input type="checkbox" data-logistic-id="'+value.logId+'" ' + ((value.pincode_asign == 0) ? '' : 'checked') + '  value="' + value.p_id + '" id="' + value.p_id + '" name="pincode_id[]">'
                + '<label for="' + value.p_id + '" style="margin-left: 5px">' + value.pincode + '<span>('+value.other_asign+')</span></label></div>';
        })
        $('#pincodeSection').html(pincode_html);


        var area_html = '';
        $.each(area_list, function (key, value) {
            area_html = area_html + '<div class="col-md-4 col-lg-4 pincode' + value.p_id + '">'
                + '<input type="checkbox" '+((value.area_asign==0)?'':'checked')+'  value="' + value.a_id + '" id="' + value.a_id + '" name="area_id[]">'
                + '<label for="' + value.a_id + '" style="margin-left: 5px">' + value.area_name + '(' + value.pincode + ')<span>('+value.other_asign+')</span></label></div>';
        })
        $('#areaSection').html(area_html);

        $('input[name="pincode_id[]"]').on('click', function () {
            var this_element = $(this);
            var this_pincode = this_element.val();
            var logID = this_element.attr('data-logistic-id');
            if (this_element.is(':checked')) {
                let optopns = {
                    'LoginToken': localStorage.getItem('LoginToken'),
                    'logID': logID,
                    'pincode_id': this_pincode,
                }
                call_api('a/get-area-pincode', optopns)
                    .then(
                        (resolve) => {
                            if (resolve.status == true) {
                                var area_html2 = '';
                                $.each(resolve.data, function (key, value) {
                                    area_html2 = area_html2 + '<div class="col-md-4 col-lg-4 pincode' + value.p_id + '">'
                                        + '<input type="checkbox"  '+((value.area_asign==0)?'':'checked')+'  value="' + value.a_id + '" id="' + value.a_id + '" name="area_id[]">'
                                        + '<label for="' + value.a_id + '" style="margin-left: 5px">' + value.area_name + '(' + value.pincode + ')<span>('+value.other_asign+')</span></label></div>';
                                })
                                $('#areaSection').append(area_html2);
                            }
                        },
                        (reject) => {
                            console.log(reject);
                        }
                    )
            }
            else {
                $('.pincode' + this_pincode).remove();
            }
        })
        $('#save_area_allocation').on('click', function () {
            var this_element = $(this);
            var logisticId = this_element.val();
            var selected_pincode = $('input[name="pincode_id[]"]:checked');
            var final_pincode = [];
            selected_pincode.each(function () {
                final_pincode.push($(this).val());
            })
            var final_area = [];
            var selected_area = $('input[name="area_id[]"]:checked');
            selected_area.each(function () {
                final_area.push($(this).val());
            })
            let optopns = {
                'LoginToken': localStorage.getItem('LoginToken'),
                'pincode_id': final_pincode,
                'area_id': final_area,
                'logistics_id': logisticId
            }
            call_api('a/save-area-allot', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            $('#getMessage').html(resolve.message);
                        }
                        else {
                            $('#getMessage').html(resolve.message);
                        }
                        if(final_area.length==0)
                        {
                            $('input[name="pincode_id[]"]').prop('checked',false);
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        })

    });
}