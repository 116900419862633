import React, { Component } from 'react';
import { call_api, apiUrl } from '../../connect/Connect.js';
import Notifications, { notify } from 'react-notify-toast';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
class SizeChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            size_chart: apiUrl + props?.product?.size_chart || '',
            product_id: props?.product?.product_id || '',
        };
    }
    change_logo(event) {
        let image = event.target.files[0];
        if (image != null) {
            if (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg" || image.type == "image/webp") {
                var reader = new FileReader();
                var url = reader.readAsDataURL(image);
                reader.onloadend = function (e) {
                    this.setState({
                        size_chart: reader.result, displayUbtn: true
                    })
                }.bind(this);
            }
            else {
                this.setState({
                    size_chart: '', displayUbtn: true
                })
            }
        }
        else {
            this.setState({
                size_chart: '', displayUbtn: true
            })
        }
    }
    save_upload_image() {
        this.setState({ loading: true })
        var size_chart = this.state.size_chart;
        if (size_chart) {
            let optopns = { 'size_chart': size_chart, product_id: this.state.product_id };
            call_api('a/product-size-chart-upload', optopns)
                .then(
                    (resolve) => {
                        if (resolve.status == true) {
                            notify.show(resolve.message, 'success', 2000);
                            this.props.model_handler(true);
                        }
                        else {
                            notify.show(resolve.message, 'error', 2000);
                            this.setState({ loading: false })
                        }
                    },
                    (reject) => {
                        console.log(reject);
                    }
                )
        }
        else {
            this.setState({ loading: false })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Notifications options={{ animationDuration: 200, zIndex: 99999, timeout: 1000, top: '100px' }} />
                <Modal size="md" fade={false} isOpen={true} aria-labelledby="example-modal-sizes-title-sm" role="dialog" backdrop="static" keyboard={false}>
                    <ModalHeader close={<button className="close" onClick={() => { this.props.model_handler(false) }}>X</button>}>
                        <CardText className="modal-title">
                            Upload Size Chart
                        </CardText>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                                <label className="control-label">Select Size Chart</label>
                                <input type="file" ref="size_chart" accept="image/*" className='form-control' name="size_chart" onChange={(event) => this.change_logo(event)} />
                                <div className="text-center mt-2 dropzone">
                                    <img src={this.state.size_chart} style={{ maxHeight: "180px", maxWidth: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.displayUbtn == true ? <Button type="button" disabled={this.state.loading} onClick={() => this.save_upload_image()} className="btn btn-danger"> {this.state.loading && <i className="mdi mdi-dots-circle mdi-spin" aria-hidden="true"></i>} Upload</Button> : ''}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
export default SizeChart;